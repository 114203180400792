import { create } from 'zustand'
import zustymiddleware from 'zustymiddleware'

interface FilterValue {
  label: 'string'
  value: number | string
}

export interface Pricingfilters {
  inputForecastModel?: null | FilterValue[]
  inputForecastModelVariant?: null | FilterValue[]
  inputForecastPeriodStart?: null | string
  inputForecastPeriodEnd?: null | string
  inputForecastObjective?: null | FilterValue[]
}
const pricingFilters: Pricingfilters = {
  inputForecastModel: null,
  inputForecastModelVariant: null,
  inputForecastPeriodStart: null,
  inputForecastPeriodEnd: null,
  inputForecastObjective: null
}

declare global {
  interface Window {
    store: any
  }
}

const customObjective: Number = 0

export const useInputForecastStore = create(
  zustymiddleware((set) => ({
    ...pricingFilters,
    customObjective,
    updateFilter: (filterName: string, value: number[] | string[]) => {
      if (typeof value === 'object') {
        set(() => ({ [filterName]: value }))
      }
    },
    resetFilter: (filterName: string) => {
      set(() => ({ [filterName]: pricingFilters[filterName] }))
    },
    setCustomObjetive: (value: string | number) =>
      set(() => ({ customObjective: value }))
  }))
)

window.store = useInputForecastStore
