import { useState } from 'react'
import { Tooltip } from 'components/Tooltip'
import { BadgeContainer, BadgeContent } from './Badge.styles'

const Badge = (props: any) => {
  const { data, color, tooltip, size } = props
  const [active, setActive] = useState(false)

  const handleShowTooltip = () => {
    setActive(true)
  }
  const handleHideTooltip = () => {
    setActive(false)
  }

  return (
    <BadgeContainer
      onMouseEnter={handleShowTooltip}
      onMouseLeave={handleHideTooltip}
    >
      <BadgeContent $color={color}>{data}</BadgeContent>
      {active && tooltip && (
        <Tooltip size={size ?? 15} position="bottom-left">
          {tooltip}
        </Tooltip>
      )}
    </BadgeContainer>
  )
}

export { Badge }
