import { useContext, useEffect, useState } from 'react'
import { ReactComponent as AvatarIcon } from 'assets/icons/avatar.svg'
import { UserButton, UserName, Wrapper } from './User.style'
import { AuthContext } from 'contexts/AuthProvider'

const User = () => {
  const [userName, setUserName] = useState<string>('')
  const auth = useContext(AuthContext)
  // setCookie is not declared because we only want to remove 'token' cookie not just to set it as empty.

  useEffect(() => {
    if (auth && 'user' in auth) {
      const { user } = auth
      if (user && 'name' in user) {
        const { name } = user
        setUserName(name)
      }
    }
  }, [auth])

  /*
  Remove "Idp-Seat_" prefix form the username. We save the new value as user DisplayName to preserve the original
  username value from the IDP and avoid any side effects in the authn/authz process.
  */
  const userDisplayName = userName.replace('Idp-Seat_', '')

  return (
    <>
      {userName && (
        <Wrapper data-testid="user-info">
          <UserButton>
            <AvatarIcon />
            <UserName> {userDisplayName} </UserName>
          </UserButton>
        </Wrapper>
      )}
    </>
  )
}

export { User }
