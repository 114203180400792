import {
  Input,
  InputError,
  InputErrorIcon,
  Label,
  Wrapper
} from './InputText.style'
import { useCallback } from 'react'
import { InputTextProps } from './InputText.types'

const InputText = (props: InputTextProps) => {
  const {
    label,
    type = 'text',
    placeholder = '',
    icon,
    look,
    id,
    onRefreshValue = () => {},
    customError,
    defaultValue
  } = props

  const handleText = useCallback(
    (e) => {
      const text = e.target.value
      onRefreshValue(text)
    },
    [onRefreshValue]
  )

  return (
    <Wrapper>
      {!!label && <Label htmlFor={id}>{label}</Label>}
      <Input
        type={type}
        id={id}
        placeholder={placeholder}
        icon={icon}
        look={look}
        onChange={(e) => handleText(e)}
        data-testid="input-text"
        error={!!customError}
        defaultValue={defaultValue}
      />
      {customError && (
        <InputError>
          <InputErrorIcon />
          {customError}
        </InputError>
      )}
    </Wrapper>
  )
}

export { InputText }
