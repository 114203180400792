import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useDefaultBrand = (): string => {
  const storage = window.localStorage.getItem('theme')
  const location = useLocation()
  if (!storage) {
    return location.pathname.includes('smart-order') ? 'cupra' : 'seat'
  }
  return storage
}

export const useOutsideAlerter = (ref: any, callback: () => void) => {
  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (ref?.current && !ref?.current?.contains(event.target)) {
        callback()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [callback, ref])
}
