import { cupraTheme, GlobalStyles, seatTheme } from 'assets/styles'
import { useDefaultBrand } from 'hooks/utils'
import {
  ReactNode,
  createContext,
  useState,
  useEffect,
  useCallback
} from 'react'
import { ThemeProvider } from 'styled-components'

interface IBrand {
  brand: string
  setBrand: Function
  isMenuExpanded: boolean
  setIsMenuExpanded: Function
}

const initialState: IBrand = {
  brand: 'seat',
  setBrand: () => {},
  isMenuExpanded: false,
  setIsMenuExpanded: () => {}
}

const BrandContext = createContext(initialState)

interface IProps {
  children: ReactNode
}

// eslint-disable-next-line no-undef
const BrandProvider = (props: IProps): JSX.Element => {
  const defaultBrand = useDefaultBrand()
  const [brand, setBrand] = useState(defaultBrand)
  const [isMenuExpanded, setIsMenuExpanded] = useState(false)
  const { children } = props
  const value = { brand, setBrand, isMenuExpanded, setIsMenuExpanded }
  const theme = useCallback(() => {
    window.localStorage.setItem('theme', brand)
    switch (brand) {
      case 'seat':
        return seatTheme
      case 'cupra':
        return cupraTheme
    }
  }, [brand])

  useEffect(() => {
    theme()
  }, [brand, theme])

  return (
    <BrandContext.Provider value={value}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </BrandContext.Provider>
  )
}

export { BrandContext, BrandProvider }
