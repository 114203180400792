/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from 'components/Card'
import { Col } from 'components/Grid/Col'
import { Row } from 'components/Grid/Row'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'components/ErrorFallback'
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Tooltip
} from 'recharts'
import { BrandContext } from 'contexts/BrandProvider'
import { useContext, useEffect, useState } from 'react'
import { inputs } from 'mocks/inputForecast'
import { useInputForecastParams } from '../hooks/useInputForecastParams'
import { toQueryParams } from 'api/utils'
import {
  getTrendVariable,
  getTrendVariableByModel
} from 'api/pricing/inputForecast'
import { useTokenUuid } from 'hooks/request'

const MultiInputChart = (data: any) => {
  const { brand } = useContext(BrandContext)
  const [inputVariables, setInputVariables] = useState<any[]>([])
  const [, setInputVariablesLenght] = useState(0)
  const params = useInputForecastParams()
  const uuid = useTokenUuid()
  const [inputsObj, setInputsObj] = useState<any[]>([])
  const [, setSelectedVal] = useState<any>({})

  const selectedVal = {
    label: data.data.input_data_filter.input_data_filter,
    value: data.data.input_data_filter.input_data_filter,
    isFixed: true
  }

  const modelVariant = (model: string) => {
    switch (model) {
      case 'leon':
        return 'leon-5d'
      default:
        return params.model
    }
  }

  const fetchDensityDataByModel = async () => {
    if (inputVariables.length > 0) {
      const queryParams = toQueryParams({
        brand: brand,
        model: params.model,
        start_month: params.startMonth,
        end_month: params.endMonth,
        start_year: params.startYear,
        end_year: params.endYear,
        trim: params.trim,
        fuel: params.fuel,
        input_data_filter: inputVariables[inputVariables.length - 1].value
      })
      if (
        typeof params.model !== 'undefined' &&
        typeof params.startMonth !== 'undefined' &&
        typeof params.endMonth !== 'undefined' &&
        typeof params.objective !== 'undefined'
      ) {
        const resp: any = await getTrendVariableByModel(uuid, queryParams)
        setInputVariablesLenght(inputVariables.length)
        if (resp !== 500) {
          data.data.points.map((e, index) => {
            Object.assign(e, {
              [`${inputVariables[inputVariables.length - 1].value}`]:
                resp.points[index].value
            })
          })
        }
      }
    }
  }

  const fetchDensityData = async () => {
    if (inputVariables.length > 0) {
      const queryParams = toQueryParams({
        brand: brand,
        model: params.model,
        model_variant: modelVariant(params.model),
        start_month: params.startMonth,
        end_month: params.endMonth,
        start_year: params.startYear,
        end_year: params.endYear,
        trim: params.trim,
        fuel: params.fuel,
        input_data_filter: inputVariables[inputVariables.length - 1].value
      })
      if (
        typeof params.model !== 'undefined' &&
        typeof params.startMonth !== 'undefined' &&
        typeof params.endMonth !== 'undefined' &&
        typeof params.objective !== 'undefined'
      ) {
        const resp: any = await getTrendVariable(uuid, queryParams)
        setInputVariablesLenght(inputVariables.length)
        if (resp !== 500) {
          data.data.points.map((e, index) => {
            Object.assign(e, {
              [`${inputVariables[inputVariables.length - 1].value}`]:
                resp.points[index].value
            })
          })
        }
      }
    }
  }
  const inputsFiltered = () => {
    inputsObj?.map((input: any) => {
      if (input.value === selectedVal.value) return (input.isFixed = 'true')
    })
    return inputsObj
  }

  const handleSelect = (id, event) => {
    setInputVariables(event)
  }

  useEffect(() => {
    if (data.filterByModel) fetchDensityDataByModel()
    else fetchDensityData()

    inputsObj?.map((input: any) => {
      delete input.isFixed
    })
  }, [inputVariables])

  useEffect(() => {
    if (data) {
      setInputsObj(
        data?.inputsDataVariable?.length > 0 ? data.inputsDataVariable : inputs
      )
      setSelectedVal({
        label: data.data.input_data_filter.input_data_filter,
        value: data.data.input_data_filter.input_data_filter,
        isFixed: true
      })
    }
  }, [data])

  return (
    <Row>
      <Col>
        <Card
          headerTitle="Most relevant variables line chart"
          minHeightBody={43.6}
          withSelect
          selectVal={selectedVal}
          selectFunc={handleSelect}
          selectOptions={inputsFiltered()}
          disableMultiSelect={inputVariables.length > 3}
          subtitle="Most relelevant variables envolved in model"
          tooltip={
            <p>
              Most important variables relationship between them. You can choose
              up to 4 different variables. The main one is selected by default.
            </p>
          }
        >
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                data={data.data.points}
                margin={{ top: 20, right: 40, bottom: 40, left: 40 }}
              >
                <CartesianGrid
                  stroke="#DEDEDE"
                  strokeDasharray="3 3"
                  vertical={false}
                />

                <YAxis
                  yAxisId={0}
                  stroke={brand === 'seat' ? '#F96C64' : '#95572B'}
                  type="number"
                  width={80}
                  name={data.data.input_data_filter.input_data_filter}
                  allowDecimals={false}
                  domain={([dataMin, dataMax]) => {
                    return [
                      Math.round(dataMin * 100) / 100,
                      Math.round(dataMax * 100) / 100
                    ]
                  }}
                />
                {inputVariables.length > 1 && (
                  <YAxis
                    yAxisId={1}
                    stroke="#003E51"
                    width={80}
                    allowDecimals={false}
                    orientation="right"
                    type="number"
                    domain={([dataMin, dataMax]) => {
                      return [
                        Math.round(dataMin * 100) / 100,
                        Math.round(dataMax * 100) / 100
                      ]
                    }}
                  />
                )}
                {inputVariables.length > 2 && (
                  <YAxis
                    yAxisId={2}
                    stroke="#B0A097"
                    type="number"
                    domain={([dataMin, dataMax]) => {
                      return [
                        Math.round(dataMin * 100) / 100,
                        Math.round(dataMax * 100) / 100
                      ]
                    }}
                  />
                )}
                {inputVariables.length > 3 && (
                  <YAxis
                    yAxisId={3}
                    orientation="right"
                    stroke="#018A7F"
                    type="number"
                    domain={([dataMin, dataMax]) => {
                      return [
                        Math.round(dataMin * 100) / 100,
                        Math.round(dataMax * 100) / 100
                      ]
                    }}
                  />
                )}

                <XAxis
                  dataKey={(x) => `${x.month}/${x.year}`}
                  label={{
                    value: 'Period range',
                    position: 'bottom'
                  }}
                  interval="preserveEnd"
                  minTickGap={15}
                />
                <Tooltip
                  contentStyle={{
                    borderColor: '#f5f5f5',
                    padding: '0.8rem 1.6rem',
                    borderRadius: '10px',
                    background: '#f5f5f5',
                    fontSize: ' 16px'
                  }}
                  itemStyle={{
                    marginTop: '4px'
                  }}
                />

                <Legend
                  verticalAlign="bottom"
                  align="center"
                  wrapperStyle={{ left: 50, bottom: 10 }}
                />

                <Line
                  type="monotone"
                  dataKey="value"
                  stroke={brand === 'seat' ? '#F96C64' : '#95572B'}
                  dot={false}
                  strokeWidth="2"
                  yAxisId={0}
                  name={data.data.input_data_filter.input_data_filter}
                />

                {inputVariables.map((el, index) => {
                  if (index !== 0) {
                    return (
                      <Line
                        key={index}
                        type="monotone"
                        dataKey={el.value}
                        yAxisId={index}
                        strokeWidth="2"
                        dot={false}
                        stroke={
                          index === 1
                            ? '#003E51'
                            : index === 2
                            ? '#B0A097'
                            : '#018A7F'
                        }
                      />
                    )
                  }
                })}
              </ComposedChart>
            </ResponsiveContainer>
          </ErrorBoundary>
        </Card>
      </Col>
    </Row>
  )
}

export { MultiInputChart }
