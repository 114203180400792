import { Card } from 'components/Card'
import styled from 'styled-components'
import { getMonthName } from 'api/utils'
import { BarChartDouble } from 'components/Graphs/BarChart/BarChartDouble'

export const BadgeLegend = styled.div<{ $color?: string }>`
  display: flex;
  margin-top: 4px;

  &::before {
    content: '';
    width: 16px;
    height: 16px;
    background-color: ${(props) => props.$color};
    border-radius: 4px;
    margin-right: 4px;
  }
`

const IncomingOrdersObjective = ({ incomingOrdersObjective }) => {
  return (
    <Card
      headerTitle="Incoming Orders Objective"
      gridType="default"
      tooltip={
        <div>
          <p>
            <strong>
              IC Closed vs{' '}
              {`${getMonthName(
                incomingOrdersObjective?.next_month?.month
              )}'${incomingOrdersObjective?.next_month?.year
                .toString()
                .substring(2)}`}
            </strong>{' '}
            is the data take from the same month of the last year. And shows the
            result closed of Incoming Orders
          </p>
          <p>
            <strong>
              IC Predicted vs{' '}
              {`${getMonthName(
                incomingOrdersObjective?.next_month?.month
              )}'${incomingOrdersObjective?.next_month?.year
                .toString()
                .substring(2)}`}
            </strong>{' '}
            is the sum of the Daily Sales and the Forecast.
          </p>
          <p>
            <strong>Incoming Orders Objective</strong> shows the real Incoming
            Orders Objective stablished for this month.
          </p>
          <p>
            The last month data is from AES_io(N,N+1) vs Daily_channel_report +
            Prediction. And for the last year is from AEs_io_close_Month+1 vs
            AEs_io_close_month_(last year)
          </p>
        </div>
      }
    >
      <BarChartDouble
        data={incomingOrdersObjective}
        dataLast={[
          {
            month: incomingOrdersObjective?.next_month?.month,
            year: incomingOrdersObjective?.next_month?.year,
            deviation_with_current_month_back_orders:
              incomingOrdersObjective?.next_month
                ?.deviation_with_next_incoming_orders,
            back_orders: incomingOrdersObjective?.next_month?.incoming_orders,
            legend: `${getMonthName(
              incomingOrdersObjective?.next_month?.month
            )}'${incomingOrdersObjective?.next_month?.year
              .toString()
              .substring(2)}`,
            title: 'IO Objective'
          }
        ]}
        dataLegend={
          incomingOrdersObjective
            ? [
                {
                  month: incomingOrdersObjective?.next_month?.month,
                  year: incomingOrdersObjective?.next_month?.year,
                  deviation_with_current_month_back_orders:
                    incomingOrdersObjective?.next_month
                      ?.deviation_with_next_incoming_orders,
                  back_orders:
                    incomingOrdersObjective?.next_month?.incoming_orders,
                  legend: [
                    incomingOrdersObjective?.next_month?.incoming_orders,
                    'IO Obj',
                    `${getMonthName(
                      incomingOrdersObjective?.next_month?.month
                    )}'${incomingOrdersObjective?.next_month?.year
                      .toString()
                      .substring(2)}`
                  ]
                },
                {
                  month: incomingOrdersObjective?.last_year?.month,
                  year: incomingOrdersObjective?.last_year?.year,
                  deviation_with_current_month_back_orders:
                    incomingOrdersObjective?.last_year
                      ?.deviation_with_next_incoming_orders,
                  back_orders:
                    incomingOrdersObjective?.last_year?.incoming_orders,
                  legend: [
                    incomingOrdersObjective?.last_year?.incoming_orders,
                    'IO Closed',
                    `${getMonthName(
                      incomingOrdersObjective?.last_year?.month
                    )}'${incomingOrdersObjective?.last_year?.year
                      .toString()
                      .substring(2)}`
                  ]
                },
                {
                  month: incomingOrdersObjective?.next_month?.month,
                  year: incomingOrdersObjective?.next_month?.year,
                  deviation_with_current_month_back_orders:
                    incomingOrdersObjective?.next_month
                      ?.deviation_with_next_incoming_orders,
                  back_orders:
                    incomingOrdersObjective?.next_month?.incoming_orders,
                  legend: [
                    incomingOrdersObjective?.next_month?.incoming_orders,
                    'IO Obj',
                    `${getMonthName(
                      incomingOrdersObjective?.next_month?.month
                    )}'${incomingOrdersObjective?.next_month?.year
                      .toString()
                      .substring(2)}`
                  ]
                },
                {
                  month: incomingOrdersObjective?.current_month?.month,
                  year: incomingOrdersObjective?.current_month?.year,
                  deviation_with_current_month_back_orders:
                    incomingOrdersObjective?.current_month
                      ?.deviation_with_next_incoming_orders,
                  back_orders:
                    incomingOrdersObjective?.current_month?.incoming_orders,
                  legend: [
                    incomingOrdersObjective?.current_month?.incoming_orders,
                    'IO Predicted',
                    `${getMonthName(
                      incomingOrdersObjective?.current_month?.month
                    )}'${incomingOrdersObjective?.current_month?.year
                      .toString()
                      .substring(2)}`
                  ]
                }
              ]
            : []
        }
      />
    </Card>
  )
}

export { IncomingOrdersObjective }
