import styled from 'styled-components'

export const FeedbackWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 320px;
  height: auto;
  box-shadow: 0px 12px 22px hsl(0deg 0% 0% / 10%);
  border-radius: 8px;
  text-align: center;
  padding: 20px;
  background: #fff;
  transition: transform 0.2s ease;
`
export const FeedbackCaption = styled.h5`
  font-weight: 400;
  max-width: 300px;
  margin: auto;
  margin-top: 12px;
`

export const FeedbackIconList = styled.ul`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`
export const FeedbackIcon = styled.li`
  label {
    font-size: 36px;
    cursor: pointer;
    filter: grayscale(1);
    transition: all 0.2s ease;
    border: 0;
    min-width: auto;

    &:hover {
      filter: grayscale(0);
    }
  }

  input[type='radio']:checked + label {
    background: none !important;
    filter: grayscale(0);
  }
`

export const TextArea = styled.textarea`
  margin: 24px 0;
  width: 100%;
  height: 100px;
  padding: 12px;
  box-sizing: border-box;
  border: 1px solid #dedede;
  border-radius: 8px;
  background-color: #f9f9f9;
  font-size: 12px;
  resize: none;
  font-family: 'Cupra';
`
export const ButtonWrapper = styled.div`
  button {
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border: none;
    font-size: 10px;
    background-image: linear-gradient(
      to right,
      #fbc2eb 0%,
      #a6c1ee 51%,
      #fbc2eb 100%
    );

    &:hover {
      background-position: right center;
    }
  }
`
export const CloseFeedback = styled.div`
  button {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;

    &:hover {
      background: transparent;
    }
  }
`

export const FloatingButton = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  transition: 0.5s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 200% auto;
  background-image: linear-gradient(
    to right,
    #fbc2eb 0%,
    #a6c1ee 51%,
    #fbc2eb 100%
  );

  &:hover {
    background-position: right center;
  }
`

export const FeedbackIconSuccessful = styled.span`
  font-size: 36px;
  margin: 24px;
  display: block;
`
