import { ErrorMessage, Wrapper } from './ErrorFallback.style'
import { ErrorFallbackProps } from './ErrorFallback.types'
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg'

const ErrorFallback = (props: ErrorFallbackProps) => {
  const { error } = props
  return (
    <Wrapper data-testid="error">
      <WarningIcon />
      <ErrorMessage>{error.message}</ErrorMessage>
    </Wrapper>
  )
}

export { ErrorFallback }
