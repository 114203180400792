import { create } from 'zustand'
import { Tactical } from 'views/Pricing/views/CreateCampaign/Definition/Configure/components/ConfigurationTable/hooks/useConfigurationTableData'

interface FilterValue {
  label: 'string'
  value: number | string
}

export interface Pricingfilters {
  createSummaryModel?: null | FilterValue[]
  createSummaryPeriod?: null | string
  createSummaryObjective?: null | FilterValue[]
}

const pricingFilters: Pricingfilters = {
  createSummaryModel: null,
  createSummaryPeriod: null,
  createSummaryObjective: null
}

export const usePricingStore = create((set) => ({
  ...pricingFilters,
  updateFilter: (filterName: string, value: number[] | string[]) => {
    if (typeof value === 'object') {
      set(() => ({ [filterName]: value }))
    }
  },
  resetFilter: (filterName: string) => {
    set(() => ({ [filterName]: pricingFilters[filterName] }))
  }
}))

export interface Item {
  budget: number
  margin: number
  mix_percentage: number
  model: string
  trim: string
  fuel: string
  optimal_discount: number
  orders_objective: number
  predicted_orders: number
  tactical_request: number
  total_net_contribution_margin: number
  campaign_objective: string
}

export interface CampaignTable {
  createDefinitionFilter: null | string
  createDefinitionModel: string
  createDefinitionPeriod: Date | string
  createDefinitionCampaignTitle: string
  createDefinitionSetup: null | string
  createDefinitionOptimizationData: object
  createDefinitionFilterTrimFuel: {
    trim: string[]
    fuel: string[]
  }
}

const campaignData: CampaignTable = {
  createDefinitionFilter: '',
  createDefinitionModel: '',
  createDefinitionPeriod: new Date(Date.now()),
  createDefinitionCampaignTitle: '',
  createDefinitionSetup: 'Market',
  createDefinitionOptimizationData: { loading: true, items: [] },
  createDefinitionFilterTrimFuel: { trim: [], fuel: [] }
}

export const useCampaignStore = create((set) => ({
  ...campaignData,
  updateState: (filterName: string, value: number[] | string[]) => {
    set(() => ({ [filterName]: value }))
  },
  resetCampaignStore: () => {
    set(campaignData)
  }
}))
export interface LastCampaignConfigData {
  idCampaign: number
  brand: string
  status: string
  date: string
  tacticals: Tactical[]
  number: number
  modelVariant: string
  model: string
}

const campaignConfigTable = {
  createDefinitionCampaignConfigData: {},
  createDefinitionIdCampaign: 0,
  createDefinitionDraftCampaignState: 'draft'
}

export const useCampaignConfigStore = create((set) => ({
  ...campaignConfigTable,
  updateState: (filterName: string, value: number[] | string[]) => {
    set(() => ({ [filterName]: value }))
  },
  resetCampaignConfigStore: () => {
    set(campaignConfigTable)
  }
}))

const clickedCampaignOnCampaignsList = {
  clickedCampaign: 0,
  isArchiveModalOpen: false,
  isDeleteModalOpen: false
}

export const useClickedCampaignStore = create((set) => ({
  ...clickedCampaignOnCampaignsList,
  updateState: (filterName: string, value: number[] | string[]) => {
    set(() => ({ [filterName]: value }))
  },
  resetClickedCampaignStore: () => {
    set(clickedCampaignOnCampaignsList)
  }
}))

export interface CampaignResultsStore {
  trim: string
  description: string
  tmaimg: string
  list_price: number
  mix: number
  fuel: string
  model: string
}

const campaignResults: CampaignResultsStore[] | undefined = undefined

export const useCampaignResultsStore = create((set) => ({
  campaignResults,
  updateState: (filterName: string, value: number[] | string[]) => {
    set(() => ({ [filterName]: value }))
  },
  reset: () => {
    set(campaignResults)
  }
}))

export interface CampaignOptimizationChart {
  trim: string
  fuel: string
  model: string
}

const campaignOptimizationChart: CampaignOptimizationChart | undefined =
  undefined

export const useCampaignOptimizationChartStore = create((set) => ({
  campaignOptimizationChart,
  updateChartState: (filterName: string, value: number[] | string[]) => {
    set(() => ({ [filterName]: value }))
  },
  reset: () => {
    set(campaignOptimizationChart)
  }
}))
