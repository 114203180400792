import { create } from 'zustand'

export interface SmartOrder {
  date?: null | string
  loading: boolean
  campa?: string
  model?: string
}

const initialState: SmartOrder = {
  date: null,
  loading: false
}

export const useSmartStore = create((set) => ({
  ...initialState,
  updateFilter: (filterName: string, value: number[] | string[]) => {
    if (typeof value === 'object') {
      set(() => ({ [filterName]: value }))
    }
  },
  resetFilter: (filterName: string) => {
    set(() => ({ [filterName]: initialState[filterName] }))
  },
  setLoading: (value: boolean) => set(() => (initialState.loading = value))
}))

window.store = useSmartStore
