import { getMonthName } from 'api/utils'
import { Flex } from 'App.style'
import { Card } from 'components/Card'
import { BarChart } from 'components/Graphs/BarChart/BarChart'

const BackOrders = ({ backOrders }) => {
  return (
    <Card
      headerTitle="Back Orders"
      gridType="default"
      tooltip={
        <div>
          <p>
            <strong>
              {`${getMonthName(
                backOrders?.last_month_real?.month
              )}'${backOrders?.last_month_real?.year.toString().substring(2)}`}
              vs BO Objective
            </strong>
            compares this year's BO objective with the one from the previous
            year.
          </p>
          <p>
            The values from last month is from AEs_back_orders_canal_privado.
            And the values from last year is from AEs_cierre_month.
          </p>
        </div>
      }
    >
      <Flex style={{ flexDirection: 'column', height: '200px' }}>
        <BarChart
          dataLegend={
            backOrders
              ? [
                  {
                    month: backOrders?.last_month_real?.month,
                    year: backOrders?.last_month_real?.year,
                    deviation_with_current_month_back_orders:
                      backOrders?.last_month_real
                        ?.deviation_with_current_back_orders,
                    back_orders: backOrders?.last_month_real?.back_orders,
                    legend: [
                      backOrders?.last_month_real?.back_orders,
                      'BO Closed',
                      `${getMonthName(
                        backOrders?.last_month_real?.month
                      )}'${backOrders?.last_month_real?.year
                        .toString()
                        .substring(2)}`
                    ]
                  },
                  {
                    month: backOrders?.previous_month_real?.month,
                    year: backOrders?.previous_month_real?.year,
                    deviation_with_current_month_back_orders:
                      backOrders?.previous_month_real
                        ?.deviation_with_current_back_orders,
                    back_orders: backOrders?.previous_month_real?.back_orders,
                    legend: [
                      backOrders?.previous_month_real?.back_orders,
                      'BO Closed',
                      `${getMonthName(
                        backOrders?.previous_month_real?.month
                      )}'${backOrders?.previous_month_real?.year
                        .toString()
                        .substring(2)}`
                    ]
                  }
                ]
              : []
          }
        />

        <div
          style={{
            textAlign: 'center',
            paddingTop: '20px'
          }}
        >
          <Flex style={{ justifyContent: 'center', margin: '0' }}>
            <h3
              style={{
                color:
                  Math.sign(
                    backOrders?.last_month_real?.back_orders -
                      backOrders?.previous_month_real?.back_orders
                  ) === 1
                    ? '#28A745'
                    : '#E73939',
                marginRight: '4px'
              }}
            >
              {backOrders?.last_month_real?.back_orders -
                backOrders?.previous_month_real?.back_orders}
            </h3>
            <h4>Difference between Back Orders Closed</h4>
          </Flex>
        </div>
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '10px 0 0',
            fontSize: '12px',
            fontWeight: '100'
          }}
        ></span>
      </Flex>
    </Card>
  )
}

export { BackOrders }
