export const sortArrayOfObjects = (arr: any, objKey: string) => {
  return arr.sort((a: string, b: string) => {
    if (a[objKey] > b[objKey]) {
      return 1
    }
    if (b[objKey] > a[objKey]) {
      return -1
    }
    return 0
  })
}

export const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)

/**
 * @param {number} string - number of month to be Stringified. If empty, it returns stringified current month
 * @return month as localized String
 */
export const getMonth = (idx: number) => {
  const objDate = new Date()
  objDate.setDate(1)
  objDate.setMonth(idx - 1)
  const locale = 'en-us',
    month = objDate.toLocaleString(locale, { month: 'long' })
  return month
}

/**
 * @return current week
 */
export const getWeek = () => {
  const currentDate: any = new Date()
  const startDate: any = new Date(currentDate.getFullYear(), 0, 1)
  const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000))

  const weekNumber = Math.ceil(days / 7)
  return weekNumber
}

/**
 * Parse a number to locale
 * @param {number} number - the number to localize
 */
export const toLocaleNumber = (number: number): string => {
  return new Intl.NumberFormat().format(number)
}

/**
 * @return European format for a given money amount and rounded without decimals
 */
export const roundAndFormatToEuropeanMoneyAmount = (amount: number): string => {
  return Intl.NumberFormat('de-DE').format(Math.round(amount)) + ' €'
}

export const roundAndFormatToEuropeanMoneyNoSymbol = (
  amount: number
): string => {
  return Intl.NumberFormat('de-DE').format(amount)
}

export const capitalize = (sentence: string) =>
  sentence.replace(/\b\w/g, (l) => l.toUpperCase())

/**
 * Parse a localized number to a float
 * @param {string} stringNumber - the localized number
 * @param {string} locale - [optional] the locale that the number is represented in. Omit this parameter to use the current locale.
 */
export const parseLocaleNumber = (stringNumber: string, locale?: string) => {
  const thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, '')
  const decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, '')

  return parseFloat(
    stringNumber
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .replace(new RegExp('\\' + decimalSeparator), '.')
  )
}

export const arrayObjectsAreSame = (a: [], b: []) =>
  JSON.stringify(a) === JSON.stringify(b)

/**
 * getDateForNextMonths
 * @param {string} months - number of months ahead (max 11 months ahead)
 * @return {Data} - Max date
 */
export const getDateForNextMonths = (months: number) => {
  const d = new Date()
  const currentYear = d.getUTCFullYear()
  const currentMonth = d.getUTCMonth()
  return new Date(currentYear, currentMonth + months)
}

/**
 * getObjectiveSelectorValue
 * @param {string} objective - objective from the campaign
 * @return {object} - Object option necessary for the Select component
 */
export const getObjectiveSelectorValue = (objective: string) => {
  const objective_types = {
    func_obj_market: 'Market',
    func_obj_budget: 'Budget',
    func_obj_margin: 'Margin',
    func_obj_margin_market: 'Margin market',
    func_obj_neutral: 'Neutral'
  }

  return {
    value: objective,
    label: objective_types[objective] || objective
  }
}

export const getBadgeColorPercentaje = (val: number) => {
  return val > 5 ? '#28A745' : val < -5 ? '#DC3545' : '#FEB800'
}

export const getBadgeColorMonths = (val: number) => {
  return val > 2 || val < -1
    ? '#DC3545'
    : val > 1.3 && val < 1.7
    ? '#28A745'
    : '#FEB800'
}
