import { Wrapper } from './Tooltip.style'
import { TooltipProps } from './Tooltip.types'

const Tooltip = (props: TooltipProps) => {
  const { children, position = 'right', gap = 2.8, size } = props
  return (
    <Wrapper
      position={position}
      size={size}
      gap={gap}
      className="tooltip"
      data-testid="tooltip"
    >
      {children}
    </Wrapper>
  )
}

export { Tooltip }
