import styled from 'styled-components'
import { ReactComponent as Chevronleft } from 'assets/icons/chevronleft.svg'

export const Wrapper = styled.div<{ scroll: number; expanded: number }>`
  display: flex;
  align-items: center;
  gap: 1rem;
  position: sticky;
  top: 11.6rem;
  left: -1.6rem;
  padding: 0 2.4rem 0 2rem;
  height: 6.6rem;
  max-width: 100%;

  z-index: 1;
  border-radius: 3.3rem;
  width: max-content;
  background-color: ${({ scroll, theme }) =>
    scroll < 50 ? 'rgba(255, 255, 255, 0.01)' : theme.colors.white};
  box-shadow: ${({ scroll }) =>
    scroll < 50 ? 'initial' : '0px 0px 15px rgba(0, 0, 0, 0.25)'};
  margin-left: ${({ scroll }) => (scroll < 50 ? '-2rem' : '-5.2rem')};
  max-width: ${({ scroll, expanded }) => {
    if (scroll >= 50 && !expanded) {
      return '5.4rem'
    }
  }};
  height: ${({ scroll, expanded }) => {
    if (scroll >= 50 && !expanded) {
      return '5.4rem'
    }
  }};
  padding: ${({ scroll, expanded }) => {
    if (scroll >= 50 && !expanded) {
      return '0 1.6rem 0'
    }
  }};
  transition: all 0.4s;
`

export const FilterIconWrapper = styled.button<{ scroll: number }>`
  background-color: transparent;
  height: 100%;
  display: flex;
  align-items: center;
  > * {
    &:last-child {
      display: none;
    }
  }
  ${({ scroll }) =>
    scroll >= 50 &&
    `
    cursor: pointer;
    &:hover {
      >*{
        &:first-child { display: none }
        &:last-child { display: block }
      }
    }
  `}
`
export const ChevronleftIcon = styled(Chevronleft)<{
  scroll: number
  expanded: number
}>`
  transform: rotate(0);
  transform: ${({ scroll, expanded }) => {
    if (scroll >= 50 && !expanded) {
      return 'rotate(180deg)'
    }
  }};
`

export const FiltersWrapper = styled.div<{
  scroll: number
  expanded: number
}>`
  display: flex;
  align-items: center;
  gap: 1rem;
  opacity: 1;
  opacity: ${({ scroll, expanded }) => {
    if (scroll >= 50 && !expanded) {
      return 0
    }
  }};
  transition: ${({ scroll, expanded }) => {
    if (scroll >= 50 && !expanded) {
      return 'all 0.15s'
    }
    return 'all 0.2s linear 0.2s'
  }};
`

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
`

export const ButtonWrapperCentered = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`
export const ButtonWrapperCenteredVariation = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const FiltersNumber = styled.span<{
  scroll: number
  expanded: number
}>`
  opacity: 0;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  right: -1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  font-weight: 500;
  font-size: 1.2rem;
  opacity: ${({ scroll, expanded }) => {
    if (scroll >= 50 && !expanded) {
      return 1
    }
  }};
  transition: ${({ scroll, expanded }) => {
    if (scroll >= 50 && !expanded) {
      return 'opacity 0.1s linear 0.4s'
    }
    return 'opacity 0.1s'
  }};
`
