import { useTranslation } from 'react-i18next'
import { Wrapper, Title, Subtitle } from './NoData.style'
import { FeedbackProps } from './NoData.types'
import Page404 from './assets/404.png'
import Page500 from './assets/500.png'
import { Button } from 'components/Button'

const NoData = (props: FeedbackProps) => {
  const { feed = 'empty' } = props
  const { t } = useTranslation()
  const msg = (msg) => {
    switch (msg) {
      case 'empty':
        return t('utils.nodata')
      case '404':
        return t('utils.fourhundred')
      case '500':
        return t('utils.fivehundred')
    }
  }

  const subtitle = (subtitle) => {
    switch (subtitle) {
      case 'empty':
        return t('utils.nodata')
      case '404':
        return t('utils.fourhundredSubtitle')
      case '500':
        return t('utils.fivehundredSubtitle')
    }
  }

  const logo = (logo) => {
    switch (logo) {
      case 'empty':
        return Page500
      case '404':
        return Page404
      case '500':
        return Page500
    }
  }
  const message = msg(feed)
  const logoImg = logo(feed)
  const subtitleText = subtitle(feed)

  const refreshPage = () => {
    window.location.reload()
  }

  return (
    <Wrapper
      data-testid="nodata"
      style={{ backgroundImage: `url(${logoImg})`, backgroundSize: 'contain' }}
    >
      <div>
        <Title>{message}</Title>
        <Subtitle>{subtitleText}</Subtitle>
        <Button label="Refresh page" look="filled" onClick={refreshPage} />
      </div>
    </Wrapper>
  )
}

export { NoData }
