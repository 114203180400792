import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  order: -1;
`

export const PageTitle = styled.h2<{ $arrowAction?: boolean }>`
  font-size: ${(props) => (props.$arrowAction ? '16px' : '24px')};
  color: ${({ theme, color }) =>
    color ? theme.colors[color] : theme.colors.black};
  font-weight: normal;
  white-space: nowrap;
`

export const PageBreadCrumb = styled(Link)`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.gray};
  display: block;
  text-decoration: none;
  padding: 0.4rem;
  padding-right: 1rem;
  margin-left: -0.4rem;
`
