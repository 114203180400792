import { ButtonStyleProps } from './OptionSelection.types'
import styled from 'styled-components'

export const Wrapper = styled.button<ButtonStyleProps>`
  color: white;
  border-radius: 9px;
  background-color: ${({ isSelected }) =>
    isSelected ? 'rgba(216, 216, 216, 0.4);' : 'rgba(216, 216, 216, 0.15)'};
  width: ${({ tool }) => (tool ? '207px' : '140px')};
  height: ${({ tool }) => (tool ? '156px' : '59px')};
  border: ${({ isSelected }) => (isSelected ? '1px solid #FFFFFF;' : '0')};
  font-size: 15px;

  &:hover {
    cursor: pointer;
  }

  &:last-child {
    margin-left: 24px;
  }

  span {
    vertical-align: middle;
    display: inline-block;
  }

  svg {
    vertical-align: middle;
    display: inline-block;
    margin-bottom: ${({ tool }) => (tool ? '30px' : '0')};
    margin-right: ${({ tool }) => (tool ? '0px' : '5px')};

    path {
      fill: white;
    }
  }
`
