export const seatModels = [
  {
    label: 'Arona',
    value: 'arona'
  },
  {
    label: 'Ateca',
    value: 'ateca'
  },
  {
    label: 'Ibiza',
    value: 'ibiza'
  },
  {
    label: 'Leon',
    value: 'leon'
  },
  {
    label: 'Tarraco',
    value: 'tarraco'
  }
]
export const cupraModels = [
  {
    label: 'Ateca',
    value: 'ateca'
  },
  {
    label: 'Born',
    value: 'born'
  },
  {
    label: 'Formentor',
    value: 'formentor'
  },
  {
    label: 'Leon',
    value: 'leon'
  }
]

export const mtfs = [
  {
    label: 'Arona Fr Petrol',
    value: 'arona-fr-petrol',
    model: 'arona',
    trim: 'fr',
    fuel: 'petrol'
  },
  {
    label: 'Arona Reference Petrol',
    value: 'arona-reference-petrol',
    model: 'arona',
    trim: 'reference',
    fuel: 'petrol'
  },
  {
    label: 'Arona Style Petrol',
    value: 'arona-style-petrol',
    model: 'arona',
    trim: 'style',
    fuel: 'petrol'
  },
  {
    label: 'Arona Xperience Petrol',
    value: 'arona-xperience-petrol',
    model: 'arona',
    trim: 'xperience',
    fuel: 'petrol'
  },
  {
    label: 'Ateca Reference Diesel',
    value: 'ateca-reference-diesel',
    model: 'ateca',
    trim: 'reference',
    fuel: 'diesel'
  },
  {
    label: 'Ateca Reference Petrol',
    value: 'ateca-reference-petrol',
    model: 'ateca',
    trim: 'reference',
    fuel: 'petrol'
  },
  {
    label: 'Ateca Style Diesel',
    value: 'ateca-style-diesel',
    model: 'ateca',
    trim: 'style',
    fuel: 'diesel'
  },
  {
    label: 'Ateca Style Petrol',
    value: 'ateca-style-petrol',
    model: 'ateca',
    trim: 'style',
    fuel: 'petrol'
  },
  {
    label: 'Ateca Xperience Diesel',
    value: 'ateca-xperience-diesel',
    model: 'ateca',
    trim: 'xperience',
    fuel: 'diesel'
  },
  {
    label: 'Ateca Xperience Petrol',
    value: 'ateca-xperience-petrol',
    model: 'ateca',
    trim: 'xperience',
    fuel: 'petrol'
  },
  {
    label: 'Ibiza Fr Petrol',
    value: 'ibiza-fr-petrol',
    model: 'ibiza',
    trim: 'fr',
    fuel: 'petrol'
  },
  {
    label: 'Ibiza Reference Petrol',
    value: 'ibiza-reference-petrol',
    model: 'ibiza',
    trim: 'reference',
    fuel: 'petrol'
  },
  {
    label: 'Ibiza Style Petrol',
    value: 'ibiza-style-petrol',
    model: 'ibiza',
    trim: 'style',
    fuel: 'petrol'
  },
  {
    label: 'Leon 5d Fr Diesel',
    value: 'leon-5d-fr-diesel',
    model: 'leon',
    modelVariant: 'leon-5d',
    trim: 'fr',
    fuel: 'diesel'
  },
  {
    label: 'Leon 5d Fr Petrol',
    value: 'leon-5d-fr-petrol',
    model: 'leon',
    modelVariant: 'leon-5d',
    trim: 'fr',
    fuel: 'petrol'
  },
  {
    label: 'Leon 5d Style Diesel',
    value: 'leon-5d-style-diesel',
    model: 'leon',
    modelVariant: 'leon-5d',
    trim: 'style',
    fuel: 'diesel'
  },
  {
    label: 'Leon 5d Style Petrol',
    value: 'leon-5d-style-petrol',
    model: 'leon',
    modelVariant: 'leon-5d',
    trim: 'style',
    fuel: 'petrol'
  },
  {
    label: 'Tarraco Fr Disel',
    value: 'tarraco-fr-diesel',
    model: 'tarraco',
    trim: 'fr',
    fuel: 'diesel'
  },
  {
    label: 'Tarraco Fr Petrol',
    value: 'tarraco-fr-petrol',
    model: 'tarraco',
    trim: 'fr',
    fuel: 'petrol'
  },
  {
    label: 'Tarraco Style Disel',
    value: 'tarraco-style-diesel',
    model: 'tarraco',
    trim: 'style',
    fuel: 'diesel'
  },
  {
    label: 'Tarraco Style Petrol',
    value: 'tarraco-style-petrol',
    model: 'tarraco',
    trim: 'style',
    fuel: 'petrol'
  }
]

export const cupraMtfs = [
  {
    label: 'Born Electric',
    value: 'born',
    model: 'born',
    trim: 'cupra',
    fuel: 'electric'
  },
  {
    label: 'Formentor Petrol',
    value: 'formentor',
    model: 'formentor',
    trim: 'cupra',
    fuel: 'petrol'
  },
  {
    label: 'Formentor Diesel',
    value: 'formentor',
    model: 'formentor',
    trim: 'cupra',
    fuel: 'diesel'
  },
  {
    label: 'Formentor Hybrid',
    value: 'formentor',
    model: 'formentor',
    trim: 'cupra',
    fuel: 'hybrid'
  },
  {
    label: 'Ateca Petrol',
    value: 'ateca',
    model: 'ateca',
    trim: 'cupra',
    fuel: 'petrol'
  },
  {
    label: 'Leon 5d Petrol',
    value: 'leon',
    modelVariant: 'leon-5d',
    model: 'leon',
    trim: 'cupra',
    fuel: 'petrol'
  },
  {
    label: 'Leon 5d Hybrid',
    value: 'leon-5d',
    modelVariant: 'leon-5d',
    model: 'leon',
    trim: 'cupra',
    fuel: 'Hybrid'
  },
  {
    label: 'Leon st Petrol',
    value: 'leon-st',
    modelVariant: 'leon-st',
    model: 'leon',
    trim: 'cupra',
    fuel: 'petrol'
  },
  {
    label: 'Leon st Hybrid',
    value: 'leon-st',
    modelVariant: 'leon-st',
    model: 'leon',
    trim: 'cupra',
    fuel: 'hybrid'
  }
]

export const inputs = [
  {
    label: 'Cann price max',
    value: 'cann_price_max'
  },
  {
    label: 'Cann price min',
    value: 'cann_price_min'
  },
  {
    label: 'Cars in stock',
    value: 'cars_in_stock'
  },
  {
    label: 'Comp price avg',
    value: 'comp_price_avg'
  },
  {
    label: 'Comp price max',
    value: 'comp_price_max'
  },
  {
    label: 'Comp price min',
    value: 'comp_price_min'
  },
  {
    label: 'Comp regs sum',
    value: 'comp_regs_sum'
  },
  {
    label: 'Confs',
    value: 'confs'
  },
  {
    label: 'Corrected discount',
    value: 'corrected_discount'
  },
  {
    label: 'Days to production',
    value: 'days_to_production'
  },
  {
    label: 'Diesel',
    value: 'diesel'
  },
  {
    label: 'Hyb elec others',
    value: 'hyb_elec_others'
  },
  {
    label: 'IPC index',
    value: 'ipc_index'
  },
  {
    label: 'IPC variation',
    value: 'ipc_variation'
  },
  {
    label: 'Orders',
    value: 'orders'
  },
  {
    label: 'Petrol',
    value: 'petrol'
  },
  {
    label: 'PIB',
    value: 'pib'
  },
  {
    label: 'Price',
    value: 'price'
  },
  {
    label: 'Some campaign',
    value: 'some_campaign'
  },
  {
    label: 'Total budget',
    value: 'total_budget'
  },

  {
    label: 'Traffic shw',
    value: 'traffic_shw'
  },
  {
    label: 'Unemployement rate',
    value: 'unemployment_rate'
  }
]

export const trend = [
  {
    month: 2,
    year: 2023,
    value: 2895.525,
    interval: [5500, 1500],
    regression: 3500
  },
  {
    month: 3,
    year: 2023,
    value: 2899.841,
    interval: [5000, 1840],
    regression: 4000
  },
  {
    month: 4,
    year: 2023,
    value: 3278.641,
    interval: [5200, 2500],
    regression: 4100
  },
  {
    month: 5,
    year: 2023,
    value: 3824.785,
    interval: [5500, 2600],
    regression: 4500
  },
  {
    month: 6,
    year: 2023,
    value: 3931.638,
    interval: [5700, 2400],
    regression: 5000
  },
  {
    month: 7,
    year: 2023,
    value: 5587.305,
    interval: [7200, 4000],
    regression: 6500
  },
  {
    month: 8,
    year: 2023,
    value: 5543.514,
    interval: [7000, 4200],
    regression: 6200
  }
]

export const density = {
  input_data_filter: {
    brand: 'seat',
    model: 'arona',
    model_variant: 'arona',
    fuel: 'petrol',
    trim: 'fr',
    start_month: null,
    end_month: null,
    start_year: null,
    end_year: null,
    input_data_filter: 'corrected_discount'
  },
  points: [
    {
      month: 11,
      year: 2017,
      value: 1822.314
    },
    {
      month: 12,
      year: 2017,
      value: 1822.314
    },
    {
      month: 1,
      year: 2018,
      value: 1823.165
    },
    {
      month: 2,
      year: 2018,
      value: 1823.167
    },
    {
      month: 3,
      year: 2018,
      value: 1823.172
    },
    {
      month: 4,
      year: 2018,
      value: 493.736
    },
    {
      month: 5,
      year: 2018,
      value: 1905.244
    },
    {
      month: 6,
      year: 2018,
      value: 1938.254
    },
    {
      month: 7,
      year: 2018,
      value: 1970.074
    },
    {
      month: 8,
      year: 2018,
      value: 2074.556
    },
    {
      month: 9,
      year: 2018,
      value: 2102.004
    },
    {
      month: 10,
      year: 2018,
      value: 960.428
    },
    {
      month: 11,
      year: 2018,
      value: 1605.298
    },
    {
      month: 12,
      year: 2018,
      value: 1215.743
    },
    {
      month: 1,
      year: 2019,
      value: 2746.716
    },
    {
      month: 2,
      year: 2019,
      value: 2566.355
    },
    {
      month: 3,
      year: 2019,
      value: 1542.164
    },
    {
      month: 4,
      year: 2019,
      value: 1878.195
    },
    {
      month: 5,
      year: 2019,
      value: 1872.636
    },
    {
      month: 6,
      year: 2019,
      value: 1762.481
    },
    {
      month: 7,
      year: 2019,
      value: 1885.902
    },
    {
      month: 8,
      year: 2019,
      value: 2813.017
    },
    {
      month: 9,
      year: 2019,
      value: 1920.188
    },
    {
      month: 10,
      year: 2019,
      value: 2052.968
    },
    {
      month: 11,
      year: 2019,
      value: 2025.42
    },
    {
      month: 12,
      year: 2019,
      value: 2051.762
    },
    {
      month: 1,
      year: 2020,
      value: 2295.851
    },
    {
      month: 2,
      year: 2020,
      value: 2333.446
    },
    {
      month: 3,
      year: 2020,
      value: 2334.711
    },
    {
      month: 4,
      year: 2020,
      value: 2238.828
    },
    {
      month: 5,
      year: 2020,
      value: 2214.035
    },
    {
      month: 6,
      year: 2020,
      value: 2214.035
    },
    {
      month: 7,
      year: 2020,
      value: 2736.564
    },
    {
      month: 8,
      year: 2020,
      value: 2602.479
    },
    {
      month: 9,
      year: 2020,
      value: 2387.603
    },
    {
      month: 10,
      year: 2020,
      value: 2633.058
    },
    {
      month: 11,
      year: 2020,
      value: 2822.314
    },
    {
      month: 12,
      year: 2020,
      value: 2834.711
    },
    {
      month: 1,
      year: 2021,
      value: 2465.958
    },
    {
      month: 2,
      year: 2021,
      value: 2494.54
    },
    {
      month: 3,
      year: 2021,
      value: 2493.207
    },
    {
      month: 4,
      year: 2021,
      value: 2322.639
    },
    {
      month: 5,
      year: 2021,
      value: 2329.431
    },
    {
      month: 6,
      year: 2021,
      value: 2721.637
    },
    {
      month: 7,
      year: 2021,
      value: 2755.764
    },
    {
      month: 8,
      year: 2021,
      value: 2755.764
    },
    {
      month: 9,
      year: 2021,
      value: 2696.483
    },
    {
      month: 10,
      year: 2021,
      value: 2696.483
    },
    {
      month: 11,
      year: 2021,
      value: 2473.334
    },
    {
      month: 12,
      year: 2021,
      value: 2398.141
    },
    {
      month: 1,
      year: 2022,
      value: 2137.09
    },
    {
      month: 2,
      year: 2022,
      value: 2137.09
    },
    {
      month: 3,
      year: 2022,
      value: 2137.09
    },
    {
      month: 4,
      year: 2022,
      value: 2136.132
    },
    {
      month: 5,
      year: 2022,
      value: 2136.132
    },
    {
      month: 6,
      year: 2022,
      value: 1906.289
    },
    {
      month: 7,
      year: 2022,
      value: 2530.322
    },
    {
      month: 8,
      year: 2022,
      value: 2530.322
    },
    {
      month: 9,
      year: 2022,
      value: 2359.203
    },
    {
      month: 10,
      year: 2022,
      value: 2354.145
    },
    {
      month: 11,
      year: 2022,
      value: 2194.55
    },
    {
      month: 12,
      year: 2022,
      value: 2111.95
    },
    {
      month: 1,
      year: 2023,
      value: 1756.678
    },
    {
      month: 2,
      year: 2023,
      value: 1756.678
    },
    {
      month: 3,
      year: 2023,
      value: 1761.157
    },
    {
      month: 4,
      year: 2023,
      value: 1775.917
    },
    {
      month: 5,
      year: 2023,
      value: 1892.835
    },
    {
      month: 6,
      year: 2023,
      value: 1904.132
    },
    {
      month: 7,
      year: 2023,
      value: 2062.645
    },
    {
      month: 8,
      year: 2023,
      value: 2103.967
    },
    {
      month: 9,
      year: 2023,
      value: 1860.107
    },
    {
      month: 10,
      year: 2023,
      value: 1827.05
    },
    {
      month: 11,
      year: 2023,
      value: 2664.174
    }
  ],
  sum_value: 156783.944,
  histogram: [
    {
      value: 1822.314,
      count: 2
    },
    {
      value: 1823.165,
      count: 1
    },
    {
      value: 1823.167,
      count: 1
    },
    {
      value: 1823.172,
      count: 1
    },
    {
      value: 493.736,
      count: 1
    },
    {
      value: 1905.244,
      count: 1
    },
    {
      value: 1938.254,
      count: 1
    },
    {
      value: 1970.074,
      count: 1
    },
    {
      value: 2074.556,
      count: 1
    },
    {
      value: 2102.004,
      count: 1
    },
    {
      value: 960.428,
      count: 1
    },
    {
      value: 1605.298,
      count: 1
    },
    {
      value: 1215.743,
      count: 1
    },
    {
      value: 2746.716,
      count: 1
    },
    {
      value: 2566.355,
      count: 1
    },
    {
      value: 1542.164,
      count: 1
    },
    {
      value: 1878.195,
      count: 1
    },
    {
      value: 1872.636,
      count: 1
    },
    {
      value: 1762.481,
      count: 1
    },
    {
      value: 1885.902,
      count: 1
    },
    {
      value: 2813.017,
      count: 1
    },
    {
      value: 1920.188,
      count: 1
    },
    {
      value: 2052.968,
      count: 1
    },
    {
      value: 2025.42,
      count: 1
    },
    {
      value: 2051.762,
      count: 1
    },
    {
      value: 2295.851,
      count: 1
    },
    {
      value: 2333.446,
      count: 1
    },
    {
      value: 2334.711,
      count: 1
    },
    {
      value: 2238.828,
      count: 1
    },
    {
      value: 2214.035,
      count: 2
    },
    {
      value: 2736.564,
      count: 1
    },
    {
      value: 2602.479,
      count: 1
    },
    {
      value: 2387.603,
      count: 1
    },
    {
      value: 2633.058,
      count: 1
    },
    {
      value: 2822.314,
      count: 1
    },
    {
      value: 2834.711,
      count: 1
    },
    {
      value: 2465.958,
      count: 1
    },
    {
      value: 2494.54,
      count: 1
    },
    {
      value: 2493.207,
      count: 1
    },
    {
      value: 2322.639,
      count: 1
    },
    {
      value: 2329.431,
      count: 1
    },
    {
      value: 2721.637,
      count: 1
    },
    {
      value: 2755.764,
      count: 2
    },
    {
      value: 2696.483,
      count: 2
    },
    {
      value: 2473.334,
      count: 1
    },
    {
      value: 2398.141,
      count: 1
    },
    {
      value: 2137.09,
      count: 3
    },
    {
      value: 2136.132,
      count: 2
    },
    {
      value: 1906.289,
      count: 1
    },
    {
      value: 2530.322,
      count: 2
    },
    {
      value: 2359.203,
      count: 1
    },
    {
      value: 2354.145,
      count: 1
    },
    {
      value: 2194.55,
      count: 1
    },
    {
      value: 2111.95,
      count: 1
    },
    {
      value: 1756.678,
      count: 2
    },
    {
      value: 1761.157,
      count: 1
    },
    {
      value: 1775.917,
      count: 1
    },
    {
      value: 1892.835,
      count: 1
    },
    {
      value: 1904.132,
      count: 1
    },
    {
      value: 2062.645,
      count: 1
    },
    {
      value: 2103.967,
      count: 1
    },
    {
      value: 1860.107,
      count: 1
    },
    {
      value: 1827.05,
      count: 1
    },
    {
      value: 2664.174,
      count: 1
    }
  ]
}

export const density2 = {
  input_data_filter: {
    brand: 'seat',
    model: 'arona',
    model_variant: 'arona',
    fuel: 'petrol',
    trim: 'fr',
    start_month: null,
    end_month: null,
    start_year: null,
    end_year: null,
    input_data_filter: 'corrected_discount'
  },
  points: [
    {
      month: 11,
      year: 2017,
      value: 10
    },
    {
      month: 12,
      year: 2017,
      value: 11
    },
    {
      month: 11,
      year: 2017,
      value: 12
    },
    {
      month: 12,
      year: 2017,
      value: 13
    },
    {
      month: 11,
      year: 2017,
      value: 14
    },
    {
      month: 12,
      year: 2017,
      value: 15
    }
  ],
  sum_value: 156783.944,
  histogram: [
    {
      value: 10,
      count: 3
    },
    {
      value: 11,
      count: 1
    },
    {
      value: 12,
      count: 1
    },
    {
      value: 13,
      count: 1
    },
    {
      value: 14,
      count: 2
    },
    {
      value: 15,
      count: 0
    }
  ]
}

export const density3 = {
  input_data_filter: {
    brand: 'seat',
    model: 'arona',
    model_variant: 'arona',
    fuel: 'petrol',
    trim: 'fr',
    start_month: null,
    end_month: null,
    start_year: null,
    end_year: null,
    input_data_filter: 'corrected_discount'
  },
  histogram: {
    hist: [2, 1, 0, 0, 0, 0, 0, 2],
    bins: [
      1324.392, 1430.964825, 1537.53765, 1644.110475, 1750.6833000000001,
      1857.256125, 1963.82895, 2070.4017750000003
    ]
  }
}
