import { useCallback, useState } from 'react'
import { ReactDatePicker, Wrapper } from './Datepicker.style'
import { DatepickerProps } from './Datepicker.types'
import * as amplitude from '@amplitude/analytics-browser'

const Datepicker = (props: DatepickerProps) => {
  const {
    onUpdateValue,
    defaultValue,
    minDate,
    maxDate,
    emptyPlaceholder,
    reference,
    fullWidth = false,
    look,
    fixedWidth,
    id,
    readOnly
  } = props
  const [startDate, setStartDate] = useState(defaultValue || null)
  const updateDate = useCallback(
    (date) => {
      amplitude.track(`Date picked`)
      setStartDate(date)
      onUpdateValue(id, date)
    },
    [id, onUpdateValue]
  )
  return (
    <Wrapper
      data-testid="input-datepicker"
      fullWidth={fullWidth}
      look={look}
      fixedWidth={fixedWidth}
    >
      <ReactDatePicker
        selected={startDate}
        onChange={updateDate}
        dateFormat="MMMM yyyy"
        maxDate={maxDate}
        minDate={minDate}
        showMonthYearPicker
        wrapperClassName="datepicker"
        placeholderText={emptyPlaceholder}
        ref={reference}
        readOnly={readOnly}
      />
    </Wrapper>
  )
}

export { Datepicker }
