import { useEffect, useState } from 'react'
import Slider from 'rc-slider'
import './styles/InputRange.css'
import { InputRangeProps } from './InputRange.types'

const InputRange = (props: InputRangeProps) => {
  const { min, max, onChange } = props
  const [value, setValue] = useState([min, max])

  const marks = {
    [min]: min,
    [max]: max
  }

  const handleInputChange = (nextValue) => {
    onChange(nextValue)
  }

  useEffect(() => {
    setValue([min, max])
  }, [min, max])

  return (
    <div style={{ width: '300px' }}>
      <Slider
        range
        marks={marks}
        min={min}
        max={max}
        value={value}
        allowCross={false}
        onChange={(nextValue) => setValue(nextValue as any)}
        onChangeComplete={handleInputChange}
      />
    </div>
  )
}

export { InputRange }
