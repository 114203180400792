import styled from 'styled-components'

export const DensityHistogramStyles = styled.div`
  .tooltip {
    min-height: 60px;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`
