import styled from 'styled-components'

export const ViewPort = styled.div`
  width: 100%;
  min-height: calc(100vh - 96px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  padding: 40px 20px;
`
