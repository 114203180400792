import { useTranslation } from 'react-i18next'
import {
  CardContent,
  CardFooter,
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle,
  CardWrapper,
  TooltipContainer,
  RouterLink,
  InputSearch,
  SelectContainer
} from './Card.style'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import { CardProps } from './Card.types'
import { Tooltip } from 'components/Tooltip'
import { InputText } from 'components/Inputs/Text'
import { useState } from 'react'
import { Select } from 'components/Inputs/Select'

const Card = (props: CardProps) => {
  const {
    headerTitle,
    subtitle,
    footerCaption,
    children,
    headerTo,
    gridType = 'default',
    upperCaseTitle = false,
    headerComponent,
    multiSelectComponent,
    minHeightBody = 9.2,
    reference,
    tooltip,
    withSearch,
    searchVal,
    searchFunc,
    searchLabel,
    withSelect,
    selectVal,
    selectFunc,
    selectOptions,
    disableMultiSelect
  } = props
  const { t } = useTranslation()
  const [active, setActive] = useState(false)

  const handleShowTooltip = () => {
    setActive(true)
  }
  const handleHideTooltip = () => {
    setActive(false)
  }

  return (
    <CardWrapper ref={reference}>
      {headerTitle && (
        <CardHeader>
          <div>
            <CardHeaderTitle upperCaseTitle={upperCaseTitle}>
              {headerTitle}
            </CardHeaderTitle>
            <CardHeaderSubtitle>{subtitle}</CardHeaderSubtitle>
          </div>
          {headerTo && <RouterLink to={headerTo}>{t('utils.view')}</RouterLink>}
          {withSearch && (
            <InputSearch>
              <InputText
                label={searchLabel}
                placeholder="Objective"
                id={searchVal}
                look="small"
                type="number"
                onRefreshValue={searchFunc}
              />
            </InputSearch>
          )}
          {multiSelectComponent}

          {withSelect && selectVal && (
            <SelectContainer>
              <Select
                options={selectOptions}
                isMulti
                emptyPlaceholder="Variables"
                id="variables"
                onUpdateValue={selectFunc}
                defaultValue={selectVal}
                disableMultiSelect={disableMultiSelect}
                minWidth={300}
              />
            </SelectContainer>
          )}
          {headerComponent}
          {tooltip && (
            <TooltipContainer>
              <InfoIcon
                onMouseEnter={handleShowTooltip}
                onMouseLeave={handleHideTooltip}
              />
              {active && (
                <Tooltip size={30} position="bottom-left">
                  {tooltip}
                </Tooltip>
              )}
            </TooltipContainer>
          )}
        </CardHeader>
      )}
      <CardContent gridType={gridType} minHeightBody={minHeightBody}>
        {children}
      </CardContent>
      {footerCaption && <CardFooter>{footerCaption}</CardFooter>}
    </CardWrapper>
  )
}

export { Card }
