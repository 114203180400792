import styled from 'styled-components'

export const BadgeContainer = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-right: 8px;
  margin-left: auto;
  display: inline-block;
`
export const BadgeContent = styled.div<{ $color?: string }>`
  background-color: ${(props) => props.$color};
  color: ${(props) => (props.$color === '#FEB800' ? '#000' : '#fff')};
  padding: 0px 8px;
  border-radius: 4px;
`
