import { api } from 'api/api'

export const getLastAvailableData = async (
  uuid: string,
  queryParams: string
) => {
  const response = await api.get(
    '/optimization/demand-forecast-input-data/last-available-data',
    queryParams,
    uuid,
    2
  )
  return response
}

export const getModelsFilterList = async (
  uuid: string,
  queryParams: string
) => {
  const response = await api.get(
    '/optimization/demand-forecast-input-data-models',
    queryParams,
    uuid,
    2
  )
  return response
}

export const getMTFsFilterList = async (uuid: string, queryParams: string) => {
  const response = await api.get(
    '/optimization/demand-forecast-input-data-mtfs',
    queryParams,
    uuid,
    2
  )
  return response
}

export const getInputsFilterList = async (
  uuid: string,
  queryParams: string
) => {
  const response = await api.get(
    '/optimization/demand-forecast-input-data-variables',
    queryParams,
    uuid,
    2
  )
  return response
}

export const getDensityHistogramData = async (
  uuid: string,
  queryParams: string
) => {
  const response = await api.get(
    '/optimization/demand-forecast-input-data-histogram-by-mtf',
    queryParams,
    uuid,
    2
  )
  return response
}

export const getDensityHistogramDataByModel = async (
  uuid: string,
  queryParams: string
) => {
  const response = await api.get(
    '/optimization/demand-forecast-input-data-histogram-by-model',
    queryParams,
    uuid,
    2
  )
  return response
}

export const getTrendVariable = async (uuid: string, queryParams: string) => {
  const response = await api.get(
    '/optimization/demand-forecast-input-data-timeline-by-mtf',
    queryParams,
    uuid,
    2
  )
  return response
}

export const getTrendVariableByModel = async (
  uuid: string,
  queryParams: string
) => {
  const response = await api.get(
    '/optimization/demand-forecast-input-data-timeline-by-model',
    queryParams,
    uuid,
    2
  )
  return response
}

export const getInputDataVariable = async (
  uuid: string,
  queryParams: string
) => {
  const response = await api.get(
    '/optimization/demand-forecast-input-data-variables',
    queryParams,
    uuid,
    2
  )
  return response
}
