import styled from 'styled-components'

type columnAlign = 'left' | 'center' | 'right'

export const TableWrapper = styled.table<{
  sticky?: boolean
}>`
  width: 100%;
  max-width: 100%;
  line-height: 1.4;
  overflow: ${({ sticky }) => !sticky && 'hidden'};
  overflow-y: hidden;
  overflow-x: ${({ sticky }) => sticky && 'scroll'};
  display: ${({ sticky }) => sticky && 'block'};
`

export const THWrapper = styled.span<{
  columnAlign?: columnAlign
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ columnAlign }) => {
    if (columnAlign === 'left') {
      return 'start'
    }
    if (columnAlign === 'right') {
      return 'end'
    }
    return 'center'
  }};
`

export const TH = styled.th<{
  sticky?: boolean
  highlight?: boolean
  checked?: boolean
  columnAlign?: columnAlign
}>`
  text-align: ${({ columnAlign }) => (columnAlign ? columnAlign : 'center')};
  padding: 0 1.6rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  position: relative;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ sticky, theme }) => sticky && theme.colors.white};
  background-color: ${({ highlight }) => highlight && '#FFEEE5'};
  mix-blend-mode: ${({ highlight }) => highlight && 'multiply'};
  height: 4.4rem;
  &:first-child {
    ${THWrapper} {
      justify-content: start;
    }
  }
  ${({ sticky }) =>
    sticky &&
    `
      position: sticky;
      left: 0;
      z-index: 2;
      &:after {
        content: '';
        width: 2rem;
        position: absolute;
        right: -2rem;
        top: 0;
        height: 100%;
        background: linear-gradient(90deg, #00000015, #00000000);
      }
  `};

  ${({ checked }) =>
    checked &&
    `
      &:after {
        content: '';
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 13px 13px 0 0;
      }
  `};

  &:after {
    border-color: ${({ checked, theme }) =>
      checked &&
      theme.colors.primary + ' transparent' + ' transparent' + ' transparent'};
  }
`

export const TR = styled.tr<{ highlight?: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  &:nth-child(2n + 1) {
    background-color: ${({ theme, highlight }) =>
      highlight ? '#dedede' : theme.colors.bgPage};
  }
  background-color: ${({ highlight }) => highlight && '#dedede'};
  &:first-child {
    ${TH} {
      &:before {
        content: '';
        position: absolute;
        height: 80rem;
        width: 1px;
        right: 0;
        top: 0;
        background-color: #dedede80;
      }
    }
  }
`

export const TD = styled.td<{
  bold?: boolean
  sticky?: boolean
  highlight?: boolean
  columnAlign?: columnAlign
}>`
  text-align: ${({ columnAlign }) => (columnAlign ? columnAlign : 'center')};
  padding: 0 1.6rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ highlight }) => highlight && '#FFEEE5'};
  mix-blend-mode: ${({ highlight }) => highlight && 'multiply'};
  height: 4.4rem;
  ${({ bold }) =>
    bold &&
    `
      font-weight: 500;
  `};
  ${({ sticky }) =>
    sticky &&
    `
      position: sticky;
      left: 0;
      z-index: 1;
      background-color: inherit;
  `};
  &:after {
    content: '';
    width: 2rem;
    position: absolute;
    right: -2rem;
    top: 0;
    height: 100%;
    background: ${({ sticky }) =>
      sticky && 'linear-gradient(90deg, #00000015, #00000000)'};
  }
  > span {
    color: ${({ theme }) => theme.colors.gray};
  }
`

export const TDspan = styled.span<{ breaklines?: boolean }>`
  margin-left: ${({ breaklines }) => !breaklines && '0.3rem'};
  display: ${({ breaklines }) => breaklines && 'block'};
`

export const Thead = styled.thead`
  ${TR} {
    background-color: inherit;
    border-top: ${({ theme }) => '1px solid' + theme.colors.border};
  }
`

export const Tbody = styled.tbody``
