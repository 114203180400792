import { BarChart, Bar, XAxis, YAxis } from 'recharts'
import { BarChartTypes } from './BarChartTypes'
import styled from 'styled-components'

export const Legend = styled.div`
  text-align: center;
  padding: 0 20px;

  h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
  }

  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
`

export const LegendMonths = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
`

export const LegendBadge = styled.span<{ $color?: string }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  margin-top: 4px;

  &::before {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: ${(props) => props.$color};
    margin-right: 4px;
  }
`

const keys = ['0-90', '91-120', '121-180', '181-270', '+271']

const COLORS = ['#AADF89', '#C4E9AC', '#EDF580', '#F6C55A', '#FFA074']

const BarChartStacked = (props: BarChartTypes) => {
  const { data, legend, width, hideLegend } = props

  return (
    <>
      <BarChart
        width={width ?? 400}
        height={60}
        data={[data?.time_ranges]}
        margin={{ top: 5, right: 0, left: -50, bottom: 0 }}
        layout="vertical"
      >
        <XAxis type="number" style={{ display: 'none' }} />
        <YAxis type="category" style={{ display: 'none' }} />
        {keys.map((key: string, index: number): any => {
          const bars: any = []
          bars.push(
            <Bar key={index} dataKey={key} stackId="a" fill={COLORS[index]} />
          )
          return bars
        })}
      </BarChart>
      {data && data?.time_ranges && !hideLegend && (
        <Legend>
          <h3>{data.stock}</h3>
          <h4>{legend}</h4>
          <LegendMonths>
            {Object.entries(data?.time_ranges)?.map((el, index) => (
              <div key={index}>
                {el[1]}
                <LegendBadge $color={COLORS[index]}>{keys[index]}</LegendBadge>
              </div>
            ))}
          </LegendMonths>
        </Legend>
      )}
    </>
  )
}

export { BarChartStacked as BarChartStacked }
