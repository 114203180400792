import { Container } from 'layout/Content/Content.style'
import { Outlet } from 'react-router-dom'

const Pricing = () => {
  return (
    <>
      <Container>
        <Outlet />
      </Container>
    </>
  )
}

export { Pricing }
