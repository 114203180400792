import { useOutsideAlerter } from 'hooks/utils'
import { useCallback, useState } from 'react'
import { Button, Options, Option, Wrapper } from './ButtonDropdown.style'
import { ButtonDropdownProps } from './ButtonDropdown.types'

const ButtonDropdown = (props: ButtonDropdownProps) => {
  const { label, options, outsideAlerterRef } = props
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenuButton = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const closeMenuButton = useCallback(() => {
    setIsOpen(false)
  }, [])

  useOutsideAlerter(outsideAlerterRef, () => {
    setIsOpen(false)
  })

  return (
    <Wrapper
      data-html2canvas-ignore="true"
      data-testid="buttondropdown-component"
    >
      <Button onClick={toggleMenuButton}>{label}</Button>
      <Options isOpen={isOpen} onClick={closeMenuButton}>
        {options &&
          options.map(({ label, onClick }) => (
            <Option tabIndex={0} onClick={onClick} key={label}>
              {label}
            </Option>
          ))}
      </Options>
    </Wrapper>
  )
}

export { ButtonDropdown }
