import { Card } from 'components/Card'
import { BarChartStacked } from 'components/Graphs/BarChart/BarChartStacked'
import styled from 'styled-components'
import { getMonthFullName } from '../../../api/utils'

export const BadgeLegend = styled.div<{ $color?: string }>`
  display: flex;
  margin-top: 4px;

  &::before {
    content: '';
    width: 16px;
    height: 16px;
    background-color: ${(props) => props.$color};
    border-radius: 4px;
    margin-right: 4px;
  }
`

const LogisticStock = ({ logisticStock }) => {
  return (
    <Card
      headerTitle="Stock Ageing / Logistic Stock"
      gridType="default"
      tooltip={<p>Information about the age of logistic stock</p>}
    >
      <BarChartStacked
        layout="vertical"
        data={logisticStock}
        legend="Total Logistic Stock"
        total={logisticStock.total_logistic_stock}
      />
      <span
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 0 0',
          fontSize: '12px',
          fontWeight: '100'
        }}
      >
        {`${getMonthFullName(logisticStock?.month)} data`}
      </span>
    </Card>
  )
}

export { LogisticStock }
