/* eslint-disable no-debugger */
import { useInputForecastStore } from 'state'
import { shallow } from 'zustand/shallow'

export const useInputForecastParams = () => {
  const {
    inputForecastModel,
    inputForecastModelVariant,
    inputForecastPeriodStart,
    inputForecastPeriodEnd,
    inputForecastObjective
  } = useInputForecastStore(
    (state: any) => ({
      inputForecastModel: state.inputForecastModel,
      inputForecastModelVariant: state.inputForecastModelVariant,
      inputForecastPeriodStart: state.inputForecastPeriodStart,
      inputForecastPeriodEnd: state.inputForecastPeriodEnd,
      inputForecastObjective: state.inputForecastObjective
    }),
    shallow
  )

  const queryModel = inputForecastModel?.value?.toLowerCase()
  const queryModelVariant = inputForecastModelVariant?.model_variant
    ? inputForecastModelVariant?.model_variant?.toLowerCase()
    : ''
  const queryFuel = inputForecastModelVariant?.fuel
    ? inputForecastModelVariant?.fuel?.toLowerCase()
    : ''
  const queryTrim = inputForecastModelVariant?.trim
    ? inputForecastModelVariant?.trim?.toLowerCase()
    : ''
  const queryObjective = inputForecastObjective?.value.toLowerCase()

  let queryStartMonth
  let queryStartYear
  let queryEndMonth
  let queryEndYear

  const getDateFormatParam = (input, type?: String) => {
    if (input !== null) {
      if (typeof input === 'string') {
        type === 'start'
          ? (queryStartMonth = parseInt(input.split('-')[1]))
          : (queryEndMonth = parseInt(input.split('-')[1]))
        type === 'start'
          ? (queryStartYear = parseInt(input.split('-')[0]))
          : (queryEndYear = parseInt(input.split('-')[0]))
      } else {
        if (input?.getUTCMonth() + 2 > 12) {
          type === 'start' ? (queryStartMonth = 1) : (queryEndMonth = 1)
          type === 'start'
            ? (queryStartYear = input.getFullYear())
            : (queryEndYear = inputForecastPeriodEnd.getFullYear())
        }
        if (input?.getUTCMonth() + 2 <= 12) {
          type === 'start'
            ? (queryStartMonth = input.getUTCMonth() + 2)
            : (queryEndMonth = inputForecastPeriodEnd.getUTCMonth() + 2)
          type === 'start'
            ? (queryStartYear = input.getFullYear())
            : (queryEndYear = inputForecastPeriodEnd.getFullYear())
        }
      }
    }
  }

  getDateFormatParam(inputForecastPeriodStart, 'start')
  getDateFormatParam(inputForecastPeriodEnd)

  return {
    model: queryModel,
    fuel: queryFuel,
    trim: queryTrim,
    startMonth: queryStartMonth,
    startYear: queryStartYear,
    endMonth: queryEndMonth,
    endYear: queryEndYear,
    objective: queryObjective,
    modelVariant: queryModelVariant
  }
}
