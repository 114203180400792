import { Oval } from './Spinner.style'
import { SpinnerProps } from './Spinner.types'

const Spinner = (props: SpinnerProps) => {
  const { scope } = props
  return (
    <Oval data-testid="spinner" scope={scope}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Oval>
  )
}

export { Spinner }
