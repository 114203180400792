import { BarChart, Bar, YAxis, LabelList, ResponsiveContainer } from 'recharts'
import { BarChartTypes } from './BarChartTypes'
import { getBadgeColorPercentaje } from 'utils'

const COLORS = ['#018A7F', '#96E5E5', '#4FBAB9']

const BarChartComponent = (props: BarChartTypes) => {
  const { dataLegend } = props

  const renderCustomizedLabel = (props: any) => {
    const { x, value, index } = props

    const backOrdersValue = value[0]
    const description = value[1]
    const month = value[2]

    return (
      <g>
        <text
          x={x + 10}
          y={105}
          fill={index === 0 ? '#fff' : '#000'}
          textAnchor="start"
          fontSize="18"
          fontWeight="bold"
        >
          {backOrdersValue}
        </text>

        <text
          x={x + 10}
          y={120}
          fill={index === 0 ? '#fff' : '#000'}
          textAnchor="start"
          fontSize="12"
        >
          {description}
        </text>

        <text
          x={x + 10}
          y={135}
          fill={index === 0 ? '#fff' : '#000'}
          textAnchor="start"
          fontSize="12"
          fontWeight="bold"
        >
          {month}
        </text>
      </g>
    )
  }

  const customizedLabel = (props: any) => {
    const { x, y, value } = props
    const padding = 5
    const textWidth = value !== null ? value?.toString().length * 8 : 0
    const textHeight = 16

    return (
      <g>
        <rect
          x={x + 40}
          y={y - textHeight - 8}
          width={textWidth !== 0 ? textWidth + padding * 2 : 0}
          height={textHeight + padding}
          fill={getBadgeColorPercentaje(value)}
          rx={4}
          ry={4}
        />
        <text
          x={x + 45}
          y={y - 10}
          fill={
            value > 2 || value < -1 || (value > 1.3 && value < 1.7)
              ? '#fff'
              : '#000'
          }
          textAnchor="start"
          fontSize="12"
          fontWeight="normal"
        >
          {value ? `${value}%` : ''}
        </text>
      </g>
    )
  }

  const dataWithColors = dataLegend.map((entry, index) => ({
    ...entry,
    fill: COLORS[index % COLORS.length]
  }))

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        barCategoryGap={0}
        width={400}
        data={dataWithColors}
        margin={{ top: 40, right: 20, left: -20, bottom: 0 }}
      >
        <YAxis style={{ display: 'none' }} />

        <Bar dataKey="back_orders" isAnimationActive={false}>
          <LabelList dataKey="legend" content={renderCustomizedLabel} />
          <LabelList
            dataKey="deviation_with_current_month_back_orders"
            content={customizedLabel}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export { BarChartComponent as BarChart }
