import styled from 'styled-components'

export const ColGrid = styled.div<{ equalHeight: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 0 1rem;
  flex: 1 1;
  max-width: 100%;
  position: relative;
  ${({ equalHeight }) =>
    !equalHeight &&
    `
    align-self: flex-start
  `}
`
