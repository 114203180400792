/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from 'layout/Content/Content.style'
import { Outlet } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { BrandContext } from 'contexts/BrandProvider'
import { CUPRA_THEME } from '../../constants/themes'

const SmartOrder = () => {
  const { setBrand } = useContext(BrandContext)

  useEffect(() => {
    setBrand(CUPRA_THEME)
  }, [])

  return (
    <>
      <Container>
        <Outlet />
      </Container>
    </>
  )
}

export { SmartOrder }
