import styled from 'styled-components'

type columnAlign = 'left' | 'center' | 'right'

export const TableWrapper = styled.table`
  width: 100%;
  max-width: 100%;
  line-height: 1.4;
  overflow-y: hidden;
`

export const THWrapper = styled.span<{
  columnAlign?: columnAlign
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ columnAlign }) => {
    if (columnAlign === 'left') {
      return 'start'
    }
    if (columnAlign === 'right') {
      return 'end'
    }
    return 'center'
  }};
`

export const TH = styled.th<{
  columnAlign?: columnAlign
  pointer?: boolean
}>`
  text-align: ${({ columnAlign }) => (columnAlign ? columnAlign : 'center')};
  cursor: ${({ pointer }) => pointer && 'pointer'};
  padding: 0 1.6rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  position: relative;
  color: ${({ theme }) => theme.colors.black};
  height: 4.4rem;
  &:first-child {
    ${THWrapper} {
      justify-content: start;
    }
  }
`

export const TR = styled.tr`
  background-color: ${({ theme }) => theme.colors.white};
  &:nth-child(2n + 1) {
    background-color: ${({ theme }) => theme.colors.bgPage};
  }
`

export const TD = styled.td<{
  bold?: boolean
  columnAlign?: columnAlign
  sortable?: boolean
}>`
  text-align: ${({ columnAlign }) => (columnAlign ? columnAlign : 'center')};
  padding: 0 1.6rem;
  padding-right: ${({ sortable }) => sortable && '3.4rem'};
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.black};
  height: 4.4rem;
  ${({ bold }) =>
    bold &&
    `
      font-weight: 500;
  `};
  span {
    color: ${({ theme }) => theme.colors.gray};
  }
`

export const TDspan = styled.span<{ breaklines: boolean }>`
  margin-left: ${({ breaklines }) => !breaklines && '0.3rem'};
  display: ${({ breaklines }) => breaklines && 'block'};
`

export const Thead = styled.thead`
  ${TR} {
    background-color: inherit;
  }
`

export const Tbody = styled.tbody``

export const Tfoot = styled.tfoot<{ hasTotalFooter?: boolean }>`
  ${TR} {
    background-color: ${({ hasTotalFooter }) =>
      hasTotalFooter ? '#d7d9db' : 'inherit'};
  }
`
