import {
  TD,
  TH,
  TR,
  TableWrapper,
  Thead,
  Tbody,
  THWrapper,
  Tfoot
} from './Simple.style'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  SortingState,
  ColumnResizeMode
} from '@tanstack/react-table'
import { ReactComponent as SortIcon } from 'assets/icons/sort.svg'
import { ReactComponent as SortUpIcon } from 'assets/icons/sortUp.svg'
import { ReactComponent as SortDownIcon } from 'assets/icons/sortDown.svg'
import { useState } from 'react'
import { SimpleTableProps } from '../Table.types'

// eslint-disable-next-line no-undef
const ColumnSortBy = (colHeader): JSX.Element => {
  const { header } = colHeader
  const sortType = header.column.getIsSorted() as string
  if (sortType === 'asc') {
    return <SortDownIcon />
  }
  if (sortType === 'desc') {
    return <SortUpIcon />
  }
  return <SortIcon />
}

const SimpleTable = (props: SimpleTableProps) => {
  const {
    data,
    columns,
    sortable = false,
    sortBy = [],
    customFields,
    hasTotalFooter
  } = props
  const [sorting, setSorting] = useState<SortingState>(sortBy)
  const [columnResizeMode] = useState<ColumnResizeMode>()

  const table = useReactTable({
    data,
    columns,
    defaultColumn: customFields ? customFields : undefined,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode
  })

  const hasFooter = columns.some((column) => 'footer' in column)

  return (
    <TableWrapper data-testid="simple-table-component">
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TR key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const colDef: any = header.column.columnDef
              const THprops = {
                columnAlign:
                  'columnAlign' in colDef ? colDef.columnAlign : 'center',
                rowSpan: 'rowSpan' in colDef ? colDef.rowSpan.toString() : '1',
                pointer: sortable
              }
              return (
                <TH
                  key={header.id}
                  {...THprops}
                  colSpan={header.colSpan}
                  style={{ width: header.getSize() }}
                >
                  {!header.isPlaceholder && sortable && (
                    <THWrapper
                      {...{
                        onClick: header.column.getToggleSortingHandler()
                      }}
                      columnAlign={THprops.columnAlign}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      <ColumnSortBy header={header} />
                    </THWrapper>
                  )}
                  {!header.isPlaceholder && !sortable && (
                    <THWrapper columnAlign={THprops.columnAlign}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </THWrapper>
                  )}
                </TH>
              )
            })}
          </TR>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map((row) => (
          <TR key={row.id}>
            {row.getVisibleCells().map((cell) => {
              const colDef: any = cell.column.columnDef
              const TDprops = {
                columnAlign:
                  'columnAlign' in colDef ? colDef.columnAlign : 'center',
                bold: 'bold' in colDef && !!colDef.bold,
                sortable
              }

              return (
                <TD
                  key={cell.id}
                  {...TDprops}
                  style={{ width: cell.column.getSize() }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TD>
              )
            })}
          </TR>
        ))}
      </Tbody>
      {hasFooter && (
        <Tfoot hasTotalFooter={hasTotalFooter}>
          {table.getFooterGroups().map((footerGroup) => (
            <TR key={footerGroup.id}>
              {footerGroup.headers.map((header) => {
                const colDef: any = header.column.columnDef
                const THprops = {
                  columnAlign:
                    'columnAlign' in colDef ? colDef.columnAlign : 'center',
                  bold: true,
                  sortable
                }
                return (
                  <TD
                    key={header.id}
                    {...THprops}
                    style={{ width: header.getSize() }}
                  >
                    {!header.isPlaceholder &&
                      flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                  </TD>
                )
              })}
            </TR>
          ))}
        </Tfoot>
      )}
    </TableWrapper>
  )
}

export { SimpleTable }
