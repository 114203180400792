import styled from 'styled-components'

export const RowGrid = styled.div<{
  disableMarginBottom: boolean
  justify: string
  align: string
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 -1rem 2rem;
  margin-bottom: ${({ disableMarginBottom }) => disableMarginBottom && 0};
  min-width: 100%;
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
`
