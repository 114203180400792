import styled from 'styled-components'
import { ReactComponent as ErrorIcon } from 'assets/icons/close-circle.svg'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
`

export const Label = styled.label`
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 1.6rem;
  line-height: 1.4;
`

export const Input = styled.input<{
  icon?: string
  look?: 'gray' | 'small' | 'full'
  error?: boolean
}>`
  height: ${({ look }) => (look === 'gray' ? '4rem' : '3.4rem')};
  color: ${({ look, theme }) => look === 'gray' && theme.colors.gray};
  font-size: ${({ look }) => look === 'gray' && '1.6rem'};
  width: ${({ look }) =>
    look === 'small' ? '100px' : look === 'full' ? '100%' : '28rem'};
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 0.6rem 0.6rem 0.6rem 1.2rem;
  padding-left: ${({ look }) => look === 'gray' && '2.4rem'};
  padding-right: ${({ look }) => look === 'gray' && '2.4rem'};
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  background-position: right 0.6rem center;
  background-color: ${({ look, theme }) =>
    look === 'gray' && theme.colors.bgPage};
  border-color: ${({ error, theme }) => error && theme.colors.red};
  background-color: ${({ error }) => error && 'rgba(220, 53, 69, 0.04)'};
  &::placeholder {
    opacity: 0.5;
  }
`

export const InputError = styled.span`
  padding: 0.8rem;
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  bottom: -3.2rem;
  left: 0;
  display: flex;
  align-items: center;
  gap: 0.6rem;
`

export const InputErrorIcon = styled(ErrorIcon)`
  width: 1.8rem;
`
