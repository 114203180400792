import styled from 'styled-components'

export const InfoCardsList = styled.div`
  display: flex;
  margin-bottom: 36px;

  h2 {
    font-weight: normal;
  }
`

export const InfoCardsItem = styled.div`
  padding: 16px;
  gap: 24px;
  width: 210px;
  height: 86px;
  background-color: ${({ theme }) => theme.colors.primaryEvent};
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-right: 36px;
`
