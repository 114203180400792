/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from 'components/Card'
import { Col } from 'components/Grid/Col'
import { Row } from 'components/Grid/Row'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'components/ErrorFallback'
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Tooltip,
  ReferenceArea,
  Area
} from 'recharts'
import { BrandContext } from 'contexts/BrandProvider'
import { useContext } from 'react'
import { useMostRecentDate } from 'views/Pricing/views/DemandForecast/hooks/useDemandForecast'
import { Button } from 'components/Button'
import { PRICING, DEMAND_FORECAST } from 'constants/paths'
import { useNavigate, useParams } from 'react-router-dom'

const MultiInputChart = ({ data }: any) => {
  const { brand } = useContext(BrandContext)
  const { modelo } = useParams()
  const navigate = useNavigate()

  const mostRecentDate = useMostRecentDate(data)

  const Shape = (props: any) => {
    const { x: oX, y: oY, width: oWidth, height: oHeight, fill } = props

    let x = oX
    let y = oHeight < 0 ? oY + oHeight : oY
    let width = oWidth
    let height = Math.abs(oHeight)

    return (
      <rect
        fill={fill}
        mask="url(#mask-stripe)"
        x={x}
        y={y}
        width={width}
        height={height}
      />
    )
  }

  const dateLessMonths = (date, remainingMonths: number) => {
    let { month, year } = date
    month -= remainingMonths

    while (month < 1) {
      month += 12
      year -= 1
    }

    return { month, year }
  }

  return (
    <Row>
      <Col>
        <Card
          headerTitle="Demand Forecast"
          minHeightBody={43.6}
          subtitle={
            data.some((item) => item.cars_in_stock_points)
              ? 'Line chart with the 5 more relevant variables for the demand forecast '
              : 'Line chart with the demand forecast (orders)'
          }
          tooltip={
            <p>
              Displays 5 more relevant variables for calculate demand forecast
              prediction and also demand forecast line too
            </p>
          }
          headerComponent={
            modelo ? (
              <div style={{ marginLeft: 'auto', marginRight: '20px' }}>
                <Button
                  onClick={() =>
                    navigate('/' + PRICING + '/' + DEMAND_FORECAST, {
                      state: { model: modelo }
                    })
                  }
                  label="See more in Demand Forecast →"
                  look="filled"
                />
              </div>
            ) : (
              ''
            )
          }
        >
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                data={data}
                margin={{ top: 20, right: 40, bottom: 40, left: 40 }}
              >
                <CartesianGrid
                  stroke="#DEDEDE"
                  strokeDasharray="3 3"
                  vertical={false}
                />
                {data.some((item) =>
                  Object.prototype.hasOwnProperty.call(item, 'confs_points')
                ) && (
                  <YAxis
                    yAxisId={0}
                    tickCount={8}
                    type="number"
                    width={40}
                    domain={([dataMin, dataMax]) => {
                      return [
                        Math.round((dataMin - 50) * 100) / 100,
                        Math.round(dataMax * 100) / 100
                      ]
                    }}
                  />
                )}
                <YAxis
                  yAxisId={1}
                  type="number"
                  tickCount={8}
                  orientation={
                    data.some((item) =>
                      Object.prototype.hasOwnProperty.call(item, 'confs_points')
                    )
                      ? 'right'
                      : 'left'
                  }
                  width={40}
                  allowDecimals={false}
                  domain={([dataMin, dataMax]) => {
                    return [
                      Math.round(dataMin * 100) / 100 - 20,
                      Math.round(dataMax * 100) / 100 + 20
                    ]
                  }}
                  label={
                    data.some((item) =>
                      Object.prototype.hasOwnProperty.call(item, 'confs_points')
                    )
                      ? {
                          value: 'Orders (demand)',
                          position: 'right',
                          angle: -90
                        }
                      : {
                          value: 'Orders (demand)',
                          position: 'left',
                          angle: -90
                        }
                  }
                />
                <XAxis
                  dataKey={(x) => `${x.month}/${x.year}`}
                  label={{
                    value: 'Period range',
                    position: 'bottom'
                  }}
                  interval="preserveEnd"
                />
                <Tooltip
                  contentStyle={{
                    borderColor: '#f5f5f5',
                    padding: '0.8rem 1.6rem',
                    borderRadius: '10px',
                    background: '#f5f5f5',
                    fontSize: ' 16px'
                  }}
                  itemStyle={{
                    marginTop: '4px'
                  }}
                />
                {
                  <Area
                    type="monotone"
                    dataKey="interval"
                    fill="#DEDEDE"
                    stroke="none"
                    activeDot={false}
                    yAxisId={1}
                  />
                }
                <Legend
                  verticalAlign="bottom"
                  align="center"
                  wrapperStyle={{ left: 50, bottom: 10 }}
                  iconType="plainline"
                />
                <pattern
                  id="pattern-stripe"
                  width="8"
                  height="8"
                  patternUnits="userSpaceOnUse"
                  patternTransform="rotate(45)"
                >
                  <rect
                    width="2"
                    height="8"
                    transform="translate(0,0)"
                    fill="white"
                  ></rect>
                </pattern>
                <mask id="mask-stripe">
                  <rect
                    x="0"
                    y="0"
                    width="100%"
                    height="100%"
                    fill="url(#pattern-stripe)"
                  />
                </mask>
                {data.some((item) =>
                  Object.prototype.hasOwnProperty.call(
                    item,
                    'cars_in_stock_points'
                  )
                ) && (
                  <Line
                    type="monotone"
                    dataKey="cars_in_stock_points"
                    stroke={brand === 'seat' ? '#F96C64' : '#95572B'}
                    dot={false}
                    strokeWidth="1"
                    yAxisId={0}
                    name="Cars in stock"
                  />
                )}

                {data.some((item) =>
                  Object.prototype.hasOwnProperty.call(item, 'confs_points')
                ) && (
                  <Line
                    type="monotone"
                    dataKey="confs_points"
                    stroke="#003E51"
                    dot={false}
                    strokeWidth="1"
                    yAxisId={0}
                    name="Confs"
                  />
                )}
                {data.some((item) =>
                  Object.prototype.hasOwnProperty.call(
                    item,
                    'corrected_discount_points'
                  )
                ) && (
                  <Line
                    type="monotone"
                    dataKey="corrected_discount_points"
                    stroke="#B0A097"
                    dot={false}
                    strokeWidth="1"
                    yAxisId={0}
                    name="Corrected Discount"
                  />
                )}

                {data.some((item) =>
                  Object.prototype.hasOwnProperty.call(
                    item,
                    'total_budget_points'
                  )
                ) && (
                  <Line
                    type="monotone"
                    dataKey="total_budget_points"
                    stroke="#018A7F"
                    dot={false}
                    strokeWidth="1"
                    yAxisId={0}
                    name="Total mkt budget"
                  />
                )}
                {data.some((item) =>
                  Object.prototype.hasOwnProperty.call(
                    item,
                    'traffic_shw_points'
                  )
                ) && (
                  <Line
                    type="monotone"
                    dataKey="traffic_shw_points"
                    stroke="#DC3545"
                    dot={false}
                    strokeWidth="1"
                    yAxisId={0}
                    name="Traffic show"
                  />
                )}
                {data.some((item) =>
                  Object.prototype.hasOwnProperty.call(item, 'orders_points')
                ) && (
                  <Line
                    type="monotone"
                    dataKey="orders_points"
                    stroke="#000000"
                    dot={false}
                    strokeWidth="3"
                    name="Orders (Demand Forecast)"
                    yAxisId={1}
                  />
                )}
                <ReferenceArea
                  fill={'#EBECED'}
                  shape={<Shape />}
                  x1={`${dateLessMonths(mostRecentDate, 4).month}/${
                    dateLessMonths(mostRecentDate, 4).year
                  }`}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </ErrorBoundary>
        </Card>
      </Col>
    </Row>
  )
}

export { MultiInputChart }
