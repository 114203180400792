import styled from 'styled-components'

export const PageLayout = styled.div`
  font-family: ${({ theme }) => theme.font};
  background-color: ${({ theme }) => theme.colors.bgPage};
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  button,
  input {
    font-family: ${({ theme }) => theme.font};
  }
  *:focus {
    outline: none;
  }
`

export const ContentLayout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 -1rem 2rem;
  max-width: 100%;
`
