const transformData = (val: number) => {
  return Math.round(val).toString().replace('.', ',')
}

export const CustomTooltip = (props) => {
  const { payload } = props

  if (payload[0]) {
    return (
      <div
        style={{
          borderColor: '#343A40',
          padding: '16px',
          borderRadius: '10px',
          background: '#343A40',
          fontSize: ' 14px',
          color: '#fff',
          minWidth: '260px'
        }}
      >
        <h2
          style={{
            fontSize: ' 16px',
            lineHeight: '20px'
          }}
        >
          {payload[0].payload.model} {payload[0].payload.fuel} (
          {payload[0].payload.brand})
        </h2>
        <span>{payload[0].payload.title}</span>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '12px'
          }}
        >
          <div>
            {payload[0].payload.transactional_price && (
              <div>
                <p style={{ marginBottom: '0' }}>Transactional Price</p>
                <h3
                  style={{
                    fontSize: ' 24px',
                    lineHeight: '36px',
                    fontWeight: 'normal'
                  }}
                >
                  {transformData(payload[0].payload.transactional_price)}€
                </h3>
              </div>
            )}
            {payload[0].payload.adjustments && (
              <div>
                <p style={{ marginBottom: '0' }}>Adjustement</p>
                <h3
                  style={{
                    fontSize: ' 24px',
                    lineHeight: '36px',
                    fontWeight: 'normal'
                  }}
                >
                  {transformData(payload[0].payload.adjustments)}
                </h3>
              </div>
            )}
          </div>

          {payload[0].payload.price_pvp_mean && (
            <div>
              <p style={{ marginBottom: '0' }}>List Price</p>
              <h3
                style={{
                  fontSize: ' 24px',
                  lineHeight: '36px',
                  fontWeight: 'normal'
                }}
              >
                {transformData(payload[0].payload.price_pvp_mean)}€
              </h3>
            </div>
          )}

          <div>
            <p style={{ marginBottom: '0' }}>Registrations</p>
            <h3
              style={{
                fontSize: ' 24px',
                lineHeight: '36px',
                fontWeight: 'normal'
              }}
            >
              {payload[0].payload.volume}
            </h3>
          </div>
        </div>
      </div>
    )
  }
  return null
}
