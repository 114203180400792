import './translations/i18n'
import './assets/styles/fonts.css'
import { BrandProvider } from 'contexts/BrandProvider'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from 'contexts/AuthProvider'
import { CookiesProvider } from 'react-cookie'

const Providers = ({ children }) => {
  return (
    <CookiesProvider>
      <BrowserRouter>
        <AuthProvider>
          <BrandProvider>{children}</BrandProvider>
        </AuthProvider>
      </BrowserRouter>
    </CookiesProvider>
  )
}

export { Providers }
