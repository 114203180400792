import { useTranslation } from 'react-i18next'
import {
  ChevronleftIcon,
  ChevronrightIcon,
  PaginationButton,
  PaginationHandler,
  PaginationInfo,
  PaginationWrapper
} from './PaginationWidget.style'
import { PaginationProps } from './PaginationWidget.types'

const PaginationWidget = (props: PaginationProps) => {
  const {
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    pageIndex,
    pageSize,
    totalItems,
    stickyFooter
  } = props
  const { t } = useTranslation()
  const paginationToAttempt = pageSize * (pageIndex + 1)

  const paginationController = {
    totalItems,
    pages: {
      prev2: pageCount > 2 && pageIndex + 1 === pageCount && pageCount - 3,
      prev: canPreviousPage && pageIndex - 1,
      curr: pageIndex,
      next: canNextPage && pageIndex + 1,
      next2: pageCount > 2 && pageIndex === 0 && 2
    },
    items: {
      from: pageIndex * pageSize + 1,
      to: paginationToAttempt > totalItems ? totalItems : paginationToAttempt
    }
  }
  return (
    <PaginationWrapper
      data-testid="paginated-widget-component"
      {...(stickyFooter && {
        style: { paddingTop: '60px', paddingBottom: '32px' }
      })}
    >
      <PaginationInfo>
        {t('utils.showing')} {paginationController.items.from}-
        {paginationController.items.to} {t('utils.of')}{' '}
        {paginationController.totalItems} {t('utils.items')}
      </PaginationInfo>
      {pageSize <= totalItems && (
        <PaginationHandler>
          <PaginationButton
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <ChevronleftIcon />
          </PaginationButton>
          {paginationController.pages.prev2 !== false && (
            <PaginationButton
              onClick={() => gotoPage(paginationController.pages.prev2)}
            >
              {paginationController.pages.prev2 + 1}
            </PaginationButton>
          )}
          {canPreviousPage && (
            <PaginationButton
              onClick={() => gotoPage(paginationController.pages.prev)}
            >
              {+paginationController.pages.prev + 1}
            </PaginationButton>
          )}
          <PaginationButton
            onClick={() => gotoPage(paginationController.pages.curr)}
            active={true}
          >
            {paginationController.pages.curr + 1}
          </PaginationButton>
          {canNextPage && (
            <PaginationButton
              onClick={() => gotoPage(paginationController.pages.next)}
            >
              {+paginationController.pages.next + 1}
            </PaginationButton>
          )}
          {paginationController.pages.next2 && (
            <PaginationButton
              onClick={() => gotoPage(paginationController.pages.next2)}
            >
              {paginationController.pages.next2 + 1}
            </PaginationButton>
          )}
          <PaginationButton onClick={() => nextPage()} disabled={!canNextPage}>
            <ChevronrightIcon />
          </PaginationButton>
        </PaginationHandler>
      )}
    </PaginationWrapper>
  )
}

export { PaginationWidget }
