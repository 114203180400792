import React from 'react'
import { RadioItem, RadioLabel } from './Radio.style'
import { RadioProps } from './Radio.types'

const Radio: React.FC<RadioProps> = ({
  name,
  label,
  value,
  align = 'left',
  isChecked,
  handleChange
}) => {
  const handleRadioChange = (e: any) => {
    const { id } = e.currentTarget
    handleChange(id)
  }

  return (
    <RadioItem>
      <input
        type="radio"
        name={name}
        id={value}
        value={value}
        checked={isChecked}
        onChange={handleRadioChange}
      />
      <RadioLabel htmlFor={value} align={align}>
        {label}
      </RadioLabel>
    </RadioItem>
  )
}

export { Radio }
