import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'

import IbizaImg from '../assets/ibiza.png'
import AronaImg from '../assets/arona.png'
import Leon5dImg from '../assets/leon-5d.png'
import LeonStImg from '../assets/leon-st.png'
import AtecaImg from '../assets/ateca.png'
import TarracoImg from '../assets/tarraco.png'

import { Slide } from './Slide'

const Carousel = () => {
  return (
    <>
      <Swiper
        slidesPerView={4}
        centeredSlides={false}
        spaceBetween={20}
        grabCursor={true}
        navigation={true}
        modules={[Navigation]}
        style={{ width: '100%', height: '100%' }}
      >
        <SwiperSlide>
          <Slide model="ibiza" modelImg={IbizaImg} />
        </SwiperSlide>
        <SwiperSlide>
          <Slide model="arona" modelImg={AronaImg} />
        </SwiperSlide>
        <SwiperSlide>
          <Slide model="leon" modelVariant="leon-5d" modelImg={Leon5dImg} />
        </SwiperSlide>
        <SwiperSlide>
          <Slide model="leon" modelVariant="leon-st" modelImg={LeonStImg} />
        </SwiperSlide>
        <SwiperSlide>
          <Slide model="ateca" modelImg={AtecaImg} />
        </SwiperSlide>
        <SwiperSlide>
          <Slide model="tarraco" modelImg={TarracoImg} />
        </SwiperSlide>
      </Swiper>
    </>
  )
}

export { Carousel }
