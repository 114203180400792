/* eslint-disable react-hooks/exhaustive-deps */
import { Filters } from 'components/Filters'
import { Datepicker } from 'components/Inputs/Datepicker'
import { Select } from 'components/Inputs/Select'
import {
  useRef,
  MutableRefObject,
  useCallback,
  useState,
  useEffect,
  useContext
} from 'react'
import { useInputForecastStore } from 'state'
import { arrayObjectsAreSame, getDateForNextMonths } from 'utils'
import { shallow } from 'zustand/shallow'
import { BrandContext } from 'contexts/BrandProvider'
import { toQueryParams } from 'api/utils'
import { useTokenUuid } from 'hooks/request'
import {
  getInputsFilterList,
  getMTFsFilterList,
  getModelsFilterList
} from 'api/pricing/inputForecast'

const PageFilters = ({ maximunDate, selectedModel }) => {
  const maxDate = maximunDate ? maximunDate : getDateForNextMonths(1)
  const modelRef: MutableRefObject<any> = useRef('model')
  const mtfRef: MutableRefObject<any> = useRef('mtf')
  const periodStartRef: MutableRefObject<any> = useRef('periodStart')
  const periodEndRef: MutableRefObject<any> = useRef('periodEnd')
  const objectiveRef: MutableRefObject<any> = useRef('objective')

  const [modelList, setModelList] = useState<any[]>([])
  const [mtfList, setMtfList] = useState<any[]>([])

  const [inputsObj, setInputsObj] = useState<any[]>([])

  const { brand } = useContext(BrandContext)

  const uuid = useTokenUuid()

  const {
    model,
    modelVariant,
    periodStart,
    periodEnd,
    objective,
    updateFilter,
    resetFilter
  } = useInputForecastStore(
    (state: any) => ({
      model: state.inputForecastModel,
      modelVariant: state.inputForecastModelVariant,
      periodStart: state.inputForecastPeriodStart,
      periodEnd: state.inputForecastPeriodEnd,
      objective: state.inputForecastObjective,
      updateFilter: state.updateFilter,
      resetFilter: state.resetFilter
    }),
    shallow
  )

  const [valueModel, setValueModel] = useState(model)
  const [valueModelVariant, setValueModelVariant] = useState(modelVariant)
  const [valuePeriodStart, setValuePeriodStart] = useState(periodStart)
  const [valuePeriodEnd, setValuePeriodEnd] = useState(periodEnd)
  const [valueObjective, setValueObjective] = useState(objective)

  const updateStateFilter = useCallback((key: string, value: string) => {
    switch (key) {
      case 'model':
        setValueModel(value)
        break
      case 'modelVariant':
        setValueModelVariant(value)
        break
      case 'periodStart':
        setValuePeriodStart(value)
        break
      case 'periodEnd':
        setValuePeriodEnd(value)
        break
      case 'objective':
        setValueObjective(value)
        break
    }
  }, [])

  const applyAll = useCallback(() => {
    updateFilter('inputForecastModel', valueModel)
    updateFilter('inputForecastPeriodStart', valuePeriodStart)
    updateFilter('inputForecastPeriodEnd', valuePeriodEnd)
    updateFilter('inputForecastObjective', valueObjective)
    updateFilter('inputForecastModelVariant', valueModelVariant)
  }, [
    updateFilter,
    valueModel,
    valueModelVariant,
    valueObjective,
    valuePeriodStart,
    valuePeriodEnd
  ])

  const clearAll = useCallback(() => {
    modelRef.current.clearValue()
    mtfRef.current.clearValue()
    periodStartRef.current.clear()
    periodEndRef.current.clear()
    objectiveRef.current.clearValue()
    resetFilter('inputForecastModel')
    resetFilter('inputForecastModelVariant')
    resetFilter('inputForecastPeriodStart')
    resetFilter('inputForecastPeriodEnd')
    resetFilter('inputForecastObjective')
  }, [resetFilter])

  const enableApplyAll = useCallback(() => {
    return (
      valueModel &&
      valuePeriodStart &&
      valuePeriodEnd &&
      valueObjective &&
      valueModelVariant &&
      !(
        periodStart === valuePeriodStart &&
        periodEnd === valuePeriodEnd &&
        arrayObjectsAreSame(model, valueModel) &&
        arrayObjectsAreSame(objective, valueObjective) &&
        arrayObjectsAreSame(modelVariant, valueModelVariant)
      )
    )
  }, [
    model,
    modelVariant,
    objective,
    periodStart,
    periodEnd,
    valueModel,
    valueObjective,
    valuePeriodStart,
    valuePeriodEnd,
    valueModelVariant
  ])

  const enableClearAll = useCallback(() => {
    return model?.model || periodStart || periodEnd || objective?.value
  }, [model, objective, periodStart, periodEnd])

  const totalFilters = useCallback(() => {
    const hasModel = +!!model?.model
    const hasPeriod = +!!periodStart && +!!periodEnd
    const hasObjective = +!!objective?.value
    return hasModel + hasPeriod + hasObjective
  }, [model?.model, objective?.value, periodStart, periodEnd])

  let queryParams = toQueryParams({
    brand
  })

  const fetchModelFiltersList = async () => {
    const resp: any = await getModelsFilterList(uuid, queryParams)
    if (resp !== 404 && resp !== 204 && resp !== 500) {
      const formattedList = resp.filter_list.map((model: any) => ({
        value: model.value,
        label: model.label
      }))
      setModelList(formattedList)

      if (selectedModel) {
        const matchedModel = formattedList.find(
          (model) => model.value === selectedModel
        )

        setValueModel(
          matchedModel || {
            value: selectedModel,
            label:
              selectedModel.charAt(0).toUpperCase() +
              selectedModel.slice(1).toLowerCase()
          }
        )
      }
    }
  }

  const fetchMTFsFiltersList = async () => {
    queryParams = `model=${valueModel?.label}&${queryParams}`
    const resp: any = await getMTFsFilterList(uuid, queryParams)
    if (resp !== 404 && resp !== 204) {
      setMtfList(resp.filter_list)
    }
  }

  const fetchInputFiltersList = async () => {
    const resp: any = await getInputsFilterList(uuid, queryParams)
    if (resp !== 404 && resp !== 204) {
      setInputsObj(resp.filter_list)
    }
  }

  useEffect(() => {
    uuid && fetchModelFiltersList()
    uuid && fetchInputFiltersList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, uuid])

  useEffect(() => {
    uuid && fetchMTFsFiltersList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, uuid, valueModel])

  useEffect(() => {
    clearAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand])

  useEffect(() => {
    setValueModelVariant('')
    mtfRef.current.clearValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueModel])

  useEffect(() => {
    if (selectedModel) {
      setValueModel(selectedModel)
    }
  }, [selectedModel])

  return (
    <Filters
      applyAll={applyAll}
      clearAll={clearAll}
      enableApplyAll={enableApplyAll()}
      enableClearAll={enableClearAll()}
      totalFilters={totalFilters()}
    >
      <Select
        options={modelList}
        emptyPlaceholder="Model"
        reference={modelRef}
        value={valueModel}
        onUpdateValue={updateStateFilter}
        id="model"
      />
      <Select
        options={mtfList}
        emptyPlaceholder="MTF"
        onUpdateValue={updateStateFilter}
        reference={mtfRef}
        defaultValue={modelVariant}
        id="modelVariant"
        isDisabled={valueModel === null}
      />
      <Select
        options={inputsObj}
        emptyPlaceholder="Input objective"
        reference={objectiveRef}
        defaultValue={objective}
        onUpdateValue={updateStateFilter}
        id="objective"
      />
      <Datepicker
        maxDate={maxDate}
        emptyPlaceholder="Start period range"
        reference={periodStartRef}
        defaultValue={periodStart}
        onUpdateValue={updateStateFilter}
        fixedWidth="210px"
        id="periodStart"
      />
      <Datepicker
        maxDate={maxDate}
        emptyPlaceholder="End period range"
        reference={periodEndRef}
        defaultValue={periodEnd}
        onUpdateValue={updateStateFilter}
        fixedWidth="210px"
        id="periodEnd"
      />
    </Filters>
  )
}

export { PageFilters }
