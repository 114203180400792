const baseUrl = process.env.REACT_APP_API_URL_V2

export const api = {
  get: async (
    url: string,
    queryParams: string,
    tokenId: string,
    backendApiVersion: number
  ) => {
    if (tokenId) {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenId}`,
          'Content-Type': 'application/json',
          'Api-Version': `${backendApiVersion}`
        }
      }
      try {
        const resp = await fetch(`${baseUrl}${url}?${queryParams}`, config)
        if (resp.ok && resp.status !== 204) {
          return resp.json()
        }
        return resp.status
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }
  },

  getBlob: async (url: string, queryParams: string, tokenId: string) => {
    if (tokenId) {
      const config = {
        responseType: 'blob',
        headers: { Authorization: `Bearer ${tokenId}` }
      }
      try {
        const resp = await fetch(`${baseUrl}${url}?${queryParams}`, config)
        if (resp.ok) {
          return resp.blob()
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }
  },

  delete: async (url: string, tokenId: string) => {
    if (tokenId) {
      const config = {
        headers: { Authorization: `Bearer ${tokenId}` },
        method: 'DELETE'
      }
      try {
        await fetch(`${baseUrl}${url}`, config)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }
  },

  post: async (
    url: string,
    queryParams: string,
    tokenId: string,
    backendApiVersion: number,
    body?: object
  ) => {
    if (tokenId) {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenId}`,
          'Content-Type': 'application/json',
          'Api-Version': `${backendApiVersion}`
        },
        method: 'POST',
        body: JSON.stringify(body)
      }
      try {
        const resp = await fetch(`${baseUrl}${url}?${queryParams}`, config)
        if (resp.ok) {
          return resp.json()
        }
        return resp.status
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }
  },

  put: async (
    url: string,
    queryParams: string,
    tokenId: string,
    body: object
  ) => {
    if (tokenId) {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenId}`,
          'Content-Type': 'application/json'
        },
        method: 'PUT',
        body: JSON.stringify(body)
      }
      try {
        const resp = await fetch(`${baseUrl}${url}?${queryParams}`, config)
        if (resp.ok) {
          return resp.json()
        }
        return resp.status
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }
  }
}
