import styled from 'styled-components'
import { FeedbackProps } from './NoData.types'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.6rem;
  height: 100%;
  min-height: calc(100vh - 180px);
  background-repeat: no-repeat;
  background-position: bottom right;
`

export const Title = styled.h1`
  margin-bottom: 16px;
  font-weight: 400;
`

export const Subtitle = styled.p`
  margin-bottom: 32px;
  max-width: 400px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`

export const NoDataMessage = styled.span<FeedbackProps>`
  color: ${({ theme, feed }) => (feed === '500' ? 'red' : theme.colors.gray)};
  text-align: center;
  display: block;
  line-height: 1.4;
  font-size: ${({ feed }) => (feed === '500' ? '2rem' : '1.3rem')};
  max-width: 24.8rem;
`
