import { create } from 'zustand'
import zustymiddleware from 'zustymiddleware'

interface FilterValue {
  label: 'string'
  value: number | string
}

export interface PocketFilters {
  inputForecastModel?: null | FilterValue[]
  inputForecastModelVariant?: null | FilterValue[]
  inputForecastPeriodStart?: null | string
  inputForecastFuel?: null | FilterValue[]
}
const pocketFilters: PocketFilters = {
  inputForecastModel: null,
  inputForecastModelVariant: null,
  inputForecastPeriodStart: null,
  inputForecastFuel: null
}

export const usePocketStore = create(
  zustymiddleware((set) => ({
    ...pocketFilters,
    updateFilter: (filterName: string, value: number[] | string[]) => {
      if (typeof value === 'object') {
        set(() => ({ [filterName]: value }))
      }
    },
    resetFilter: (filterName: string) => {
      set(() => ({ [filterName]: pocketFilters[filterName] }))
    },
    setCustomObjetive: (value: string | number) =>
      set(() => ({ customFuel: value }))
  }))
)

window.store = usePocketStore
