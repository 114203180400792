/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'components/Button'
import {
  FeedbackWrapper,
  FeedbackIconList,
  FeedbackIcon,
  FeedbackCaption,
  TextArea,
  ButtonWrapper,
  CloseFeedback,
  FloatingButton,
  FeedbackIconSuccessful
} from './Feedback.style'
import { useContext, useEffect, useState } from 'react'
import { Radio } from 'components/Radio'
import { ReactComponent as FeedbackFloatingIcon } from 'assets/icons/feedback.svg'
import { useTokenUuid } from 'hooks/request'
import { postUserFeedback } from 'api/feedback'
import { toQueryParams } from 'api/utils'
import { AuthContext } from 'contexts/AuthProvider'
import { useLocation } from 'react-router-dom'
import { BrandContext } from 'contexts/BrandProvider'

import { useCookies } from 'react-cookie'

const NOTION_ID = '24308d7dec094a61afa1bad046b168ac'

const Feedback = () => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [send, setIsSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [feedbackValue, setFeedbackValue] = useState('')
  const [feedbackMessage, setFeedbackMessage] = useState('')
  const [userName, setUserName] = useState<string>('')
  const { pathname } = useLocation()
  const { brand } = useContext(BrandContext)

  const [cookies, setCookie] = useCookies(['feedbackTooltipCollapsed'])

  const uuid = useTokenUuid()

  const auth = useContext(AuthContext)

  const today = new Date()

  const handleCollapsed = () => {
    setIsCollapsed(!isCollapsed)
    setCookie('feedbackTooltipCollapsed', true)
  }

  const handleChange = (value: string) => setFeedbackValue(value)

  const handleMessage = (msg: any) => setFeedbackMessage(msg.target.value)

  const submitFeedback = async () => {
    setLoading(true)
    if (auth && 'user' in auth) {
      const body = {
        database_id: NOTION_ID,
        feedback: feedbackValue,
        user: `${userName}(${brand})`,
        details: `${today}: ${feedbackMessage}`,
        source: pathname
      }
      const res: any = await postUserFeedback(uuid, body)
      if (res !== 500) setIsSent(true)
      setLoading(false)
      setTimeout(() => {
        setIsCollapsed(true)
        setIsSent(false)
        setFeedbackValue('')
        setFeedbackMessage('')
      }, 3000)
    }
  }

  useEffect(() => {
    if (cookies.feedbackTooltipCollapsed) setIsCollapsed(true)
    if (auth && 'user' in auth) {
      const { user } = auth
      if (user && 'name' in user) {
        const { name } = user
        setUserName(name)
      }
    }
  }, [auth, cookies])

  return pathname === '/' ? null : (
    <>
      {isCollapsed && (
        <FloatingButton onClick={handleCollapsed}>
          <FeedbackFloatingIcon style={{ width: '20px', height: '20px' }} />
        </FloatingButton>
      )}
      {!isCollapsed && !send && !loading && (
        <FeedbackWrapper>
          <CloseFeedback>
            <Button label="X" onClick={handleCollapsed} />
          </CloseFeedback>
          <h3>How are you feeling?</h3>
          <FeedbackCaption>
            Your input is valuable in helping us better undertand your needs
          </FeedbackCaption>
          <FeedbackIconList>
            <FeedbackIcon>
              <Radio
                name="option"
                value="low"
                label="&#129397;"
                handleChange={handleChange}
              />
            </FeedbackIcon>
            <FeedbackIcon>
              <Radio
                name="option"
                value="mid-low"
                label="&#128532;"
                handleChange={handleChange}
              />
            </FeedbackIcon>
            <FeedbackIcon>
              <Radio
                name="option"
                value="medium"
                label="&#128529;"
                handleChange={handleChange}
              />
            </FeedbackIcon>
            <FeedbackIcon>
              <Radio
                name="option"
                value="high"
                label="&#128578;"
                handleChange={handleChange}
              />
            </FeedbackIcon>
            <FeedbackIcon>
              <Radio
                name="option"
                value="super-high"
                label="&#129392;"
                handleChange={handleChange}
              />
            </FeedbackIcon>
          </FeedbackIconList>
          <TextArea
            placeholder="Add a comment..."
            onChange={handleMessage}
          ></TextArea>
          <ButtonWrapper>
            <Button label="Submit Now" onClick={submitFeedback} />
          </ButtonWrapper>
        </FeedbackWrapper>
      )}

      {loading && (
        <FeedbackWrapper>
          <h3>Sending...</h3>
        </FeedbackWrapper>
      )}

      {send && (
        <FeedbackWrapper>
          <CloseFeedback>
            <Button label="X" onClick={handleCollapsed} />
          </CloseFeedback>
          <h3>Thanks</h3>
          <FeedbackIconSuccessful>&#129392;</FeedbackIconSuccessful>
          <FeedbackCaption>
            Thank you for your feedback, it helps us a lot to improve the tool
          </FeedbackCaption>
        </FeedbackWrapper>
      )}
    </>
  )
}

export { Feedback }
