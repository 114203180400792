/* eslint-disable @typescript-eslint/no-unused-vars */
import { Title } from 'components/Title'
import { useTranslation } from 'react-i18next'
import { Row } from 'components/Grid/Row'
import { Col } from 'components/Grid/Col'
import { PageFilters } from './PageFilters'
import { useContext, useEffect, useState } from 'react'
import { useTokenUuid } from 'hooks/request'
import { toQueryParams } from 'api/utils'
import {
  getDensityHistogramData,
  getTrendVariable,
  getInputDataVariable,
  getTrendVariableByModel,
  getDensityHistogramDataByModel,
  getLastAvailableData
} from 'api/pricing/inputForecast'
import { useInputForecastParams } from './hooks/useInputForecastParams'
import { BrandContext } from 'contexts/BrandProvider'
import { NoData } from 'components/NoData'
import { DensityHistogram } from './components/DensityHistogramChart'
import { TrendInput } from './components/TrendInputChart'
import { MultiInputChart } from './components/MultiInputChart'
import { Spinner } from 'components/Spinner'
import { ReactComponent as NoResults } from 'assets/icons/noResults.svg'
import { useLocation } from 'react-router-dom'

const DataForecastInsights = ({ mtf }) => {
  const { t } = useTranslation()

  const [trendVariableData, setTrendVariableData] = useState<any>()
  const [densityHistogramData, setDensityHistogramData] = useState<any>()
  const [inputDataVariable, setInputDataVariable] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [filterByModel, setFilterByModel] = useState<boolean>(false)
  const [dataEmpty, setDataEmpty] = useState<any>(null)

  const { brand } = useContext(BrandContext)

  const uuid = useTokenUuid()

  const location = useLocation()
  const paramsLocation = location.state || {}
  const { model } = paramsLocation

  const params = useInputForecastParams()

  let queryParams = toQueryParams({
    model: params.model,
    model_variant: params.modelVariant,
    start_month: params.startMonth,
    end_month: params.endMonth,
    start_year: params.startYear,
    end_year: params.endYear,
    trim: params.trim,
    fuel: params.fuel,
    input_data_filter: params.objective
  })

  const fetchDensityData = async () => {
    if (
      typeof params.model !== 'undefined' &&
      typeof params.startMonth !== 'undefined' &&
      typeof params.endMonth !== 'undefined' &&
      typeof params.objective !== 'undefined'
    ) {
      queryParams = `brand=${brand}&${queryParams}`
      setIsLoading(true)
      const resp: any = await getTrendVariable(uuid, queryParams)

      if (resp !== 204) setTrendVariableData(resp)

      const resp_histogram: any = await getDensityHistogramData(
        uuid,
        queryParams
      )
      if (resp_histogram !== 204) setDensityHistogramData(resp_histogram)
      setIsLoading(false)
    }
  }

  const fetchDensityDataByModel = async () => {
    if (
      typeof params.model !== 'undefined' &&
      typeof params.startMonth !== 'undefined' &&
      typeof params.endMonth !== 'undefined' &&
      typeof params.objective !== 'undefined'
    ) {
      queryParams = `brand=${brand}&${queryParams}`
      setIsLoading(true)
      const resp: any = await getTrendVariableByModel(uuid, queryParams)

      if (resp !== 204) setTrendVariableData(resp)

      const resp_histogram: any = await getDensityHistogramDataByModel(
        uuid,
        queryParams
      )
      if (resp_histogram !== 204) setDensityHistogramData(resp_histogram)
      setIsLoading(false)
    }
  }

  const fetchInputDataVariable = async () => {
    const queryParams = toQueryParams({
      brand
    })
    const resp: any = await getInputDataVariable(uuid, queryParams)
    if (resp !== 404 && resp !== 204) {
      if (resp !== 204) setInputDataVariable(resp.filter_list)
    }
  }
  const fetchAvailableData = async () => {
    const simpleQueryParam = toQueryParams({
      brand: brand
    })
    const resp: any = await getLastAvailableData(uuid, simpleQueryParam)
    setDataEmpty(resp)
  }

  useEffect(() => {
    fetchAvailableData()
    if (dataEmpty !== null) {
      setTrendVariableData(undefined)
      setDensityHistogramData(undefined)
      uuid && fetchInputDataVariable()
      if (params.modelVariant.length) uuid && fetchDensityData()
      if (!params.modelVariant.length) {
        uuid && fetchDensityDataByModel()
      }
      setFilterByModel(!params.modelVariant.length)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, uuid])

  if (trendVariableData === 500) {
    return <NoData feed="500" />
  }

  if (trendVariableData === 404) {
    return <NoData feed="404" />
  }

  return (
    <>
      <Title
        label={
          mtf
            ? t('sidebar.pricing.mtfComparative')
            : t('pricing.dataForecastInsights.title')
        }
        data-testid="dashboard-view"
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PageFilters
          maximunDate={dataEmpty?.date ? new Date(dataEmpty?.date) : null}
          selectedModel={model ?? null}
        />
      </div>

      <Row>
        <Col>
          {(densityHistogramData || trendVariableData) && (
            <h2
              style={{
                textTransform: 'capitalize',
                marginTop: '20px',
                marginBottom: '20px'
              }}
            >
              {!filterByModel &&
                `${params.modelVariant ? params.modelVariant : params.model} ${
                  params.trim
                } ${params.fuel}`}
              {filterByModel &&
                `${params.model}
                `}
            </h2>
          )}
        </Col>
      </Row>
      {isLoading && <Spinner />}
      {!mtf && !isLoading && trendVariableData && densityHistogramData && (
        <TrendInput data={trendVariableData} />
      )}

      {!mtf && !isLoading && densityHistogramData && (
        <DensityHistogram data={densityHistogramData} />
      )}

      {mtf && !isLoading && trendVariableData && densityHistogramData && (
        <MultiInputChart
          filterByModel={filterByModel}
          data={trendVariableData}
          inputsDataVariable={inputDataVariable}
        />
      )}
      {!isLoading && !densityHistogramData && !trendVariableData && (
        <div style={{ textAlign: 'center' }}>
          <NoResults />
          <h3 style={{ fontWeight: 400 }}>
            {t('pricing.dataForecastInsights.noResults')}
          </h3>
        </div>
      )}
    </>
  )
}

export { DataForecastInsights }
