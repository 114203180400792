import styled, { css, StyledProps } from 'styled-components'
import { Position } from './Tooltip.types'

const position = (
  props: StyledProps<{
    position: Position
    gap: number
  }>
) => {
  switch (props.position) {
    case 'top':
      return css`
        left: 50%;
        transform: translateX(-50%);
        bottom: ${props.gap}rem;
      `
    case 'right':
      return css`
        top: 50%;
        transform: translateY(-50%);
        left: ${props.gap}rem;
      `
    case 'bottom':
      return css`
        left: 50%;
        transform: translateX(-50%);
        top: ${props.gap}rem;
      `
    case 'left':
      return css`
        top: 50%;
        transform: translateY(-50%);
        right: ${props.gap}rem;
      `
    case 'top-left':
      return css`
        bottom: ${props.gap}rem;
        right: ${props.gap}rem;
      `
    case 'top-right':
      return css`
        bottom: ${props.gap}rem;
        left: ${props.gap}rem;
      `
    case 'bottom-left':
      return css`
        top: ${props.gap}rem;
        right: ${props.gap}rem;
      `
    case 'bottom-right':
      return css`
        top: ${props.gap}rem;
        left: ${props.gap}rem;
      `
  }
}

export const Wrapper = styled.div<{
  size?: number
  position:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right'
  gap: number
}>`
  padding: 0.8rem 1.6rem;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.bgBlack};
  border-radius: 0.4rem;
  width: ${({ size }) => !!size && size + 'rem'};
  text-align: center;
  font-size: 1.3rem;
  position: absolute;
  z-index: 2;
  ${position};
`
