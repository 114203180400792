/* eslint-disable react-hooks/exhaustive-deps */
import {
  Wrapper,
  ReactSelect,
  InputError,
  InputErrorIcon
} from './Select.style'
import { useState } from 'react'
import { components } from 'react-select'
import { useTheme } from 'styled-components'
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevrondown.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import Checkmark from 'assets/icons/checkmark.svg'
import { SelectProps } from './Select.types'
import * as amplitude from '@amplitude/analytics-browser'

const Select = (props: SelectProps) => {
  const {
    emptyPlaceholder = 'Select',
    options,
    isMulti = false,
    onUpdateValue,
    defaultValue,
    id,
    reference,
    fullWidth = false,
    look,
    minWidth = 200,
    customError,
    customComponent,
    value,
    disableMultiSelect,
    isDisabled
  } = props
  const [, setSelectedOption] = useState<any>()
  const theme: any = useTheme()

  const customStyles = {
    control: (styles) => {
      const setBackground = () => {
        if (customError) {
          return 'rgba(220, 53, 69, 0.04)'
        }
        if (look === 'gray') {
          return theme.colors.bgPage
        }
        return theme.colors.white
      }
      const bckg = setBackground()
      return {
        ...styles,
        backgroundColor: bckg,
        borderRadius: 0,
        border: `1px solid ${
          customError ? theme.colors.red : theme.colors.border
        }`,
        boxShadow: 'none',
        cursor: 'pointer',
        alignItems: 'center',
        minHeight: look === 'gray' ? 40 : 34,
        maxHeight: look === 'gray' ? 40 : 34,
        fontSize: look === 'gray' && '1.6rem',
        '&:hover': {
          borderColor: theme.colors.border
        },
        maxWidth: 300
      }
    },
    option: (styles, { isDisabled, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected && theme.colors.white,
      fontWeight: isSelected && '500',
      color: theme.colors.black,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      padding: look === 'gray' ? '1.2rem 2.4rem' : '0.9rem 2rem',
      minWidth: minWidth,
      display: 'flex',
      alignItems: 'center',
      fontSize: look === 'gray' && '1.6rem',
      '&:hover': {
        backgroundColor: theme.colors.primaryEvent
      },
      '&:before': {
        content: isMulti && '" "',
        appearance: 'none',
        width: '0.8em',
        height: '0.8em',
        borderRadius: '0.2em',
        marginRight: '0.8em',
        border: '0.1em solid ' + theme.colors.gray,
        outline: 'none',
        cursor: 'pointer',
        position: 'relative',
        backgroundColor: isSelected ? theme.colors.gray : 'white',
        backgroundSize: '100%',
        backgroundImage: 'url(' + Checkmark + ')',
        backgroundPosition: 'center center'
      }
    }),
    menu: (styles) => ({
      ...styles,
      marginTop: 5,
      borderRadius: 0,
      boxShadow: 0,
      minWidth: minWidth
    }),
    menuList: (styles) => ({
      ...styles,
      padding: 0,
      border: `1px solid ${theme.colors.border}`,
      minWidth: minWidth,
      overflowX: 'hidden'
    }),
    dropdownIndicator: (styles, state) => ({
      ...styles,
      color: theme.colors.primary,
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
      padding: '0 1.1rem',
      zoom: look === 'gray' && '1.15',
      '&:hover': {
        color: theme.colors.primary
      },
      path: {
        fill: state.selectProps.isDisabled
          ? theme.colors.gray
          : theme.colors.primary
      }
    }),
    valueContainer: (styles, { hasValue }) => {
      const calcPaddingLeft = () => {
        if (isMulti && hasValue) {
          return 3
        }
        if (look === 'gray') {
          return 24
        }
        return 12
      }
      const paddingLeft = calcPaddingLeft()
      return {
        ...styles,
        paddingLeft,
        paddingRight: isMulti && 0,
        flexWrap: 'nowrap',
        overflowX: 'auto'
      }
    },
    singleValue: (styles) => ({
      ...styles,
      color: look === 'gray' && theme.colors.gray
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: theme.colors.primaryEvent,
      color: theme.colors.primary,
      fontSize: '1.3rem',
      borderRadius: 0
    }),

    multiValueLabel: (styles) => ({
      ...styles,
      fontSize: '100%',
      color: 'inherit'
    }),
    multiValueRemove: (styles, { isDisabled }) => {
      return {
        ...styles,
        padding: '0 0.7rem',
        display: isDisabled ? 'none' : 'flex',
        '&:hover': {
          color: theme.colors.primary,
          backgroundColor: theme.colors.primaryEvent
        },
        path: {
          fill: theme.colors.primary
        }
      }
    },
    input: (styles) => ({
      ...styles,
      with: 0,
      padding: 0,
      margin: 0,
      caretColor: 'transparent'
    })
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ChevronDownIcon />
      </components.DropdownIndicator>
    )
  }

  const MultiValueRemove = (props) => {
    if (props.data.isFixed) {
      return null
    }
    return (
      <components.MultiValueRemove {...props}>
        <CloseIcon />
      </components.MultiValueRemove>
    )
  }

  const onChange = (event) => {
    amplitude.track(`Update select ${event?.label}`)
    setSelectedOption(event)
    if (onUpdateValue) {
      onUpdateValue(id, event)
    }
  }

  const elements = customComponent
    ? {
        IndicatorSeparator: () => null,
        ClearIndicator: () => null,
        MultiValueRemove,
        DropdownIndicator,
        Option: customComponent
      }
    : {
        IndicatorSeparator: () => null,
        ClearIndicator: () => null,
        MultiValueRemove,
        DropdownIndicator
      }

  return (
    <Wrapper data-testid="input-select" fullWidth={fullWidth}>
      <ReactSelect
        isOptionDisabled={(option: any) => {
          return isMulti
            ? (disableMultiSelect ?? false) || (option.isFixed ?? false)
            : false
        }}
        isClearable={isMulti ? value?.some((v: any) => !v.isFixed) : false}
        ref={reference}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        options={options}
        styles={customStyles}
        placeholder={emptyPlaceholder}
        isMulti={isMulti}
        id={id}
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={false}
        components={elements}
        isDisabled={isDisabled}
      />
      {customError && (
        <InputError>
          <InputErrorIcon />
          {customError}
        </InputError>
      )}
    </Wrapper>
  )
}

export { Select }
