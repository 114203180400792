import { useMemo } from 'react'
import { usePocketStore } from 'state'
import { shallow } from 'zustand/shallow'

export const usePocketParams = () => {
  const { inputForecastModel, inputForecastPeriodStart, inputForecastFuel } =
    usePocketStore(
      (state: any) => ({
        inputForecastModel: state.inputForecastModel,
        inputForecastPeriodStart: state.inputForecastPeriodStart,
        inputForecastPeriodEnd: state.inputForecastPeriodEnd,
        inputForecastFuel: state.inputForecastFuel
      }),
      shallow
    )

  const queryModel = inputForecastModel?.value?.toLowerCase()
  const queryFuel = inputForecastFuel

  let queryStartMonth
  let queryStartYear

  const getDateFormatParam = (input) => {
    if (input !== null) {
      if (typeof input === 'string') {
        queryStartMonth = parseInt(input.split('-')[1])
        queryStartYear = parseInt(input.split('-')[0])
      } else {
        if (input?.getUTCMonth() + 2 > 12) {
          queryStartMonth = 1
          queryStartYear = input.getFullYear()
        }
        if (input?.getUTCMonth() + 2 <= 12) {
          queryStartMonth = input.getUTCMonth() + 2

          queryStartYear = input.getFullYear()
        }
      }
    }
  }

  getDateFormatParam(inputForecastPeriodStart)

  return {
    model: queryModel,
    fuel: queryFuel,
    startMonth: queryStartMonth,
    startYear: queryStartYear
  }
}

export const usePocketDataTable = (data: any) => {
  const columns = useMemo(() => {
    const colTotal = [
      {
        header: 'Model',
        accessorKey: 'title',
        footer: 'Total',
        columnAlign: 'left'
      },
      {
        header: 'Volume',
        accessorKey: 'volume',
        footer: data.volume_total,
        columnAlign: 'left'
      },
      {
        header: '% Total',
        accessorKey: 'percentage_on_total',
        footer: '100%',
        columnAlign: 'left'
      }
    ]
    return [...colTotal]
  }, [data])

  return {
    columns,
    data: data.title_details,
    tableId: 'pocketDataDetail',
    title: data.header
  }
}
