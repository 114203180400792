import { api } from 'api/api'

//FILTERS

export const getLastAvailableData = async (
  uuid: string,
  queryParams: string
) => {
  const response = await api.get(
    '/pocket/last-available-data',
    queryParams,
    uuid,
    2
  )
  return response
}

export const getFilterBasket = async (uuid: string, queryParams: string) => {
  const response = await api.get('/pocket/filter/basket', queryParams, uuid, 2)
  return response
}

export const getFilterFuel = async (uuid: string, queryParams: string) => {
  const response = await api.get('/pocket/filter/fuel', queryParams, uuid, 2)
  return response
}

export const getFilterRegistrationsBrands = async (
  uuid: string,
  queryParams: string
) => {
  const response = await api.get(
    '/pocket/filter/registrations_brands',
    queryParams,
    uuid,
    2
  )
  return response
}

export const getFilterBodyTypes = async (uuid: string, queryParams: string) => {
  const response = await api.get(
    '/pocket/filter/body_type',
    queryParams,
    uuid,
    2
  )
  return response
}

export const getFilterUniversalTrim = async (
  uuid: string,
  queryParams: string
) => {
  const response = await api.get(
    '/pocket/filter/transactional_prices',
    queryParams,
    uuid,
    2
  )
  return response
}

// First graph
export const getPocketAnalysis = async (uuid: string, queryParams: string) => {
  const response = await api.get('/pocket/analysis', queryParams, uuid, 2)
  return response
}

export const getPocketTableInfo = async (uuid: string, queryParams: string) => {
  const response = await api.get('/pocket/title-detail', queryParams, uuid, 2)
  return response
}

// Second graph
export const getPocketCompetitorsRegistrations = async (
  uuid: string,
  queryParams: string
) => {
  const response = await api.get('/pocket/registrations', queryParams, uuid, 2)
  return response
}
