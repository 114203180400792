import styled from 'styled-components'
import Select from 'react-select'
import { ReactComponent as ErrorIcon } from 'assets/icons/close-circle.svg'

export const Wrapper = styled.div<{ fullWidth: boolean }>`
  padding: 0;
  border-radius: 0;
  width: ${({ fullWidth }) => fullWidth && '100%'};
  position: relative;
`

export const ReactSelect = styled(Select)`
  font-weight: normal;
  font-size: 1.4rem;
`

export const InputError = styled.span`
  padding: 0.8rem;
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  bottom: -3.2rem;
  left: 0;
  display: flex;
  align-items: center;
  gap: 0.6rem;
`

export const InputErrorIcon = styled(ErrorIcon)`
  width: 1.8rem;
`
