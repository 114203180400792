import { RowGrid } from './Row.style'
import { RowProps } from './Row.types'

const Row = (props: RowProps) => {
  const {
    children,
    disableMarginBottom = false,
    justify = '',
    align = ''
  } = props
  return (
    <RowGrid
      disableMarginBottom={disableMarginBottom}
      justify={justify}
      align={align}
    >
      {children}
    </RowGrid>
  )
}

export { Row }
