import { BrandContext } from 'contexts/BrandProvider'
import { useContext, useEffect } from 'react'
import { ReactComponent as CupraIcon } from 'assets/icons/cupra-logo.svg'
import { ReactComponent as SeatIcon } from 'assets/icons/seat-logo.svg'
import { Wrapper, ToggleSwitch } from './BrandSwitch.style'
import { useLocation } from 'react-router-dom'

const BrandSwitch = () => {
  const { pathname } = useLocation()
  const { brand, setBrand } = useContext(BrandContext)
  const isCupra = brand === 'cupra'

  const handleToggle = () => {
    const newBrand = isCupra ? 'seat' : 'cupra'
    if (newBrand === 'seat' && pathname.includes('smart-order')) {
      return
    }
    setBrand(newBrand)
  }

  const changeFavicon = (favicon) => {
    let link: any = document.querySelector("link[rel~='icon']")
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    link.href = favicon
  }

  useEffect(() => {
    const favicon = `${process.env.PUBLIC_URL}/favicon-${brand}.ico`
    changeFavicon(favicon)
  }, [brand])

  return (
    <Wrapper>
      <ToggleSwitch onClick={handleToggle}>
        <div>{isCupra ? <CupraIcon /> : <SeatIcon />}</div>
      </ToggleSwitch>
    </Wrapper>
  )
}

export { BrandSwitch }
