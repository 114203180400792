import styled from 'styled-components'

type columnAlign = 'left' | 'center' | 'right'

export const TableWrapper = styled.table<{
  $scroll?: boolean
}>`
  width: 100%;
  line-height: 1.4;
  overflow-x: scroll;
  display: ${({ $scroll }) => ($scroll ? 'block' : 'table')};
`

export const THWrapper = styled.span<{
  columnAlign?: columnAlign
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ columnAlign }) => {
    if (columnAlign === 'left') {
      return 'start'
    }
    if (columnAlign === 'right') {
      return 'end'
    }
    return 'center'
  }};
`

export const TH = styled.th<{
  columnAlign?: columnAlign
  pointer?: boolean
  allowHeaderWordBreak?: boolean
}>`
  text-align: ${({ columnAlign }) => (columnAlign ? columnAlign : 'center')};
  cursor: ${({ pointer }) => pointer && 'pointer'};
  padding: 0 1.6rem;
  white-space: ${({ allowHeaderWordBreak }) =>
    allowHeaderWordBreak ? 'inherit' : 'nowrap'};
  text-overflow: ellipsis;
  font-weight: 500;
  position: relative;
  color: ${({ theme }) => theme.colors.black};
  height: 4.4rem;
  &:first-child {
    ${THWrapper} {
      justify-content: start;
    }
  }
`

export const TR = styled.tr`
  background-color: ${({ theme }) => theme.colors.white};
  &:nth-child(2n + 1) {
    background-color: ${({ theme }) => theme.colors.bgPage};
  }
  transition: all 0.2s ease;
`

export const TD = styled.td<{
  bold: boolean
  columnAlign?: columnAlign
  sortable?: boolean
  enableSorting?: boolean
}>`
  text-align: ${({ columnAlign }) => (columnAlign ? columnAlign : 'center')};
  padding: 0 1.6rem;
  padding-right: ${({ sortable, enableSorting }) =>
    sortable && enableSorting !== false && '3.4rem'};
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.black};
  height: 4.4rem;
  ${({ bold }) =>
    bold &&
    `
      font-weight: 500;
  `};
  span {
    color: ${({ theme }) => theme.colors.gray};
  }
`

export const Thead = styled.thead`
  ${TR} {
    background-color: inherit;
  }
`

export const Tbody = styled.tbody``

export const Tfoot = styled.tfoot`
  ${TR} {
    background-color: #d7d9db;
  }
`

export const TableFilters = styled.div`
  height: 5.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 1.6rem;
  border-bottom: ${({ theme }) => '1px solid' + theme.colors.border};
`

export const PageSelectWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const PageSelectTitle = styled.span`
  margin-right: 0.8rem;
  color: ${({ theme }) => theme.colors.bgBlack};
`
