/* eslint-disable @typescript-eslint/no-unused-vars */
import { Col } from 'components/Grid/Col'
import { Row } from 'components/Grid/Row'
import { Title } from 'components/Title'
import { Container } from 'layout/Content/Content.style'
import { useParams, useNavigate, useLocation } from 'react-router-dom'

import {
  getBackOrdersByModel,
  getIncomingOrdersByModel,
  getIncomingOrdersObjectiveByModel,
  getInvoicedStockByModel,
  getLogisticStockByModel,
  getPrivateMarketByModel
} from 'api/dashboard/kpisByModel'

import { useTokenUuid } from 'hooks/request'
import { useState, useEffect, useContext } from 'react'
import { IncomingOrders } from './IncomingOrders'
import { Spinner } from 'components/Spinner'
import { IncomingOrdersObjective } from './IncomingOrdersObjective'
import { BackOrders } from './BackOrders'
import { InvoicedStock } from './InvoicedStock'
import { LogisticStock } from './LogisticStock'
import { PrivateMarket } from './PrivateMarket'

import { getModelColor, getModelImage, toQueryParams } from 'api/utils'
import { getDemandByModelFourMonths } from 'api/pricing/demand'
import { MultiInputChart } from 'components/Graphs/MultiInputChart/MultiInputChart'
import { BrandContext } from 'contexts/BrandProvider'
import { useMergePoints } from 'views/Pricing/views/DemandForecast/hooks/useDemandForecast'
import { Button } from 'components/Button/Simple/Button'
import {
  DATA_FORECAST_INSIGHTS,
  DEMAND_FORECAST,
  MTF_COMPARATIVE,
  POCKET_ANALYSIS,
  PRICING
} from 'constants/paths'

const DashboardByModel = () => {
  const { modelo } = useParams()
  const navigate = useNavigate()

  const location = useLocation()
  const paramsLocation = location.state || {}
  const { modelVariant } = paramsLocation

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [privateMarket, setPrivateMarket] = useState<any>({})

  const [incomingOrders, setIncomingOrders] = useState<any>({})
  const [incomingOrdersObjective, setIncomingOrdersObjective] = useState<any>()
  const [invoicedStock, setInvoicedStock] = useState<any>({})
  const [logisticStock, setLogisticStock] = useState<any>({})
  const [backOrders, setBackOrders] = useState<any>({})
  const [dataGraph, setDataGraph] = useState<any>(undefined)

  const uuid = useTokenUuid()
  const { brand } = useContext(BrandContext)

  const fetchKPIData = async () => {
    setIsLoading(true)

    if (modelo) {
      //KPI 1: Mercado Privado
      const privateMarketKPI: any = await getPrivateMarketByModel(
        uuid,
        modelo,
        modelVariant
      )
      setPrivateMarket(privateMarketKPI?.private_market_by_model_kpi)

      //KPI 2: Incoming orders
      const incomingOrdersKPI: any = await getIncomingOrdersByModel(
        uuid,
        modelo,
        modelVariant
      )
      setIncomingOrders(incomingOrdersKPI?.incoming_orders_kpi)

      //KPI 3: Incoming orders objective
      const incomingOrdersObjectiveByModel: any =
        await getIncomingOrdersObjectiveByModel(uuid, modelo, modelVariant)

      setIncomingOrdersObjective(
        incomingOrdersObjectiveByModel.incoming_orders_objective_kpi
      )

      //KPI 4:Invoiced Stock
      const invoicedStockKPI: any = await getInvoicedStockByModel(
        uuid,
        modelo,
        modelVariant
      )
      setInvoicedStock(invoicedStockKPI?.invoiced_stock_kpi)

      //KPI 5:Logistic Stock
      const logisticStockKPI: any = await getLogisticStockByModel(
        uuid,
        modelo,
        modelVariant
      )
      setLogisticStock(logisticStockKPI?.logistic_stock_kpi)

      //KPI 7: Back orders
      const backOrdersKPI: any = await getBackOrdersByModel(
        uuid,
        modelo,
        modelVariant
      )
      setBackOrders(backOrdersKPI?.back_orders_kpi)
    }

    setIsLoading(false)
  }

  const fetchDemandDataModel = async () => {
    setIsLoading(true)

    let queryParams = toQueryParams({
      brand: brand,
      model: modelo
    })
    const resp: any = await getDemandByModelFourMonths(uuid, queryParams)

    if (resp !== 500 && resp !== 204 && resp !== 404) {
      const result = useMergePoints(
        resp.cars_in_stock_points,
        resp.confs_points,
        resp.corrected_discount_points,
        resp.total_budget_points,
        resp.traffic_shw_points,
        resp.orders_points
      )
      setDataGraph(result)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    uuid && fetchKPIData()
    uuid && fetchDemandDataModel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid])

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && (
        <Container>
          <Row disableMarginBottom>
            <Col>
              <Title label="go back" arrowAction={() => navigate(-1)} />
            </Col>
          </Row>
          <Row>
            {modelo && (
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    color: getModelColor(modelo)
                  }}
                >
                  <h1>
                    <span>SEAT</span>
                  </h1>
                  <h1
                    style={{
                      fontSize: '80px',
                      lineHeight: '56px',
                      textTransform: 'capitalize'
                    }}
                  >
                    {modelVariant ?? modelo}
                  </h1>
                </div>
                <img
                  src={getModelImage(modelVariant ?? modelo)}
                  style={{ maxHeight: '200px' }}
                />
              </div>
            )}
            <div style={{ display: 'flex', marginLeft: 'auto' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '18px',
                  marginRight: '12px'
                }}
              >
                <Button
                  onClick={() =>
                    navigate('/' + PRICING + '/' + MTF_COMPARATIVE, {
                      state: { model: modelo }
                    })
                  }
                  label="See more in Multivariant Graph →"
                  look="filled"
                />
                <Button
                  onClick={() =>
                    navigate('/' + PRICING + '/' + POCKET_ANALYSIS, {
                      state: { model: modelo }
                    })
                  }
                  label="See more in Pocket Analysis →"
                  look="filled"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '18px'
                }}
              >
                <Button
                  onClick={() =>
                    navigate('/' + PRICING + '/' + DEMAND_FORECAST, {
                      state: { model: modelo }
                    })
                  }
                  label="See more in Demand Forecast →"
                  look="filled"
                />
                <Button
                  onClick={() =>
                    navigate('/' + PRICING + '/' + DATA_FORECAST_INSIGHTS, {
                      state: { model: modelo }
                    })
                  }
                  label="See more in Data Forecast Insights→"
                  look="filled"
                />
              </div>
            </div>
          </Row>
          <Row>
            {privateMarket && (
              <Col>
                <PrivateMarket privateMarket={privateMarket} />
              </Col>
            )}

            {incomingOrders && (
              <Col>
                <IncomingOrders incomingOrders={incomingOrders} />
              </Col>
            )}

            {incomingOrdersObjective && (
              <Col>
                <IncomingOrdersObjective
                  incomingOrdersObjective={incomingOrdersObjective}
                />
              </Col>
            )}
          </Row>
          <Row>
            {invoicedStock && (
              <Col>
                <InvoicedStock invoicedStock={invoicedStock} />
              </Col>
            )}
            {logisticStock && (
              <Col>
                <LogisticStock logisticStock={logisticStock} />
              </Col>
            )}

            {backOrders && (
              <Col>
                <BackOrders backOrders={backOrders} />
              </Col>
            )}
          </Row>
          {dataGraph && <MultiInputChart data={dataGraph} />}
        </Container>
      )}
    </>
  )
}

export { DashboardByModel }
