/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  TD,
  TH,
  TR,
  TableWrapper,
  Thead,
  Tbody,
  THWrapper,
  TableFilters,
  PageSelectWrapper,
  PageSelectTitle,
  Tfoot
} from './Paginated.style'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  SortingState,
  ColumnResizeMode,
  getPaginationRowModel
} from '@tanstack/react-table'
import { ReactComponent as SortIcon } from 'assets/icons/sort.svg'
import { ReactComponent as SortUpIcon } from 'assets/icons/sortUp.svg'
import { ReactComponent as SortDownIcon } from 'assets/icons/sortDown.svg'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Select } from 'components/Inputs/Select'
import { useTranslation } from 'react-i18next'
import { InputText } from 'components/Inputs/Text'
import { PaginationWidget } from './PaginationWidget'
import { PaginatedTableProps } from '../Table.types'
import Glass from 'assets/icons/glass.svg'

import * as amplitude from '@amplitude/analytics-browser'

// eslint-disable-next-line no-undef
const ColumnSortBy = (colHeader): JSX.Element => {
  const { header } = colHeader
  const sortType = header.column.getIsSorted() as string
  if (sortType === 'asc') {
    return <SortDownIcon />
  }
  if (sortType === 'desc') {
    return <SortUpIcon />
  }
  return <SortIcon />
}

const PaginatedTable = (props: PaginatedTableProps) => {
  const {
    data,
    columns,
    tableId,
    sortable = false,
    sortBy = [],
    enableResultsPerPage = true,
    filterKey,
    allowHeaderWordBreak,
    tablePaginationConfig,
    hasCustomBgrRow = '',
    hasLinkRow = false,
    handleRow = () => {},
    hasScroll,
    stickyFooter,
    isEditMode,
    totalBold,
    indexEditRow,
    handleUpdateInputValue
  } = props
  const [sorting, setSorting] = useState<SortingState>(sortBy)

  const [filtered, setFiltered] = useState([...data])
  const [columnResizeMode] = useState<ColumnResizeMode>()
  const { t } = useTranslation()

  const [rowUpdated, setRowUpdated] = useState<any>(null)

  const table = useReactTable({
    data: filtered,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode,
    getPaginationRowModel: getPaginationRowModel()
  })

  const updatePaginationFilter = (_id, e) => {
    table.setPageSize(Number(e.value))
  }

  const filterResults = useCallback(
    (filterValue: string | number) => {
      const res =
        filterKey &&
        data.filter((row) => {
          const rowValue = row[filterKey]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      setFiltered([...res])
    },
    [data, filterKey]
  )

  useEffect(() => {
    if (totalBold) table.setPageSize(Number('50'))
    setFiltered([...data])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const gotoPage = (e: number) => {
    amplitude.track(`Go to page ${e}`)
    const page = e ? Number(e) : 0
    table.setPageIndex(page)
  }

  const hasFooter = columns.some((column) => 'footer' in column) || props?.total

  const [isTrIndex, setTrIndex] = useState(0)
  const handleUpdateIndex = (row) => {
    setTrIndex(row.index)
  }

  const customBgrTr = hasLinkRow && hasCustomBgrRow

  const updateObjectByKey = useCallback(
    (key, updatedValue) => {
      if (rowUpdated && key) {
        setRowUpdated((prevRowUpdated) => ({
          ...prevRowUpdated,
          [key]: updatedValue
        }))
      }
    },
    [rowUpdated]
  )

  const handleUpdateValue = useCallback(
    (cell, e) => {
      updateObjectByKey(cell.column.id, e)
    },
    [updateObjectByKey]
  )

  useEffect(() => {
    if (rowUpdated && handleUpdateInputValue) handleUpdateInputValue(rowUpdated)
  }, [rowUpdated, handleUpdateInputValue])

  const updateInputValue = useCallback((val) => {
    setRowUpdated(val)
  }, [])

  useEffect(() => {
    if (indexEditRow !== null && indexEditRow !== undefined) {
      const rowToEdit = table
        .getRowModel()
        .rows.find((row) => row.index === indexEditRow)
      if (rowToEdit) {
        updateInputValue(rowToEdit.original)
      }
    }
  }, [indexEditRow, table, updateInputValue])

  return (
    <>
      {enableResultsPerPage && (
        <TableFilters>
          {filterKey ? (
            <InputText
              placeholder="Search by name"
              id={'filter' + tableId}
              onRefreshValue={filterResults}
              icon={Glass}
            />
          ) : (
            <span> </span>
          )}

          <PageSelectWrapper>
            <PageSelectTitle>{t('utils.resultsPerPage')}</PageSelectTitle>
            <Select
              options={[
                { value: 10, label: '10' },
                { value: 20, label: '20' },
                { value: 50, label: '50' }
              ]}
              id="pagination"
              defaultValue={{ value: 10, label: '10' }}
              onUpdateValue={updatePaginationFilter}
              minWidth={50}
            />
          </PageSelectWrapper>
        </TableFilters>
      )}
      <TableWrapper data-testid="paginated-table-component" $scroll={hasScroll}>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TR key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const colDef: any = header.column.columnDef
                const THprops = {
                  columnAlign:
                    'columnAlign' in colDef ? colDef.columnAlign : 'center',
                  rowSpan:
                    'rowSpan' in colDef ? colDef.rowSpan.toString() : '1',
                  pointer: sortable
                }
                return (
                  <TH
                    key={header.id}
                    {...THprops}
                    colSpan={header.colSpan}
                    allowHeaderWordBreak={allowHeaderWordBreak}
                  >
                    {!header.isPlaceholder && sortable && (
                      <THWrapper
                        {...{
                          onClick: () => {
                            header.column.getToggleSortingHandler()
                          }
                        }}
                        columnAlign={THprops.columnAlign}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.columnDef.enableSorting !== false && (
                          <ColumnSortBy header={header} />
                        )}
                      </THWrapper>
                    )}
                    {!header.isPlaceholder && !sortable && (
                      <THWrapper columnAlign={THprops.columnAlign}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </THWrapper>
                    )}
                  </TH>
                )
              })}
            </TR>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row, index) => (
            <TR
              key={index}
              onClick={() => {
                if (!hasLinkRow) return
                handleUpdateIndex(row)
                handleRow(row)
              }}
              style={{
                background:
                  customBgrTr && row.index === isTrIndex ? hasCustomBgrRow : '',
                fontWeight:
                  row.index === table.getRowModel().rows.length - 1 && totalBold
                    ? 'bold'
                    : ''
              }}
            >
              {row.getVisibleCells().map((cell, i) => {
                const colDef: any = cell.column.columnDef
                const TDprops = {
                  columnAlign:
                    'columnAlign' in colDef ? colDef.columnAlign : 'center',
                  bold: 'bold' in colDef && !!colDef.bold,
                  sortable,
                  enableSorting:
                    'enableSorting' in colDef ? colDef.enableSorting : true
                }

                return (
                  <TD
                    key={cell.id}
                    {...TDprops}
                    style={{ width: cell.column.getSize() * 2 }}
                  >
                    {(!isEditMode || i === 0 || row.index !== indexEditRow) &&
                      flexRender(cell.column.columnDef.cell, cell.getContext())}
                    {isEditMode && i !== 0 && row.index === indexEditRow && (
                      <>
                        <InputText
                          id={cell.id}
                          look="full"
                          defaultValue={cell.getValue()}
                          onRefreshValue={(e) => handleUpdateValue(cell, e)}
                          type="number"
                        />
                      </>
                    )}
                  </TD>
                )
              })}
            </TR>
          ))}
        </Tbody>
        {hasFooter && (
          <Tfoot style={{ position: stickyFooter ? 'absolute' : 'inherit' }}>
            <>
              {!props?.total &&
                table.getFooterGroups().map((footerGroup) => (
                  <TR key={footerGroup.id}>
                    {footerGroup.headers.map((header) => {
                      const colDef: any = header.column.columnDef
                      const THprops = {
                        columnAlign:
                          'columnAlign' in colDef
                            ? colDef.columnAlign
                            : 'center',
                        bold: true,
                        sortable
                      }
                      return (
                        <>
                          <TD
                            key={header.id}
                            {...THprops}
                            style={{ width: header.getSize() }}
                          >
                            {!header.isPlaceholder &&
                              flexRender(
                                header.column.columnDef.footer,
                                header.getContext()
                              )}
                          </TD>
                        </>
                      )
                    })}
                  </TR>
                ))}

              {props?.total && (
                <TR>
                  <TD bold={true} key={props.total}>
                    {`Total Recommended Order Prediction: ${props.total}`}
                  </TD>
                </TR>
              )}
            </>
          </Tfoot>
        )}
      </TableWrapper>
      {tablePaginationConfig && (
        <>
          <PaginationWidget
            nextPage={() => {
              tablePaginationConfig.onChangePage(
                tablePaginationConfig.actualPage + 1
              )
              table.nextPage()
            }}
            previousPage={() => {
              tablePaginationConfig.onChangePage(
                tablePaginationConfig.actualPage - 1
              )
              table.previousPage()
            }}
            canPreviousPage={tablePaginationConfig.actualPage > 1}
            canNextPage={
              tablePaginationConfig.actualPage <
              tablePaginationConfig.totalPages
            }
            pageCount={tablePaginationConfig.totalPages}
            gotoPage={(pageIndex) => {
              tablePaginationConfig.onChangePage(pageIndex + 1)
              gotoPage(pageIndex)
            }}
            pageIndex={tablePaginationConfig.actualPage - 1}
            pageSize={table.getState().pagination.pageSize}
            totalItems={tablePaginationConfig.totalItems}
          />
        </>
      )}
    </>
  )
}

export { PaginatedTable }
