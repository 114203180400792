import styled from 'styled-components'

export const LegendWrapper = styled.ul`
  display: flex;
  justify-content: center;

  li {
    &:before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 100%;
      display: inline-block;
      margin-right: 4px;
    }

    &:first-child {
      margin-right: 20px;

      &:before {
        width: 14px;
        height: 14px;
        background: linear-gradient(
          90deg,
          rgba(131, 58, 180, 1) 0%,
          rgba(255, 42, 70, 1) 50%,
          rgba(252, 176, 69, 1) 100%
        );
      }
    }

    &:last-child {
      &:before {
        border: 1px solid;
      }
    }
  }
`

export const TableCustom = styled.div`
  display: flex;
`

export const Item = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  padding: 0 1rem;
  position: relative;

  &:first-child {
    width: 70%;
  }
`
