import { Wrapper, WrapperLink } from './Button.style'
import { ButtonProps } from './Button.types'

const Button = (props: ButtonProps) => {
  const {
    label,
    onClick,
    disabled,
    look = 'outlined',
    position,
    tag,
    routeTo
  } = props
  if (tag === 'a') {
    return (
      <WrapperLink to={routeTo} look={look} position={position}>
        {label}
      </WrapperLink>
    )
  }
  return (
    <Wrapper
      onClick={onClick}
      disabled={disabled}
      look={look}
      position={position}
    >
      {label}
    </Wrapper>
  )
}

export { Button }
