import { PageBreadCrumb, PageTitle, Wrapper } from './Title.style'
import { ReactComponent as ArrowLeft } from 'assets/icons/arrowleft.svg'
import { useTranslation } from 'react-i18next'
import { TitleProps } from './Title.types'

const Title = (props: TitleProps) => {
  const { t } = useTranslation()
  const { label, arrowBackTo, color = 'black', arrowAction } = props
  return (
    <Wrapper data-testid="title">
      {arrowBackTo && (
        <PageBreadCrumb to={arrowBackTo} title={t('utils.return')}>
          <ArrowLeft />
        </PageBreadCrumb>
      )}
      {arrowAction && (
        <PageBreadCrumb to={''} title={t('utils.return')} onClick={arrowAction}>
          <ArrowLeft />
        </PageBreadCrumb>
      )}
      <PageTitle color={color} $arrowAction={arrowAction ? true : false}>
        {label}
      </PageTitle>
    </Wrapper>
  )
}

export { Title }
