import { parseLocaleNumber } from 'utils'

export const sortTableFn = (
  rowA: { original: { [x: string]: { toString: () => string } } },
  rowB: { original: { [x: string]: { toString: () => string } } },
  id: string | number
) => {
  const numberA = parseLocaleNumber(rowA.original[id].toString())
  const numberB = parseLocaleNumber(rowB.original[id].toString())
  const valueA = !isNaN(numberA) ? numberA : rowA.original[id]
  const valueB = !isNaN(numberB) ? numberB : rowB.original[id]
  if (valueA > valueB) return 1
  if (valueB > valueA) return -1
  return 0
}
