import { createContext, useEffect, useState } from 'react'
import { capitalize } from 'utils'
import { useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'

const fakeAuth = {
  isAuthenticated: false,
  signin(cb: { (): void; (...args: any[]): void }) {
    fakeAuth.isAuthenticated = true
    setTimeout(cb, 100) // fake async
  },
  signout(cb: { (): void; (...args: any[]): void }) {
    fakeAuth.isAuthenticated = false
    setTimeout(cb, 100)
  }
}

const useProvideAuth = () => {
  const [user, setUser] = useState<any>(null)

  const signin = (cb: () => void) => {
    return fakeAuth.signin(() => {
      const user = cb()
      setUser(user)
    })
  }

  const signout = (cb: () => void) => {
    return fakeAuth.signout(() => {
      const user = cb()
      setUser(user)
    })
  }

  return {
    user,
    signin,
    signout
  }
}

const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
  return JSON.parse(jsonPayload)
}

const isValidToken = (token: string): boolean => {
  try {
    const res = parseJwt(token)
    const { exp } = res
    const currDate = Math.floor(new Date().getTime() / 1000)
    return exp > currDate
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Invalid token. Please sign in again')
  }
  return false
}

const getUser = (token: string) => {
  const res = parseJwt(token)
  const userName = res['cognito:username'].replace('.', ' ')
  const exp = res.exp - new Date().getTime() / 1000
  return {
    token,
    name: capitalize(userName),
    exp
  }
}

const useToken = () => {
  //TODO:ISI-2072 to change the way Cognito token is provided and redo signin-signout (use Amplify)
  const idTokenLabel = '#id_token='
  const { hash } = useLocation()
  const cognitoAuth = hash.includes(idTokenLabel)
    ? hash.replace(idTokenLabel, '')
    : null

  return cognitoAuth?.split('&')[0]
}

export const AuthContext = createContext(null)

const removeAccessTokenFromUrl = () => {
  const { history, location } = window
  const { hash } = location
  if (
    hash &&
    hash.indexOf('#id_token') !== -1 &&
    history &&
    history.replaceState
  ) {
    // Remove everything from #id_token hash
    const cleanSearch = hash.replace(/#id_token=.*/g, '')
    // Clean hash value in the URL
    const cleanURL = location.toString().replace(hash, cleanSearch)
    // Use browser history API to clean the params
    history.replaceState({}, '', cleanURL)
  }
}

export const AuthProvider = ({ children }) => {
  const auth: any = useProvideAuth()
  const [cookies, setCookie] = useCookies(['token'])
  const idToken = useToken() || cookies.token
  useEffect(() => {
    if (!auth.user) {
      if (idToken && isValidToken(idToken)) {
        const user = getUser(idToken)
        auth.signin(() => {
          removeAccessTokenFromUrl()
          return user
        })
        setCookie('token', idToken, { maxAge: user.exp, path: '/' })
      } else {
        const loginUrl = process.env.REACT_APP_COGNITO_REDIRECT_URI_SIGN_IN
        if (loginUrl) {
          window.location.replace(loginUrl)
        }
      }
    }
  }, [auth, idToken, setCookie])

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}
