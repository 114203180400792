import { create } from 'zustand'
import zustymiddleware from 'zustymiddleware'

interface FilterValue {
  label: 'string'
  value: number | string
}

export interface DemandFilters {
  inputForecastModel?: null | FilterValue[]
  inputForecastMTF?: null | FilterValue[]
  inputForecastFuel?: null | FilterValue[]
}
const pocketFilters: DemandFilters = {
  inputForecastModel: null,
  inputForecastMTF: null,
  inputForecastFuel: null
}

export const useDemandStore = create(
  zustymiddleware((set) => ({
    ...pocketFilters,
    updateFilter: (filterName: string, value: number[] | string[]) => {
      if (typeof value === 'object') {
        set(() => ({ [filterName]: value }))
      }
    },
    resetFilter: (filterName: string) => {
      set(() => ({ [filterName]: pocketFilters[filterName] }))
    }
  }))
)

window.store = useDemandStore
