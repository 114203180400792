/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useContext, useEffect, useCallback } from 'react'

import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Bar,
  ReferenceDot
} from 'recharts'

import { Tooltip as TooltipCard } from 'components/Tooltip'
import { Row } from 'components/Grid/Row'
import { Col } from 'components/Grid/Col'
import { Card } from 'components/Card'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'components/ErrorFallback'
import { BrandContext } from 'contexts/BrandProvider'
import { DensityHistogramStyles } from './DensityHistogramChart.style'
import { useTranslation } from 'react-i18next'
import { useInputForecastStore } from 'state'
import { shallow } from 'zustand/shallow'

import * as amplitude from '@amplitude/analytics-browser'

const DensityHistogram = (data) => {
  const { brand } = useContext(BrandContext)
  const [tooltipActive, setTooltipActive] = useState(false)
  const [tooltipActiveObjective, setTooltipActiveObjective] = useState(false)

  const { customObjective, setCustomObjetive } = useInputForecastStore(
    (state: any) => ({
      customObjective: state.customObjective,
      setCustomObjetive: state.setCustomObjetive
    }),
    shallow
  )

  const handleObjetive = (value: string | number) => {
    handleCustomObjetive(value)
  }

  const handleCustomObjetive = useCallback(
    (value) => {
      setCustomObjetive(value)
    },
    [setCustomObjetive]
  )

  const { t } = useTranslation()

  const hist = data.data.histogram.hist

  const bins = data.data.histogram.bins
  const xMax = Math.max(...bins)
  const xMin = Math.min(...bins)

  const regression = data.data.regression

  const yMax = Math.max(...regression.map((o) => o.y_value))

  const lastPoint = data.data.most_recent

  // Create data array for the histogram chart
  const chartInputData = bins.map((edge, index) => ({
    bin: bins[index].toString(),
    frequency: hist[index],
    regression: regression[index]?.y_value
  }))

  const interval = [
    lastPoint.value - lastPoint.value * 0.1,
    lastPoint.value + lastPoint.value * 0.1
  ]

  const handleShowTooltip = () => {
    amplitude.track('Info histogram point tooltip')

    const circle = document.getElementById('reference-dot')

    setTooltipActive(true)

    setTimeout(() => {
      if (document.querySelectorAll<HTMLElement>('[data-testid=tooltip]')[0]) {
        let tooltip = document.querySelectorAll<HTMLElement>(
          '[data-testid=tooltip]'
        )[0]
        tooltip.style.minHeight = 'unset'
        tooltip.style.left = `${circle?.getAttribute('cx')}px`
        tooltip.style.top = `${circle?.getAttribute('cy')}px`
        tooltip.style.transform = 'none'
        tooltip.style.background = brand === 'seat' ? '#F96C64' : '#95572B'
      }
    }, 0)
  }
  const handleHideTooltip = () => {
    setTooltipActive(false)
  }

  const handleShowTooltipObjective = () => {
    const circle = document.getElementById('objetive-dot')

    setTooltipActiveObjective(true)

    setTimeout(() => {
      if (document.querySelectorAll<HTMLElement>('[data-testid=tooltip]')[0]) {
        let tooltip = document.querySelectorAll<HTMLElement>(
          '[data-testid=tooltip]'
        )[0]
        const x = circle?.getAttribute('x')
        const y = circle?.getAttribute('y')
        const finalX = x ? parseInt(x) - 20 : 0
        const finalY = y ? parseInt(y) + 20 : 0
        tooltip.style.minHeight = 'unset'
        tooltip.style.left = `${finalX}px`
        tooltip.style.top = `${finalY}px`
        tooltip.style.transform = 'none'
        tooltip.style.background = '#018A7F'
      }
    }, 0)
  }

  const handleHideTooltipObjective = () => {
    setTooltipActiveObjective(false)
  }

  const sumWithInitial = () => {
    return chartInputData.reduce((acc, curr) => {
      if (
        curr.bin >= interval[0] &&
        curr.bin <= interval[1] &&
        curr.frequency !== undefined
      )
        acc += curr.frequency
      return acc
    }, 0)
  }

  const totalCount = () => {
    return data.data.histogram.hist.reduce((acc, curr) => {
      return (acc += curr)
    }, 0)
  }

  const objetiveFreq = (obj) => {
    const objetiveInterval = [obj - obj * 0.1, obj + obj * 0.1]
    return chartInputData.reduce((acc, curr) => {
      if (
        curr.bin >= objetiveInterval[0] &&
        curr.bin <= objetiveInterval[1] &&
        curr.frequency !== undefined
      )
        acc += curr.frequency
      return acc
    }, 0)
  }

  const customReferenceDot = (props: any) => {
    return (
      <circle
        cx={props.cx}
        r="10"
        cy={props.cy}
        fill={brand === 'seat' ? '#F96C64' : '#95572B'}
        onMouseEnter={handleShowTooltip}
        onMouseLeave={handleHideTooltip}
        id="reference-dot"
      >
        <animate
          attributeName="r"
          from="8"
          to="12"
          dur="1.5s"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
    )
  }

  const objetiveDot = (props: any) => {
    return (
      <svg
        x={props.cx}
        y={props.cy - 16}
        onMouseEnter={handleShowTooltipObjective}
        onMouseLeave={handleHideTooltipObjective}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        id="objetive-dot"
      >
        <g clipPath="url(#clip0_339_1375)">
          <path
            d="M10.1686 1.87491L7.4124 7.45616L1.2499 8.34991L5.7124 12.6999L4.65615 18.8374L10.1686 15.9374L15.6812 18.8374L14.6249 12.6999L19.0874 8.34991L12.9249 7.45616L10.1686 1.87491Z"
            stroke="#018A7F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.1686 1.87491L7.4124 7.45616L1.2499 8.34991L5.7124 12.6999L4.65615 18.8374L10.1686 15.9374L15.6812 18.8374L14.6249 12.6999L19.0874 8.34991L12.9249 7.45616L10.1686 1.87491Z"
            fill="#018A7F"
            stroke="#018A7F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_339_1375">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
  }

  return (
    <DensityHistogramStyles>
      <Row>
        <Col>
          <Card
            headerTitle="Histogram"
            subtitle="The red dot indicates the number of times the prediction from the last month appears in the model's history."
            withSearch
            searchLabel="Monthly objective:"
            searchVal={customObjective}
            searchFunc={handleObjetive}
            minHeightBody={43.6}
            tooltip={
              <p>{t('pricing.dataForecastInsights.densityHistogramToolip')}</p>
            }
          >
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  data={chartInputData}
                  margin={{ top: 20, right: 40, bottom: 40, left: 40 }}
                >
                  <CartesianGrid
                    stroke="#DEDEDE"
                    strokeDasharray="3 3"
                    vertical={false}
                  />

                  <Bar
                    dataKey="frequency"
                    fill={brand === 'seat' ? '#FDD1CF' : '#DECBBD'}
                    barSize={16}
                    width={330}
                    isAnimationActive={false}
                  />

                  <YAxis
                    name="frequency"
                    dataKey="frequency"
                    domain={[0, yMax]}
                    label={{
                      value: 'Frequency',
                      angle: -90,
                      position: 'left'
                    }}
                  />
                  <XAxis
                    name="bin"
                    tickCount={10}
                    padding={{ left: 20, right: 20 }}
                    dataKey="bin"
                    domain={[xMin, xMax]}
                    type="number"
                    label={{
                      value: data.data.input_data_filter.input_data_filter,
                      position: 'bottom'
                    }}
                    interval="preserveEnd"
                    minTickGap={15}
                  />
                  <Legend
                    verticalAlign="bottom"
                    align="center"
                    wrapperStyle={{ left: 50, bottom: 10 }}
                    payload={
                      customObjective > 0
                        ? [
                            {
                              value: 'Frequency',
                              type: 'square',
                              id: 'value',
                              color: brand === 'seat' ? '#FDD1CF' : '#DECBBD'
                            },
                            {
                              value: 'Variable',
                              type: 'line',
                              id: 'value',
                              color: brand === 'seat' ? '#F96C64' : '#95572B'
                            },
                            {
                              value: 'Objetive',
                              type: 'star',
                              id: 'value',
                              color: '#018A7F'
                            }
                          ]
                        : [
                            {
                              value: 'Frequency',
                              type: 'square',
                              id: 'value',
                              color: brand === 'seat' ? '#FDD1CF' : '#DECBBD'
                            },
                            {
                              value: 'Variable',
                              type: 'line',
                              id: 'value',
                              color: brand === 'seat' ? '#F96C64' : '#95572B'
                            }
                          ]
                    }
                  />

                  <Line
                    dataKey="regression"
                    activeDot={true}
                    dot={false}
                    stroke={brand === 'seat' ? '#F96C64' : '#95572B'}
                    strokeWidth="4"
                  />

                  <ReferenceDot
                    x={data.data.most_recent.value}
                    y={data.data.most_recent.frequency}
                    shape={customReferenceDot}
                  />

                  <ReferenceDot x={customObjective} y={0} shape={objetiveDot} />
                </ComposedChart>
              </ResponsiveContainer>
            </ErrorBoundary>
            {tooltipActive && (
              <TooltipCard>
                <span>% of times variable appears</span>
                <span>Value: {data.data.most_recent.value}</span>
                <h4>
                  {sumWithInitial()}/{totalCount()} ={' '}
                  {((sumWithInitial() / totalCount()) * 100).toFixed(2)}%
                </h4>
              </TooltipCard>
            )}

            {tooltipActiveObjective && (
              <TooltipCard>
                <span>Monthly objective frequency:</span>
                <h4>{objetiveFreq(customObjective)} times</h4>
              </TooltipCard>
            )}
          </Card>
        </Col>
      </Row>
    </DensityHistogramStyles>
  )
}

export { DensityHistogram }
