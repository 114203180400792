const hexColor = '#E4A445'

export const generateConsistentColors = (count) => {
  const colors: any = []
  let r = parseInt(hexColor.substr(1, 2), 16)
  let g = parseInt(hexColor.substr(3, 2), 16)
  let b = parseInt(hexColor.substr(5, 2), 16)

  for (let i = 0; i < count; i++) {
    r = (r + 35 + i * 5) % 256
    g = (g + 45 + i * 15) % 256
    b = (b + 55 + i * 10) % 256

    let newHex: string =
      '#' +
      r.toString(16).padStart(2, '0') +
      g.toString(16).padStart(2, '0') +
      b.toString(16).padStart(2, '0')

    colors.push(newHex)
  }

  return colors
}
