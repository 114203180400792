/* eslint-disable @typescript-eslint/no-unused-vars */
import { Filters } from 'components/Filters'
import { Select } from 'components/Inputs/Select'
import {
  useRef,
  MutableRefObject,
  useCallback,
  useState,
  useEffect,
  useContext
} from 'react'
import { useDemandStore } from 'state'
import { arrayObjectsAreSame } from 'utils'
import { shallow } from 'zustand/shallow'
import { BrandContext } from 'contexts/BrandProvider'
import { useTokenUuid } from 'hooks/request'
import { toQueryParams } from 'api/utils'

import {
  getDemandModelsFilterList,
  getDemandMTFsFilterList
} from 'api/pricing/demand'

const PageFilters = ({ handleFilters, selectedModel }) => {
  const modelRef: MutableRefObject<any> = useRef('model')
  const mtfRef: MutableRefObject<any> = useRef('mtf')

  const { brand } = useContext(BrandContext)
  const uuid = useTokenUuid()

  const { model, mtf, updateFilter, resetFilter } = useDemandStore(
    (state: any) => ({
      model: state.inputForecastModel,
      mtf: state.inputForecastMTF,
      updateFilter: state.updateFilter,
      resetFilter: state.resetFilter
    }),
    shallow
  )

  const [valueModel, setValueModel] = useState(model)
  const [valuemtf, setValuemtf] = useState(mtf)

  const [modelList, setModelList] = useState<any[]>([])
  const [mtfList, setMtfList] = useState<any[]>([])

  const updateStateFilter = useCallback((key: string, value: any) => {
    switch (key) {
      case 'model':
        if (value) setValueModel(value)
        break
      case 'mtf':
        if (value) setValuemtf(value)
        break
    }
  }, [])

  const applyAll = useCallback(() => {
    updateFilter('inputForecastModel', valueModel)
    updateFilter('inputForecastMTF', valuemtf)
  }, [updateFilter, valueModel, valuemtf])

  const clearAll = useCallback(() => {
    modelRef.current.clearValue()
    mtfRef.current.clearValue()
    resetFilter('inputForecastModel')
    resetFilter('inputForecastMTF')
  }, [resetFilter])

  const enableApplyAll = useCallback(() => {
    return (
      valueModel &&
      valueModel.label &&
      valuemtf &&
      valuemtf.label &&
      !(
        arrayObjectsAreSame(model, valueModel) &&
        arrayObjectsAreSame(mtf, valuemtf)
      )
    )
  }, [model, valueModel, mtf, valuemtf])

  const totalFilters = useCallback(() => {
    const hasModel = +!!model?.model
    const hasmtf = +!!mtf?.value
    return hasModel + hasmtf
  }, [model?.model, mtf?.value])

  let queryParams = toQueryParams({
    brand
  })

  const fetchModelFiltersList = async () => {
    const resp: any = await getDemandModelsFilterList(uuid, queryParams)
    if (resp !== 404 && resp !== 204 && resp !== 500) {
      const formattedList = resp.filter_list.map((model: any) => ({
        value: model.value,
        label: model.label
      }))
      setModelList(formattedList)

      if (selectedModel) {
        const matchedModel = formattedList.find(
          (model) => model.value === selectedModel
        )

        setValueModel(
          matchedModel || {
            value: selectedModel,
            label:
              selectedModel.charAt(0).toUpperCase() +
              selectedModel.slice(1).toLowerCase()
          }
        )
      }
    }
  }

  const fetchMTFsFiltersList = async () => {
    queryParams = `model=${valueModel?.label}&${queryParams}`
    const resp: any = await getDemandMTFsFilterList(uuid, queryParams)
    if (resp !== 404 && resp !== 204) {
      setMtfList(resp.filter_list)
    }
  }

  useEffect(() => {
    uuid && fetchModelFiltersList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, uuid])

  useEffect(() => {
    uuid && fetchMTFsFiltersList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, uuid, valueModel])

  useEffect(() => {
    modelRef.current.clearValue()
    mtfRef.current.clearValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid])

  useEffect(() => {
    setValuemtf([])
    mtfRef.current.clearValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, valueModel])

  useEffect(() => {
    handleFilters(valuemtf)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuemtf])

  useEffect(() => {
    clearAll()
    setValueModel([])
    setValuemtf([])
    modelRef.current.clearValue()
    mtfRef.current.clearValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, brand])

  useEffect(() => {
    if (selectedModel) {
      setValueModel({
        value: selectedModel,
        label:
          selectedModel.charAt(0).toUpperCase() +
          selectedModel.slice(1).toLowerCase()
      })
    }
  }, [selectedModel])

  return (
    <Filters
      applyAll={applyAll}
      clearAll={clearAll}
      enableApplyAll={enableApplyAll()}
      enableClearAll={false}
      totalFilters={totalFilters()}
    >
      <Select
        options={modelList}
        emptyPlaceholder="Model"
        reference={modelRef}
        value={valueModel}
        onUpdateValue={updateStateFilter}
        id="model"
      />

      <Select
        options={mtfList}
        emptyPlaceholder="MTF"
        reference={mtfRef}
        defaultValue={mtf}
        onUpdateValue={updateStateFilter}
        id="mtf"
        isDisabled={valueModel === null}
      />
    </Filters>
  )
}

export { PageFilters }
