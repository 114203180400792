import { DefaultTheme } from 'styled-components'

export const seatTheme: DefaultTheme = {
  name: 'seat-theme',
  colors: {
    black: '#1B1B1B',
    bgBlack: '#343A40',
    lightGray: '#999',
    gray: '#6C757D',
    border: '#dedede80',
    bgPage: '#F5F5F5',
    white: '#fff',
    red: '#DC3545',
    green: '#28A745',
    yellow: '#FF9C07',
    primary: '#F96C64',
    primaryEvent: '#FDD1CF',
    chart1: '#007BFF',
    chart2: '#FFC107',
    chart3: '#D3DAE0',
    chart4: '#902A34',
    chart5: '#1A5A0C',
    chart6: '#607497'
  },
  font: 'Cupra, Helvetica, sans-serif'
}

export const cupraTheme: DefaultTheme = {
  name: 'cupra-theme',
  colors: {
    black: '#1B1B1B',
    bgBlack: '#343A40',
    lightGray: '#999',
    gray: '#6C757D',
    border: '#dedede80',
    bgPage: '#F5F5F5',
    white: '#fff',
    red: '#DC3545',
    green: '#28A745',
    yellow: '#FF9C07',
    primary: '#95572B',
    primaryEvent: '#E4D5CA',
    chart1: '#003E51',
    chart2: '#D9CEBD',
    chart3: '#018A7F',
    chart4: '#902A34',
    chart5: '#1A5A0C',
    chart6: '#607497'
  },
  font: 'Cupra, Helvetica, sans-serif'
}
