import styled from 'styled-components'
import { RadioProps } from './Radio.types'

export const RadioItem = styled.div`
  display: flex;
  flex-direction: row;

  input[type='radio'] {
    opacity: 0;
    position: fixed;
    width: 0;
    border: 1px solid #dedede;
  }

  input[type='radio']:checked + label {
    background-color: ${({ theme }) => theme.colors.primaryEvent};
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const RadioLabel = styled.label<RadioProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: -1px;
  cursor: pointer;
  min-width: 85px;
  border-radius: 0;
`
