import { api } from 'api/api'

export const getPrivateMarket = async (uuid: string, queryParams: string) => {
  const response = await api.get(
    '/dashboard-kpis/private-market',
    queryParams,
    uuid,
    1
  )
  return response
}

export const getIncomingOrders = async (uuid: string, queryParams: string) => {
  const response = await api.get(
    '/dashboard-kpis/incoming-orders',
    queryParams,
    uuid,
    1
  )
  return response
}

export const getIncomingOrdersObjective = async (
  uuid: string,
  queryParams: string
) => {
  const response = await api.get(
    '/dashboard-kpis/incoming-orders-objective',
    queryParams,
    uuid,
    1
  )
  return response
}

export const getInvoicedStock = async (uuid: string, queryParams: string) => {
  const response = await api.get(
    '/dashboard-kpis/invoiced-stock',
    queryParams,
    uuid,
    1
  )
  return response
}

export const getLogisticStock = async (uuid: string, queryParams: string) => {
  const response = await api.get(
    '/dashboard-kpis/logistic-stock',
    queryParams,
    uuid,
    1
  )
  return response
}

export const getProduction = async (uuid: string, queryParams: string) => {
  const response = await api.get(
    '/dashboard-kpis/production',
    queryParams,
    uuid,
    1
  )
  return response
}

export const getBackOrders = async (uuid: string, queryParams: string) => {
  const response = await api.get(
    '/dashboard-kpis/back-orders',
    queryParams,
    uuid,
    1
  )
  return response
}
