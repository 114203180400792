import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
`

export const ToggleSwitch = styled.div`
  display: flex;
  align-items: center;
  background-color: #0f637c;
  border-radius: 16px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
`

export const ToggleLabel = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
`
