import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: sticky;
  top: 4.2rem;
  height: 5.4rem;
  padding: 0 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.6rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 10;
  justify-content: flex-end;
`

export const NavList = styled.ul`
  display: flex;
  height: 100%;
  align-items: center;
`

export const NavItem = styled.li`
  height: 100%;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 100ms ease-in-out;

  &::after {
    display: block;
    content: '';
    border-bottom: solid 3px #0f637c;
    transform: scaleX(0);
    transition: transform 100ms ease-in-out;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  &:hover {
    color: #0f637c;
  }

  &:hover::after {
    transform: scaleX(1);
  }
`

export const NavTitle = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 0;
  cursor: pointer;
`

export const NavSubmenu = styled.ul`
  position: absolute;
  bottom: 0;
  right: 0;
  top: 53px;
  background: white;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  box-shadow: 0px 22 2px rgba(0, 0, 0, 0.12);
`

export const InternalNavLink = styled(NavLink)`
  text-decoration: none;
  white-space: nowrap;
  height: 100%;
  color: ${({ theme }) => theme.colors.gray};
  font-size: 1.5rem;
  font-weight: 400;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;

  &.active {
    color: #0f637c;
  }
`
export const SubmenuItem = styled.li`
  height: 100%;
  padding: 16px;
  width: 100%;
  border-bottom: 3px solid #fff;

  &:hover {
    background-color: #0f637c20;
    border-bottom: 3px solid #0f637c;

    ${InternalNavLink} {
      color: #0f637c;
    }
  }
`
