import { Card } from 'components/Card'
import { PieChart } from 'components/Graphs/PieChart/PieChart'
import { getMonthFullName, getMonthName } from 'api/utils'
import { Flex } from 'App.style'

const PrivateMarket = ({ privateMarket }) => {
  return (
    <Card
      headerTitle="Private market"
      gridType="default"
      tooltip={
        <div>
          <p>
            <strong>Accumulated Registrations Objective</strong> is an annual
            value, and represent the market objective of Spain.
          </p>
          <p>
            <strong>Accumulated closed Registration</strong> is the number of
            Registrations invoiced stock from the init of the year to last
            update.
          </p>
          <p>
            <strong>Accumulated Registrations Objective previous year</strong>{' '}
            is the number of Registrations invoiced stock from the init of the
            last year to last update.
          </p>
          <p>
            This comparison shows how the progress the sales in front of the
            sales objective.
          </p>
          <p>
            Data Origin: The data is taken from the file
            SB_Closing_(last_month). Sales objective: The data is taken from tab
            Market_PC, table 2024 and row Private
          </p>
        </div>
      }
    >
      <PieChart
        customizedLabel={privateMarket?.registrations_this_year}
        title={`Accumulated closed 
          Registrations ${getMonthName(
            privateMarket?.latest_month
          )}'${privateMarket?.latest_year?.toString().substring(2)}`}
        legend={[
          privateMarket?.registrations_objective
            ? {
                name: `Accumulated Registrations Objective ${getMonthName(
                  privateMarket?.latest_month
                )}'${privateMarket?.latest_year?.toString().substring(2)}`,
                value: privateMarket?.registrations_objective,
                deviation: privateMarket?.registrations_vs_objective_deviation
              }
            : {},
          privateMarket?.registrations_last_year
            ? {
                name: `Accumulated Registrations ${
                  privateMarket?.registrations_objective ? 'Objective' : ''
                } ${getMonthName(privateMarket?.latest_month)}'${(
                  privateMarket?.latest_year - 1
                )
                  ?.toString()
                  .substring(2)}`,
                value: privateMarket?.registrations_last_year,
                deviation:
                  privateMarket?.registrations_this_year_vs_last_year_deviation
              }
            : ''
        ]}
        incomingOrders={false}
        dataFormatted={[
          {
            name: `Registrations`,
            value: privateMarket?.registrations_this_year
          },
          {
            name: `Registrations Objective`,
            value: privateMarket?.registrations_objective
              ? privateMarket?.registrations_objective -
                  privateMarket?.registrations_this_year >
                0
                ? privateMarket?.registrations_objective -
                  privateMarket?.registrations_this_year
                : 0
              : ''
          }
        ]}
      />

      <div
        style={{
          textAlign: 'center',
          marginTop: '48px',
          borderTop: '1px solid #ddd',
          paddingTop: '20px'
        }}
      >
        {privateMarket?.registrations_objective && (
          <Flex style={{ justifyContent: 'center', margin: '0' }}>
            <h3
              style={{
                color:
                  Math.sign(
                    privateMarket?.registrations_this_year -
                      privateMarket?.registrations_objective
                  ) === 1
                    ? '#28A745'
                    : '#E73939',
                marginRight: '4px'
              }}
            >
              {privateMarket?.registrations_this_year -
                privateMarket?.registrations_objective}
            </h3>
            <h4>Closed vs Objective</h4>
          </Flex>
        )}
      </div>
      <span
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 0 0',
          fontSize: '12px',
          fontWeight: '100'
        }}
      >
        {`Aggregated from January to ${getMonthFullName(
          privateMarket?.latest_month
        )} `}
      </span>
    </Card>
  )
}

export { PrivateMarket }
