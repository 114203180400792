/* eslint-disable @typescript-eslint/no-unused-vars */
import { Card } from 'components/Card'
import { Col } from 'components/Grid/Col'
import { Row } from 'components/Grid/Row'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'components/ErrorFallback'
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Area,
  Tooltip
} from 'recharts'
import { BrandContext } from 'contexts/BrandProvider'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

const TrendInput = (data: any) => {
  const { brand } = useContext(BrandContext)
  const { t } = useTranslation()

  return (
    <Row>
      <Col>
        <Card
          headerTitle="Trend Variable"
          minHeightBody={43.6}
          subtitle="Line trend chart in a specific period of selected time"
          tooltip={
            <ul>
              {
                <li>
                  <u>
                    {t(
                      'pricing.dataForecastInsights.trendInputChartTooltipFirstTitle'
                    )}
                  </u>
                  {t(
                    'pricing.dataForecastInsights.trendInputChartTooltipFirst'
                  )}
                </li>
              }
              <li>
                <u>
                  {' '}
                  {t(
                    'pricing.dataForecastInsights.trendInputChartTooltipSecondTitle'
                  )}
                </u>
                {t('pricing.dataForecastInsights.trendInputChartTooltipSecond')}
              </li>
              <li>
                <u>
                  {' '}
                  {t(
                    'pricing.dataForecastInsights.trendInputChartTooltipThirdTitle'
                  )}
                </u>
                {t('pricing.dataForecastInsights.trendInputChartTooltipThird')}
              </li>
            </ul>
          }
        >
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                data={data.data.points}
                margin={{ top: 20, right: 40, bottom: 40, left: 40 }}
              >
                <CartesianGrid
                  stroke="#DEDEDE"
                  strokeDasharray="3 3"
                  vertical={false}
                />

                <YAxis
                  type="number"
                  allowDecimals={false}
                  domain={([dataMin, dataMax]) => {
                    return [
                      Math.round(dataMin * 100) / 100,
                      Math.round(dataMax * 100) / 100
                    ]
                  }}
                  label={{
                    value: data.data.input_data_filter.input_data_filter,
                    angle: -90,
                    position: 'left'
                  }}
                />
                <XAxis
                  dataKey={(x) => `${x.month}/${x.year}`}
                  label={{
                    value: 'Period range',
                    position: 'bottom'
                  }}
                  interval="preserveEnd"
                  minTickGap={15}
                />

                <Legend
                  verticalAlign="bottom"
                  align="center"
                  wrapperStyle={{ left: 50, bottom: 10 }}
                  payload={[
                    {
                      value: '95% confidence interval',
                      type: 'square',
                      id: 'amt',
                      color: '#DECBBD'
                    },
                    {
                      value: 'Regression',
                      type: 'line',
                      id: 'uv',
                      color: brand === 'seat' ? '#F96C64' : '#95572B'
                    },
                    {
                      value: 'Variable',
                      type: 'line',
                      id: 'uv',
                      color: '#018A7F'
                    }
                  ]}
                />
                <Tooltip
                  labelStyle={{
                    display: 'none'
                  }}
                  contentStyle={{
                    borderColor: '#f5f5f5',
                    padding: '0.8rem 1.6rem',
                    borderRadius: '10px',
                    background: '#f5f5f5',
                    fontSize: ' 16px',
                    textTransform: 'capitalize'
                  }}
                  cursor={false}
                />
                {
                  <Area
                    type="monotone"
                    dataKey="interval"
                    fill="#DECBBD"
                    stroke="none"
                    activeDot={false}
                  />
                }
                <Line
                  type="monotone"
                  dataKey="regression"
                  stroke={brand === 'seat' ? '#F96C64' : '#95572B'}
                  strokeWidth="4"
                  dot={false}
                  activeDot={false}
                  isAnimationActive={false}
                />
                <Line
                  type="monotone"
                  dataKey="value"
                  stroke="#018A7F"
                  strokeWidth="4"
                  activeDot={false}
                  isAnimationActive={false}
                  dot={{
                    stroke: '#018A7F',
                    strokeWidth: 1,
                    r: 8,
                    fill: '#018A7F',
                    strokeDasharray: ''
                  }}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </ErrorBoundary>
        </Card>
      </Col>
    </Row>
  )
}

export { TrendInput }
