import { Wrapper } from './OptionSelection.style'
import { ButtonProps } from './OptionSelection.types'

const OptionSelection = (props: ButtonProps) => {
  const { onClick, children, isSelected, tool } = props
  return (
    <Wrapper onClick={onClick} isSelected={isSelected} tool={tool}>
      {children}
    </Wrapper>
  )
}

export { OptionSelection }
