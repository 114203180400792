import { InfoCardsItem, InfoCardsList } from './InfoCards.style'

const InfoCards = ({ items }) => {
  return (
    <InfoCardsList>
      {items &&
        items.map((item, index) => (
          <InfoCardsItem key={index}>
            <p>{item.title}</p>
            <h2>{item.value}</h2>
          </InfoCardsItem>
        ))}
    </InfoCardsList>
  )
}

export { InfoCards }
