import { AuthContext } from 'contexts/AuthProvider'
import { useContext, useEffect, useState } from 'react'

export const useTokenUuid = () => {
  const auth = useContext(AuthContext)
  const [uuid, setUuid] = useState('')
  useEffect(() => {
    if (auth && 'user' in auth) {
      const { user } = auth
      if (user && 'token' in user) {
        const { token } = user
        setUuid(token)
      }
    }
  }, [auth])
  return uuid
}
