import React from 'react'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as amplitude from '@amplitude/analytics-browser'

const ReactDOM = require('react-dom/client')

const REACT_APP_AMPLITUDE_ID = process.env.REACT_APP_AMPLITUDE_ID

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

/**
 * ANALYTICS AREA
 */

// Amplitude
if (REACT_APP_AMPLITUDE_ID)
  amplitude.init(REACT_APP_AMPLITUDE_ID, {
    defaultTracking: {
      pageViews: true
    }
  })
