import './translations/i18n'
import './assets/styles/fonts.css'
import { Content } from 'layout/Content'
import { Header } from 'layout/Header'
import { Providers } from 'Providers'
import { ContentLayout, PageLayout } from 'App.style'
import { Feedback } from 'components/Feedback'
import { CustomHeader } from 'layout/CustomHeader/CustomHeader'

function App() {
  return (
    <Providers>
      <PageLayout>
        <ContentLayout>
          <Header />
          <CustomHeader />
          <Content />
        </ContentLayout>
        <Feedback />
      </PageLayout>
    </Providers>
  )
}

export default App
