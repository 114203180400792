import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  height: 100%;
`

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.red};
  text-align: center;
  display: block;
  line-height: 1.4;
  font-size: 1.3rem;
  max-width: 24.8rem;
`
