import { Card } from 'components/Card'
import styled from 'styled-components'
import { Badge } from 'components/Badge/Badge'
import { PieChart } from 'components/Graphs/PieChart/PieChart'
import { getBadgeColorPercentaje } from 'utils'
import { getMonthFullName, getMonthName } from 'api/utils'
import { Flex } from 'App.style'

export const BadgeLegend = styled.div<{ $color?: string }>`
  display: flex;
  margin-top: 4px;

  &::before {
    content: '';
    width: 16px;
    height: 16px;
    background-color: ${(props) => props.$color};
    border-radius: 4px;
    margin-right: 4px;
  }
`

const IncomingOrders = ({ incomingOrders }) => {
  return (
    <Card
      headerTitle="Incoming Orders"
      gridType="default"
      headerComponent={
        <Badge
          data={`${incomingOrders.incoming_orders_deviation}%`}
          color={getBadgeColorPercentaje(
            incomingOrders.incoming_orders_deviation
          )}
          size={17}
          tooltip={
            <div>
              <BadgeLegend $color="#28A745">{'> '}than 5%</BadgeLegend>
              <BadgeLegend $color="#FEB800">From 5% to -5%</BadgeLegend>
              <BadgeLegend $color="#DC3545">{'< '} than-5%</BadgeLegend>
            </div>
          }
        ></Badge>
      }
      tooltip={
        <div>
          <p>
            <strong>IO until day </strong> is the number of incoming orders
            realised from the first day of month to today.
          </p>
          <p>
            The <strong>Monthly Forecast</strong> measures the sales trend to
            date based on this and previous years, and calculates the sales
            forecast until the end of the year.
          </p>
          <p>
            <strong>IO Predicted</strong> is the sum of the Daily Sales and the
            Forecast. It shows the trend that the IO have vs Incoming Orders
            Objective.
          </p>
          <p>Total Objective, is the sales objective of the month.</p>
          <p>
            The Incoming Orders data is taken from the Daily channel report
            filtered by private market and a forecast for the next 30 days.
          </p>
        </div>
      }
    >
      <PieChart
        customizedLabel={incomingOrders.predicted_incoming_orders}
        title="IO Predicted"
        incomingOrders={true}
        legend={[
          {
            name: `IO until day ${incomingOrders.day}th ${getMonthName(
              incomingOrders.month
            )}`,
            value: incomingOrders.monthly_incoming_orders
          },
          {
            name: 'Monthly Forecast',
            value: incomingOrders.predicted_incoming_orders_rest_of_month
          },
          {
            name: 'Total Obj. ',
            value: incomingOrders.total_objective
          }
        ]}
        dataFormatted={[
          {
            name: 'Daily IO ',
            value: incomingOrders.monthly_incoming_orders
          },
          {
            name: 'Monthly Forecast',
            value: incomingOrders.predicted_incoming_orders_rest_of_month
          },
          {
            name: 'Total Obj. ',
            value:
              incomingOrders.total_objective -
                incomingOrders.predicted_incoming_orders >
              0
                ? incomingOrders.total_objective -
                  incomingOrders.predicted_incoming_orders
                : 0
          }
        ]}
      />
      <div
        style={{
          textAlign: 'center',
          marginTop: '48px',
          borderTop: '1px solid #ddd',
          paddingTop: '20px'
        }}
      >
        <Flex style={{ justifyContent: 'center', margin: '0' }}>
          <h3
            style={{
              color:
                Math.sign(
                  incomingOrders?.predicted_incoming_orders -
                    incomingOrders?.total_objective
                ) === 1
                  ? '#28A745'
                  : '#E73939',
              marginRight: '4px'
            }}
          >
            {incomingOrders?.predicted_incoming_orders -
              incomingOrders?.total_objective}
          </h3>
          <h4>Predicted vs Objective</h4>
        </Flex>
      </div>
      <span
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 0 0',
          fontSize: '12px',
          fontWeight: '100'
        }}
      >
        {`${getMonthFullName(incomingOrders?.month)} data`}
      </span>
    </Card>
  )
}

export { IncomingOrders }
