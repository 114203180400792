import styled from 'styled-components'
import { ReactComponent as Chevron } from 'assets/icons/chevronleft.svg'
export const PaginationWrapper = styled.div`
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 1.6rem;
  border-top: ${({ theme }) => '1px solid' + theme.colors.border};
`

export const PaginationInfo = styled.span`
  color: ${({ theme }) => theme.colors.bgBlack};
`

export const PaginationHandler = styled.div`
  display: flex;
  align-items: center;
  border: ${({ theme }) => '1px solid' + theme.colors.border};
`

export const PaginationButton = styled.button<{ active?: boolean }>`
  width: 3.6rem;
  height: 3.6rem;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.primaryEvent : theme.colors.white};
  color: ${({ theme, active }) => active && theme.colors.primary};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
  &:not(:last-child) {
    border-right: ${({ theme }) => '1px solid' + theme.colors.border};
  }
  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`

export const ChevronleftIcon = styled(Chevron)`
  transform: rotate(0);
  height: 1.4rem;
`

export const ChevronrightIcon = styled(Chevron)`
  transform: rotate(180deg);
  height: 1.4rem;
`
