import styled from 'styled-components'

export const LegendCaption = styled.text`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  max-width: 300px;
`

export const LegendCaptionRound = styled.rect<{ $color?: string }>`
  width: 16px;
  height: 16px;
  fill: ${(props) => props.$color};
`

export const LegendCaptionRoundDivided = styled.rect<{
  $color?: string
  $colorSecondary?: string
}>`
  width: 16px;
  height: 16px;
  fill: linear-gradient(to bottom right, green 50%, teal 50%);
`

export const CustomLabel = styled.p<{ $color?: string }>`
  display: flex;
  justify-content: center;
  font-weight: 400;
  margin-top: 4px;

  &::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background-color: ${(props) => props.$color};
    border-radius: 4px;
    margin-right: 4px;
  }
`
