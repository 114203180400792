import { PieChart, Pie, Cell, Label, Legend } from 'recharts'
import {
  LegendCaptionRound,
  LegendCaption,
  CustomLabel
} from './PieChart.styles'
import { Flex } from 'App.style'
import styled from 'styled-components'
import { Badge } from 'components/Badge/Badge'
import { getBadgeColorPercentaje } from 'utils'

const COLORS_BLUE = ['#518BFA', '#C1E5FD', '#003972', '#fff']
const COLORS_PURPLE = ['#A45FCE', '#D8B3ED', '#003972', '#fff']

const LegendCaptionRoundDivided = styled.svg`
  width: 100px;
  height: 100px;

  rect {
    fill: url(#twoColorFill);
  }
`

export const BadgeLegend = styled.div<{ $color?: string }>`
  display: flex;
  margin-top: 4px;

  &::before {
    content: '';
    width: 16px;
    height: 16px;
    background-color: ${(props) => props.$color};
    border-radius: 4px;
    margin-right: 4px;
  }
`

const PieChartComponent = ({
  dataFormatted,
  customizedLabel,
  title,
  legend,
  incomingOrders
}) => {
  const colors = incomingOrders ? COLORS_PURPLE : COLORS_BLUE

  const CustomizedLabel = ({ value, color, colorSecondary }) => {
    return (
      <>
        <text
          x={175}
          y={80}
          fill="black"
          dominantBaseline="central"
          style={{ fontSize: '24px' }}
        >
          {value}
        </text>
        <>
          {incomingOrders && (
            <>
              <LegendCaptionRoundDivided x={160} y={107}>
                <defs>
                  <linearGradient
                    id="twoColorFill"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="100%"
                  >
                    <stop
                      offset="50%"
                      style={{ stopColor: color, stopOpacity: 1 }}
                    />
                    <stop
                      offset="50%"
                      style={{ stopColor: colorSecondary, stopOpacity: 1 }}
                    />
                  </linearGradient>
                </defs>
                <rect width="16px" height="16px" rx="4px" ry="4px" />
              </LegendCaptionRoundDivided>
              <LegendCaption x={180} y={120}>
                {title}
              </LegendCaption>
            </>
          )}

          {!incomingOrders && (
            <>
              <LegendCaptionRound
                $color={color}
                x={115}
                y={107}
                rx={4}
              ></LegendCaptionRound>
              <LegendCaption x={135} y={120}>
                {title.split('\n').map((line: string, index: number) => (
                  <tspan x="135" dy={index === 0 ? 0 : '1.2em'} key={index}>
                    {line}
                  </tspan>
                ))}
              </LegendCaption>
            </>
          )}
        </>
      </>
    )
  }

  const renderLegend = () => {
    return (
      <Flex
        style={{
          fontWeight: '500',
          textAlign: 'center',
          justifyContent: 'space-around',
          marginBottom: '-40px'
        }}
      >
        {legend?.map((entry, index) => (
          <div>
            {entry.value}
            {entry.value && (
              <CustomLabel $color={colors[incomingOrders ? index : index + 1]}>
                {entry.name}
              </CustomLabel>
            )}

            {!incomingOrders && entry.value && (
              <Badge
                data={`${entry.deviation} %`}
                color={getBadgeColorPercentaje(entry.deviation)}
                size={17}
                tooltip={
                  <div>
                    <BadgeLegend $color="#28A745">{'> '}than 5%</BadgeLegend>
                    <BadgeLegend $color="#FEB800">From 5% to -5%</BadgeLegend>
                    <BadgeLegend $color="#DC3545">{'< '} than-5%</BadgeLegend>
                  </div>
                }
              ></Badge>
            )}
          </div>
        ))}
      </Flex>
    )
  }

  return (
    <PieChart width={400} height={200} style={{ margin: 'auto' }}>
      <Legend content={renderLegend} />
      <Pie
        data={dataFormatted}
        cx={200}
        cy={125}
        startAngle={180}
        endAngle={0}
        innerRadius={115}
        outerRadius={130}
        paddingAngle={0}
        dataKey="value"
      >
        {dataFormatted?.map((_entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={colors[index === 2 ? 3 : index % colors.length]}
          />
        ))}
        <Label
          content={
            <CustomizedLabel
              color={colors[0]}
              value={customizedLabel}
              colorSecondary={colors[1]}
            />
          }
        />
      </Pie>
      <Pie
        data={[dataFormatted[0]]}
        cx={200}
        cy={125}
        startAngle={180}
        endAngle={0}
        innerRadius={100}
        outerRadius={115}
        paddingAngle={0}
        dataKey="value"
      >
        <Cell key={`cell-${0}`} fill={colors[2]} />
      </Pie>
    </PieChart>
  )
}

export { PieChartComponent as PieChart }
