import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-left: auto;
`

export const UserButton = styled.button`
  background-color: transparent;
  padding: 0 0.4rem;
  display: flex;
  align-items: center;
  display: inline-flex;
  position: relative;
  gap: 0.6rem;
`

export const UserName = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-weight: normal;
  font-size: 1.4rem;
  margin-top: 0.2rem;
`
