import {
  ButtonWrapper,
  ChevronleftIcon,
  FilterIconWrapper,
  FiltersNumber,
  FiltersWrapper,
  Wrapper
} from './Filters.style'
import { ReactComponent as FilterIcon } from 'assets/icons/filters.svg'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useState } from 'react'
import { Button } from 'components/Button'
import { FilterWidgetProps } from './Filters.types'
import * as amplitude from '@amplitude/analytics-browser'

const Filters = (props: FilterWidgetProps) => {
  const {
    children,
    applyAll,
    clearAll,
    enableApplyAll = false,
    enableClearAll = false,
    totalFilters = 0
  } = props
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(false)
  const applyAllFilters = useCallback(() => {
    amplitude.track('Filters applied')
    applyAll()
  }, [applyAll])

  const clearAllFilters = useCallback(() => {
    clearAll()
  }, [clearAll])

  const [scroll, setScroll] = useState(window.scrollY)
  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scroll, setScroll])

  const toggleButton = useCallback(() => {
    if (scroll >= 50) {
      setIsExpanded(!isExpanded)
    }
  }, [isExpanded, scroll])

  return (
    <Wrapper scroll={scroll} expanded={isExpanded ? 1 : 0}>
      <FilterIconWrapper scroll={scroll} onClick={toggleButton}>
        <FilterIcon />
        <ChevronleftIcon scroll={scroll} expanded={isExpanded ? 1 : 0} />
      </FilterIconWrapper>
      {!!totalFilters && (
        <FiltersNumber scroll={scroll} expanded={isExpanded ? 1 : 0}>
          {totalFilters}
        </FiltersNumber>
      )}
      <FiltersWrapper scroll={scroll} expanded={isExpanded ? 1 : 0}>
        {children}
        <ButtonWrapper>
          <Button
            look="filled"
            onClick={applyAllFilters}
            disabled={!enableApplyAll}
            label={t('filters.apply.label')}
          />
          {enableClearAll && (
            <Button
              look="outlined"
              onClick={clearAllFilters}
              label={t('filters.clear.label')}
            />
          )}
        </ButtonWrapper>
      </FiltersWrapper>
    </Wrapper>
  )
}

export { Filters }
