/* eslint-disable @typescript-eslint/no-unused-vars */
import { Title } from 'components/Title'
import { useTranslation } from 'react-i18next'
import { Row } from 'components/Grid/Row'
import { PageFilters } from './components/PageFilters'
import { useContext, useEffect, useState } from 'react'
import { useTokenUuid } from 'hooks/request'
import { toQueryParams } from 'api/utils'
import { usePocketParams } from './hooks/usePocketParams'
import { BrandContext } from 'contexts/BrandProvider'
import { BubbleChart } from './components/BubbleChart'
import { Spinner } from 'components/Spinner'
import { ReactComponent as NoResults } from 'assets/icons/noResults.svg'
import { InfoCards } from './components/InfoCards'
import { getLastAvailableData, getPocketAnalysis } from 'api/pricing/pocket'
import { NoData } from 'components/NoData'
import { InputRange } from 'components/Inputs/Range'
import { Flex } from 'App.style'
import { useLocation } from 'react-router-dom'

const PocketAnalysis = () => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [universalTrimFilters, setUniversalTrimFilters] = useState<any>()

  const [pocket, setPocket] = useState<any>([])
  const [registrations, setRegistrations] = useState<any>([])
  const [trim, setTrim] = useState<any>([
    universalTrimFilters?.min_transactional_price,
    universalTrimFilters?.max_transactional_price
  ])
  const [error, setError] = useState(false)
  const [dataEmpty, setDataEmpty] = useState<any>(null)

  const { brand } = useContext(BrandContext)

  const uuid = useTokenUuid()

  const location = useLocation()
  const paramsLocation = location.state || {}
  const { model } = paramsLocation

  const params = usePocketParams()

  let queryParams = toQueryParams({
    basket: params.model,
    month: params.startMonth - 1,
    year: params.startYear,
    fuels: params.fuel,
    basket_brand: brand,
    min_transactional_price: trim[0]
      ? parseInt(trim[0])
      : parseInt(universalTrimFilters?.min_transactional_price),
    max_transactional_price: trim[1]
      ? parseInt(trim[1])
      : parseInt(universalTrimFilters?.max_transactional_price)
  })

  // FIRST GRAPH
  const fetchPocketData = async () => {
    setIsLoading(true)
    const resp: any = await getPocketAnalysis(uuid, queryParams)
    if (resp !== 500 && resp !== 204) setPocket(resp)
    else setError(true)
    setIsLoading(false)
  }

  const fetchAvailableData = async () => {
    const simpleQueryParam = toQueryParams({
      basket_brand: brand
    })
    const resp: any = await getLastAvailableData(uuid, simpleQueryParam)
    setDataEmpty(resp)
  }

  // SECOND GRAPH
  // const fetchCompetitorsData = async () => {
  //   let queryParamsRegistrations = toQueryParams({
  //     month: params.startMonth - 1,
  //     year: params.startYear,
  //     fuels: params.fuel,
  //     min_transactional_price: parseInt(trim[0]),
  //     max_transactional_price: parseInt(trim[1]),
  //     body_types: 'hatchback'
  //   })
  //   setIsLoading(true)
  //   const resp: any = await getPocketCompetitorsRegistrations(
  //     uuid,
  //     queryParamsRegistrations
  //   )
  //   if (resp !== 500 && resp !== 204) setRegistrations(resp.registrations)
  //   else setError(true)
  //   setIsLoading(false)
  // }

  const items = [
    {
      title: 'Average Transactional Price',
      value: `${pocket?.avg_transactional_price}€`
    },
    {
      title: 'Average Adjustment',
      value: `${pocket?.avg_adjustments}`
    },
    {
      title: 'Total Volumen',
      value: pocket?.total_volume
    }
  ]

  const handleUniversalTrim = (data) => {
    setUniversalTrimFilters(data)
    setTrim(data)
  }

  useEffect(() => {
    setError(false)
    fetchAvailableData()

    if (params.model && params.fuel) {
      uuid && fetchPocketData()
      //uuid && fetchCompetitorsData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, queryParams])

  useEffect(() => {
    setRegistrations([])
    setPocket([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, brand])

  if (pocket === undefined || registrations === undefined) {
    return <NoData feed="500" />
  }

  return (
    <>
      <Title
        label={t('pricing.pocketAnaylsis.title')}
        data-testid="dashboard-view"
      />

      <Row justify="space-between" align="center">
        {dataEmpty?.date && (
          <PageFilters
            handleFilters={handleUniversalTrim}
            maximunDate={new Date(dataEmpty?.date)}
            selectedModel={model ?? null}
          />
        )}

        {universalTrimFilters &&
          universalTrimFilters.min_transactional_price &&
          universalTrimFilters.max_transactional_price && (
            <Flex style={{ width: '400px', flexDirection: 'column' }}>
              <span
                style={{
                  fontSize: '12px',
                  marginBottom: '20px',
                  color: '#666'
                }}
              >
                Transactional price
              </span>
              <InputRange
                min={universalTrimFilters.min_transactional_price}
                max={universalTrimFilters.max_transactional_price}
                step={2000}
                onChange={(value) => setTrim(value)}
              />
            </Flex>
          )}
      </Row>

      {isLoading && <Spinner />}

      {!error && pocket.spheres && <InfoCards items={items} />}

      {!error && pocket.spheres && pocket.spheres.length > 0 && (
        <BubbleChart pocket={pocket.spheres} />
      )}

      {/* {!error && registrations && pocket && registrations.length > 0 && (
        <BubbleChartRegistrations pocket={registrations} trim={trim} />
      )} */}

      {!isLoading &&
        !error &&
        pocket.length === 0 &&
        registrations.length === 0 && (
          <div style={{ textAlign: 'center' }}>
            <NoResults />
            <h3 style={{ fontWeight: 400 }}>
              Select some input from filters...
            </h3>
          </div>
        )}

      {!isLoading && error && (
        <div style={{ textAlign: 'center' }}>
          <NoResults />
          <h3 style={{ fontWeight: 400 }}>
            No data with this filters. You can change them to find results
          </h3>
        </div>
      )}
    </>
  )
}

export { PocketAnalysis }
