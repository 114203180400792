import { MainRouter } from 'views/Routes'
import { ViewPort } from './Content.style'

const Content = () => {
  return (
    <ViewPort>
      <MainRouter />
    </ViewPort>
  )
}

export { Content }
