import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`

export const Button = styled.button`
  height: 3.3rem;
  min-width: 12.8rem;
  border-radius: 1.8rem;
  background-color: ${({ theme }) => theme.colors.bgBlack};
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.4rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  cursor: pointer;
  &:after {
    content: '...';
    line-height: 1;
    transform: rotate(-90deg);
  }
`

export const Options = styled.ul<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  z-index: 1;
  top: 3.9rem;
  right: 0;
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.white};
`

export const Option = styled.li`
  min-width: 20rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryEvent};
  }
`
